import { ValidationTypes } from "constants/WidgetValidation";
import { FieldType } from "widgets/ZJsonSummaryWidget/constants";
import type { HiddenFnParams } from "../helper";
import { getSchemaItem } from "../helper";

const PROPERTIES = {
  content: {
    data: [
      {
        propertyName: "chipStateError",
        label: "Chip State Error",
        helpText: "Sets the error state of the chip",
        controlType: "INPUT_TEXT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.CHIP),
        dependencies: ["schema"],
      },
      {
        propertyName: "chipStatePrimary",
        label: "Chip State Primary",
        helpText: "Sets the primary state of the chip",
        controlType: "INPUT_TEXT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.CHIP),
        dependencies: ["schema"],
      },
      {
        propertyName: "chipStateSuccess",
        label: "Chip State Success",
        helpText: "Sets the success state of the chip",
        controlType: "INPUT_TEXT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.CHIP),
        dependencies: ["schema"],
      },
      {
        propertyName: "chipStateWarning",
        label: "Chip State Warning",
        helpText: "Sets the warning state of the chip",
        controlType: "INPUT_TEXT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.CHIP),
        dependencies: ["schema"],
      },
      {
        propertyName: "chipStateSecondary",
        label: "Chip State Secondary",
        helpText: "Sets the secondary state of the chip",
        controlType: "INPUT_TEXT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.CHIP),
        dependencies: ["schema"],
      },
      {
        propertyName: "chipStateInfo",
        label: "Chip State Info",
        helpText: "Sets the info state of the chip",
        controlType: "INPUT_TEXT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.CHIP),
        dependencies: ["schema"],
      },
      {
        propertyName: "chipStateIndeterminate",
        label: "Chip State Indeterminate",
        helpText: "Sets the indeterminate state of the chip",
        controlType: "INPUT_TEXT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.CHIP),
        dependencies: ["schema"],
      },
    ],
  },
};

export default PROPERTIES;
