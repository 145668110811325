// dateUtils.ts

// Get the current date in YYYY-MM-DD format
import { COMPARE_TO_TYPE, DURATION_SELECT, REVENUE_TYPE } from "./constants";
import { isCustomDuration } from "./typeChecker";

// Add days to a given date
export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

// Subtract days from a given date
export function subtractDays(date: Date, days: number): Date {
  return addDays(date, -days);
}

// Get the start of the current month
export function getStartOfMonth(date: Date = new Date()): Date {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

// Get the end of the current month
export function getEndOfMonth(date: Date = new Date()): Date {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

// Format a date as YYYY-MM-DD
export function formatDate(date: Date): string {
  return date.toLocaleDateString("en-US", {
    timeZone: "UTC",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

// Get the number of days between two dates
export function getDaysDifference(startDate: Date, endDate: Date): number {
  const msPerDay = 24 * 60 * 60 * 1000;
  return Math.round((endDate.getTime() - startDate.getTime()) / msPerDay);
}

// Check if a year is a leap year
export function isLeapYear(year: number): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

// Get the first day of the current month
export function getFirstDayOfMonth(date: Date = new Date()): Date {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

// Get the first day of the nth previous month
export function getFirstDayOfNthPreviousMonth(
  n: number,
  date: Date = new Date(),
): Date {
  return new Date(date.getFullYear(), date.getMonth() - n, 1);
}

// Helper function to get the last day of the month
export function getLastDayOfMonth(year: any, month: number) {
  return new Date(year, month + 1, 0).getDate(); // +1 because month is zero-indexed
}

// Get the last day of the nth previous month
export function getLastDayOfNthPreviousMonth(
  n: number,
  date: Date = new Date(),
): Date {
  return new Date(date.getFullYear(), date.getMonth() - n + 1, 0);
}

// Get the month and year of the previous month in "Month YYYY" format
export function getPreviousMonthAndYear(date: Date = new Date()): string {
  const previousMonthDate = getFirstDayOfNthPreviousMonth(1, date);
  const options = { month: "long", year: "numeric" } as const;
  return previousMonthDate.toLocaleDateString("en-US", options);
}

// Get the date in YYYYMMDD format from a given date object or date string
export function getDateInYYYYMMDD(date: Date | string): string {
  const parsedDate = typeof date === "string" ? new Date(date) : date;
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const day = String(parsedDate.getDate()).padStart(2, "0");
  return `${year}${month}${day}`;
}

// Convert a date from YYYYMMDD format to DD/MM/YYYY format
export function convertYYYYMMDDToDDMMYYYY(yyyymmdd: string): any {
  if (yyyymmdd) {
    const year = yyyymmdd.substring(0, 4);
    const month = yyyymmdd.substring(4, 6);
    const day = yyyymmdd.substring(6, 8);
    return `${month}/${day}/${year}`;
  }
}

// Subtract a specified number of months from a given date
export function minusMonths(date: Date, months: number): Date {
  const result = new Date(date);
  result.setMonth(result.getMonth() - months);
  return result;
}

export function minusMonthsLastDayOfMonth(date: Date, months: number): Date {
  const result: Date = new Date(date);
  result.setMonth(result.getMonth() - (months - 1));
  result.setDate(0);
  return result;
}

function minusMonthsForMonthly(date: Date, months: number): Date {
  const result: Date = new Date(date);
  result.setDate(1);
  result.setMonth(result.getMonth() - months);
  return result;
}

// Move from last day of a month to last day of a future month
function plusMonthsLastDayOfMonth(date: Date, months: number): Date {
  const result: Date = new Date(date);
  result.setMonth(result.getMonth() + months);
  if (result.getDate() != date.getDate()) {
    result.setDate(0);
  }
  const year = result.getFullYear();
  const month = result.getMonth();
  return new Date(Date.UTC(year, month + 1, 0));
}

export const minusDays = (date: string | number | Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

function plusDays(date: Date, days: number): Date {
  const result: Date = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const minusWeeks = (date: string | number | Date, weeks: number) => {
  return minusDays(date, weeks * 7);
};

function plusWeeks(date: Date, weeks: number): Date {
  return plusDays(date, weeks * 7);
}

// Get the first day of the nth previous year
export function getFirstDayOfNthPreviousYear(
  n: number,
  date: Date = new Date(),
): Date {
  return new Date(date.getFullYear() - n, 0, 1);
}

function calculateDifferenceInDays(earlier: Date, later: Date): number {
  const differenceInTime: number = later.getTime() - earlier.getTime();
  return Math.round(differenceInTime / (1000 * 3600 * 24));
}

export const calculateDatesForPeriod = (
  period: any,
  isACB: boolean,
  customEndDate: Date = minusDays(new Date(), 1),
) => {
  if (isCustomDuration(period)) {
    const startDate: Date = period.startDate;
    const endDate: Date = period.endDate;
    const durationLengthInDays: number = calculateDifferenceInDays(
      startDate,
      endDate,
    );
    return {
      startDate: getDateInYYYYMMDD(startDate),
      endDate: getDateInYYYYMMDD(endDate),
      compareToStartDate: getDateInYYYYMMDD(
        minusDays(startDate, durationLengthInDays),
      ),
      compareToEndDate: getDateInYYYYMMDD(minusDays(startDate, 1)),
      aggregationType: period.periodicity,
    };
  }
  const unit = period.slice(-1); // 'm', 'w', 'd'
  const value = parseInt(period.slice(0, -1), 10);

  let startDate: Date,
    endDate: Date,
    compareToStartDate: Date,
    compareToEndDate: Date,
    aggregationType: string;

  switch (unit) {
    case "m":
      if (isACB) {
        if (
          // customEndDate is provided, i.e., is before default value of today
          customEndDate < minusDays(new Date(), 1)
        ) {
          startDate = minusMonths(customEndDate, value);
          compareToStartDate = minusMonths(customEndDate, 2 * value);
          endDate = customEndDate;
          compareToEndDate = startDate;
        } else {
          startDate = getFirstDayOfNthPreviousMonth(value - 1);
          endDate = customEndDate;
          compareToStartDate = getFirstDayOfNthPreviousMonth(2 * value - 1);
          compareToEndDate = getLastDayOfNthPreviousMonth(value);
        }
      } else {
        startDate = getFirstDayOfNthPreviousMonth(value);
        endDate = getLastDayOfNthPreviousMonth(1);
        compareToStartDate = getFirstDayOfNthPreviousMonth(2 * value);
        compareToEndDate = getLastDayOfNthPreviousMonth(value + 1);
      }
      aggregationType = "monthly";
      break;
    case "w":
      endDate = getMostRecentMonday(new Date());
      startDate = minusWeeks(endDate, value - 1);
      compareToEndDate = minusWeeks(startDate, 1);
      compareToStartDate = minusWeeks(compareToEndDate, value - 1);
      aggregationType = "weekly";
      break;
    case "d":
      endDate = customEndDate;
      startDate = minusDays(customEndDate, value - 1);
      compareToEndDate = minusDays(startDate, 1);
      compareToStartDate = minusDays(compareToEndDate, value - 1);
      aggregationType = "daily";
      break;
    default:
      throw new Error("Invalid period unit");
  }

  return {
    startDate: getDateInYYYYMMDD(startDate),
    endDate: getDateInYYYYMMDD(endDate),
    compareToStartDate: getDateInYYYYMMDD(compareToStartDate),
    compareToEndDate: getDateInYYYYMMDD(compareToEndDate),
    aggregationType: aggregationType,
  };
};

export const calculateCompletedPeriods = (period: string) => {
  const currentDate = new Date();
  const unit = period.slice(-1); // 'm', 'w', 'd'
  const value = parseInt(period.slice(0, -1), 10);

  if (!["m", "w", "d"].includes(unit)) {
    throw new Error(
      'Invalid period unit. Allowed units are "m" for months, "w" for weeks, and "d" for days.',
    );
  }

  const periods: any[] = [];

  for (let i = 0; i < value; i++) {
    // Start from 0 to include the current completed period
    let startDate, endDate;

    switch (unit) {
      case "m": // Months
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - i,
          0,
        ); // Last day of the completed month
        startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1); // First day of that month
        break;

      case "w": // Weeks
        endDate = new Date(currentDate);
        endDate.setDate(endDate.getDate() - (endDate.getDay() + 7 * i)); // Last day of the previous week
        startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 6); // First day of that week
        break;

      case "d": // Days
        endDate = new Date(currentDate);
        endDate.setDate(endDate.getDate() - i); // End of the previous day
        startDate = new Date(endDate);
        startDate.setDate(startDate.getDate()); // Start of that day
        break;

      default:
        throw new Error("Invalid period unit");
    }

    periods.push({
      startDate: getDateInYYYYMMDD(startDate),
      endDate: getDateInYYYYMMDD(endDate),
    });
  }

  return periods.reverse(); // To get the dates in chronological order
};

// Get the last day of the nth previous year
export function getLastDayOfNthPreviousYear(
  n: number,
  date: Date = new Date(),
): Date {
  return new Date(date.getFullYear() - n + 1, 0, 0);
}

export function getRevenueCardDates(revenueType: any) {
  let startDate: string;
  let endDate: string;
  let aggregationType: string;

  let compareToStartDate: string;
  let compareToEndDate: string;

  switch (revenueType) {
    case REVENUE_TYPE.MonthToDate:
      startDate = getDateInYYYYMMDD(getFirstDayOfMonth());
      endDate = getDateInYYYYMMDD(new Date());
      aggregationType = "total";
      compareToStartDate = getDateInYYYYMMDD(getFirstDayOfNthPreviousMonth(1));
      compareToEndDate = getDateInYYYYMMDD(getLastDayOfNthPreviousMonth(1));
      break;
    case REVENUE_TYPE.LastMonthSamePeriod:
      startDate = getDateInYYYYMMDD(getFirstDayOfNthPreviousMonth(1));
      endDate = getDateInYYYYMMDD(minusMonths(new Date(), 1));
      aggregationType = "total";
      compareToStartDate = getDateInYYYYMMDD(getFirstDayOfNthPreviousMonth(2));
      compareToEndDate = getDateInYYYYMMDD(minusMonths(new Date(), 2));
      break;
    case REVENUE_TYPE.LastMonthTotal:
      startDate = getDateInYYYYMMDD(getFirstDayOfNthPreviousMonth(1));
      endDate = getDateInYYYYMMDD(getLastDayOfNthPreviousMonth(1));
      aggregationType = "total";
      compareToStartDate = getDateInYYYYMMDD(getFirstDayOfNthPreviousMonth(2));
      compareToEndDate = getDateInYYYYMMDD(getLastDayOfNthPreviousMonth(2));
      break;
    case REVENUE_TYPE.LastYearTotal:
      startDate = getDateInYYYYMMDD(getFirstDayOfNthPreviousMonth(12));
      endDate = getDateInYYYYMMDD(getLastDayOfNthPreviousMonth(1));
      compareToStartDate = getDateInYYYYMMDD(getFirstDayOfNthPreviousMonth(24));
      compareToEndDate = getDateInYYYYMMDD(getLastDayOfNthPreviousMonth(13));
      aggregationType = "total";
      break;
    case REVENUE_TYPE.Forecast:
      startDate = getDateInYYYYMMDD(getFirstDayOfNthPreviousMonth(1));
      endDate = getDateInYYYYMMDD(getLastDayOfNthPreviousMonth(1));
      compareToStartDate = getDateInYYYYMMDD(getFirstDayOfNthPreviousMonth(1));
      compareToEndDate = getDateInYYYYMMDD(getLastDayOfNthPreviousMonth(1));
      aggregationType = "monthly";
      break;
    default:
      startDate = "";
      endDate = "";
      compareToStartDate = "";
      compareToEndDate = "";
      aggregationType = "";
      break;
  }
  return {
    startDate,
    endDate,
    compareToStartDate,
    compareToEndDate,
    aggregationType,
  };
}

export function parseYYYYMMDD(yyyyMMdd: string) {
  if (yyyyMMdd) {
    const year = parseInt(yyyyMMdd.substring(0, 4), 10);
    const month = parseInt(yyyyMMdd.substring(4, 6), 10) - 1; // Month is zero-indexed
    const day = parseInt(yyyyMMdd.substring(6, 8), 10);

    return new Date(year, month, day);
  }
  return new Date();
}

export function convertDateStringToYYYYMMDD(
  dateString: string | number | Date,
) {
  // Create a Date object from the input date string
  const date = new Date(dateString);

  // Extract year, month, and day from the Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  // Format the date as YYYYMMDD
  return `${year}${month}${day}`;
}

export function getCompareToDate(
  startDate: string,
  endDate: string,
  compareTo: COMPARE_TO_TYPE,
  periodicity: DURATION_SELECT,
) {
  let compareToStartDate;
  let compareToEndDate;

  switch (compareTo) {
    case COMPARE_TO_TYPE.prevDay:
      compareToStartDate = getDateInYYYYMMDD(
        minusDays(parseYYYYMMDD(startDate), 1),
      );
      compareToEndDate = getDateInYYYYMMDD(
        minusDays(parseYYYYMMDD(endDate), 1),
      );
      break;
    case COMPARE_TO_TYPE.prevWeek:
      compareToStartDate = getDateInYYYYMMDD(
        minusWeeks(parseYYYYMMDD(startDate), 1),
      );
      compareToEndDate = getDateInYYYYMMDD(
        minusWeeks(parseYYYYMMDD(endDate), 1),
      );
      break;
    case COMPARE_TO_TYPE.prevMonth:
      if (periodicity === DURATION_SELECT.daily) {
        compareToStartDate = getDateInYYYYMMDD(
          minusDays(parseYYYYMMDD(startDate), 30),
        );
        compareToEndDate = getDateInYYYYMMDD(
          minusDays(parseYYYYMMDD(endDate), 30),
        );
      } else if (periodicity === DURATION_SELECT.weekly) {
        compareToStartDate = getDateInYYYYMMDD(
          minusWeeks(parseYYYYMMDD(startDate), 4),
        );
        compareToEndDate = getDateInYYYYMMDD(
          minusWeeks(parseYYYYMMDD(endDate), 4),
        );
      } else {
        compareToStartDate = getDateInYYYYMMDD(
          minusMonths(parseYYYYMMDD(startDate), 1),
        );
        compareToEndDate = getDateInYYYYMMDD(
          minusMonthsLastDayOfMonth(parseYYYYMMDD(endDate), 1),
        );
      }
      break;
    case COMPARE_TO_TYPE.prevYear:
      if (periodicity === DURATION_SELECT.daily) {
        compareToStartDate = getDateInYYYYMMDD(
          minusDays(parseYYYYMMDD(startDate), 365),
        );
        compareToEndDate = getDateInYYYYMMDD(
          minusDays(parseYYYYMMDD(endDate), 365),
        );
      } else if (periodicity === DURATION_SELECT.weekly) {
        compareToStartDate = getDateInYYYYMMDD(
          minusWeeks(parseYYYYMMDD(startDate), 52),
        );
        compareToEndDate = getDateInYYYYMMDD(
          minusWeeks(parseYYYYMMDD(endDate), 52),
        );
      } else {
        compareToStartDate = getDateInYYYYMMDD(
          minusMonths(parseYYYYMMDD(startDate), 12),
        );
        compareToEndDate = getDateInYYYYMMDD(
          minusMonthsLastDayOfMonth(parseYYYYMMDD(endDate), 12),
        );
      }
      break;
    default:
      compareToStartDate = startDate;
      compareToEndDate = endDate;
      break;
  }

  return { compareToStartDate, compareToEndDate };
}

export function addCompareToTime(
  epochMillis: string,
  compareTo: string | number | Date,
  periodicity: DURATION_SELECT,
): Date {
  const utcDate: Date = new Date(parseInt(epochMillis, 10));
  let actualDate: Date = new Date(utcDate);
  if (periodicity !== DURATION_SELECT.daily) {
    actualDate = new Date();
    actualDate.setFullYear(utcDate.getUTCFullYear());
    actualDate.setMonth(utcDate.getUTCMonth());
    actualDate.setDate(utcDate.getUTCDate());
    actualDate.setHours(0, 0, 0, 0);
  }

  let newDate: Date;

  switch (compareTo) {
    case COMPARE_TO_TYPE.prevDay:
      newDate = plusDays(actualDate, 1);
      break;
    case COMPARE_TO_TYPE.prevWeek:
      newDate = plusWeeks(actualDate, 1);
      break;
    case COMPARE_TO_TYPE.prevMonth:
      if (periodicity === DURATION_SELECT.daily) {
        newDate = plusDays(actualDate, 30);
      } else if (periodicity === DURATION_SELECT.weekly) {
        newDate = plusWeeks(actualDate, 4);
      } else {
        newDate = plusMonthsLastDayOfMonth(actualDate, 1);
      }
      break;
    case COMPARE_TO_TYPE.prevYear:
      if (periodicity === DURATION_SELECT.daily) {
        newDate = plusDays(actualDate, 365);
      } else if (periodicity === DURATION_SELECT.weekly) {
        newDate = plusWeeks(actualDate, 52);
      } else {
        newDate = plusMonthsLastDayOfMonth(actualDate, 12);
      }
      break;
    default:
      if (typeof compareTo === "string") {
        try {
          const customEndDateUtc: Date = convertLocalDateToUtcDate(
            new Date(compareTo),
          );
          const diff: number = calculateDifferenceInDays(
            customEndDateUtc,
            getYesterdayUtcMidnight(),
          );
          newDate = plusDays(actualDate, diff);
        } catch {
          newDate = actualDate;
        }
      } else {
        newDate = actualDate;
      }
      break;
  }

  return newDate;
}

// Example: today is 2024-11-20 17:29 in the America/New_York (ET) time zone
// This method will return 2024-11-19 00:00 in UTC time zone
// Another example: 2024-11-20 00:00 ET => 2024-11-19 00:00 UTC
// A third example: 2024-11-20 23:59 ET => 2024-11-19 00:00 UTC
function getYesterdayUtcMidnight(): Date {
  const today = new Date();
  return new Date(
    Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1,
      0,
      0,
      0,
      0,
    ),
  );
}

export function getLastDurationStr(duration: any): string {
  if (isCustomDuration(duration)) {
    return (
      duration.startDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }) +
      " - " +
      duration.endDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    );
  } else {
    return "the last " + duration;
  }
}

export function getAggregationTypePeriodAndPeriodicity(duration: any) {
  if (isCustomDuration(duration)) {
    return {
      aggregationType: duration.periodicity.toLowerCase(),
      period:
        calculateDifferenceInDays(duration.startDate, duration.endDate) + 1,
      periodicity: duration.periodicity,
    };
  } else {
    const unit = duration.slice(-1); // 'm', 'w', 'd'
    let aggregationType: string;
    let periodicity: DURATION_SELECT;

    switch (unit) {
      case "m":
        aggregationType = "monthly";
        periodicity = DURATION_SELECT.monthly;
        break;
      case "w":
        aggregationType = "weekly";
        periodicity = DURATION_SELECT.weekly;
        break;
      case "d":
        aggregationType = "daily";
        periodicity = DURATION_SELECT.daily;
        break;
      default:
        aggregationType = "total";
        periodicity = DURATION_SELECT.daily;
    }
    const period = parseInt(duration.slice(0, -1), 10);

    return {
      aggregationType: aggregationType,
      period: period,
      periodicity: periodicity,
    };
  }
}

export function getForecastedRevenueSeasonality(duration: any): string {
  let seasonality = "";
  if (isCustomDuration(duration)) {
    switch (duration.periodicity) {
      case DURATION_SELECT.daily:
        seasonality = "DAY";
        break;
      case DURATION_SELECT.weekly:
        seasonality = "WEEK";
        break;
      case DURATION_SELECT.monthly:
        seasonality = "MONTH";
        break;
      default:
        break;
    }
  } else {
    const unit = duration.slice(-1); // 'm', 'w', 'd'
    switch (unit) {
      case "d":
        seasonality = "DAY";
        break;
      case "m":
        seasonality = "MONTH";
        break;
      case "w":
        seasonality = "WEEK";
        break;
      default:
        break;
    }
  }
  return seasonality;
}

export function getPeriodicity(duration: any): DURATION_SELECT {
  if (isCustomDuration(duration)) {
    return duration.periodicity;
  } else {
    const unit = duration.slice(-1); // 'm', 'w', 'd'
    switch (unit) {
      case "d":
        return DURATION_SELECT.daily;
      case "m":
        return DURATION_SELECT.monthly;
      case "w":
        return DURATION_SELECT.weekly;
      default:
        return DURATION_SELECT.monthly;
    }
  }
}

function getMostRecentMonday(date: Date): Date {
  const day: number = date.getDay(); // Sunday is 0, Monday is 1, and so on
  const difference: number = day === 0 ? 6 : day - 1; // Go back to most recent Monday
  return minusDays(date, difference);
}

function printWeekLabel(monday: Date, sunday: Date) {
  return (
    monday.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    }) +
    " - " +
    sunday.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    })
  );
}

export function getWeekText(millis: number): string {
  const utcDate: Date = new Date(millis);
  const date = new Date();
  date.setFullYear(utcDate.getUTCFullYear());
  date.setMonth(utcDate.getUTCMonth());
  date.setDate(utcDate.getUTCDate());
  date.setHours(0, 0, 0, 0);
  const monday: Date = getMostRecentMonday(date);
  const sunday: Date = plusDays(monday, 6);
  return printWeekLabel(monday, sunday);
}

export function getMonthText(millis: number): string {
  return new Date(millis).toLocaleDateString("en-US", {
    timeZone: "UTC",
    year: "2-digit",
    month: "short",
  });
}

export function getWeekTextFromDate(monday: string): string {
  const utcMondayDate: Date = new Date(monday);
  const mondayDate: Date = new Date();
  mondayDate.setFullYear(utcMondayDate.getUTCFullYear());
  mondayDate.setMonth(utcMondayDate.getUTCMonth());
  mondayDate.setDate(utcMondayDate.getUTCDate());
  mondayDate.setHours(0, 0, 0, 0);
  const sundayDate: Date = plusDays(mondayDate, 6);
  return printWeekLabel(mondayDate, sundayDate);
}

export function getMonthTextFromMonth(month: string): string {
  return new Date(month).toLocaleDateString("en-US", {
    timeZone: "UTC",
    year: "2-digit",
    month: "short",
  });
}

function convertLocalDateToUtcDate(date: Date): Date {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0),
  );
}

export function getCompareToDateText(
  millis: any,
  compareTo: COMPARE_TO_TYPE,
  periodicity: DURATION_SELECT,
): string {
  const utcDate: Date = new Date(parseInt(millis));
  const localDate = new Date();
  localDate.setFullYear(utcDate.getUTCFullYear());
  localDate.setMonth(utcDate.getUTCMonth());
  localDate.setDate(utcDate.getUTCDate());
  localDate.setHours(0, 0, 0, 0);
  let compareToDate: Date;
  let compareToUtcDate: Date;
  let compareToDateText: string;
  switch (compareTo) {
    case COMPARE_TO_TYPE.prevDay:
      compareToDate = minusDays(localDate, 1);
      compareToUtcDate = convertLocalDateToUtcDate(compareToDate);
      compareToDateText = formatDate(compareToUtcDate);
      break;
    case COMPARE_TO_TYPE.prevWeek:
      compareToDate = minusWeeks(localDate, 1);
      compareToUtcDate = convertLocalDateToUtcDate(compareToDate);
      if (periodicity === DURATION_SELECT.daily) {
        compareToDateText = formatDate(compareToUtcDate);
      } else {
        compareToDateText = getWeekText(compareToUtcDate.getTime());
      }
      break;
    case COMPARE_TO_TYPE.prevMonth:
      if (periodicity === DURATION_SELECT.daily) {
        compareToDate = minusDays(localDate, 30);
        compareToUtcDate = convertLocalDateToUtcDate(compareToDate);
        compareToDateText = formatDate(compareToUtcDate);
      } else if (periodicity === DURATION_SELECT.weekly) {
        compareToDate = minusWeeks(localDate, 4);
        compareToUtcDate = convertLocalDateToUtcDate(compareToDate);
        compareToDateText = getWeekText(compareToUtcDate.getTime());
      } else {
        compareToDate = minusMonthsForMonthly(localDate, 1);
        compareToUtcDate = convertLocalDateToUtcDate(compareToDate);
        compareToDateText = getMonthText(compareToUtcDate.getTime());
      }
      break;
    case COMPARE_TO_TYPE.prevYear:
      if (periodicity === DURATION_SELECT.daily) {
        compareToDate = minusDays(localDate, 365);
        compareToUtcDate = convertLocalDateToUtcDate(compareToDate);
        compareToDateText = formatDate(compareToUtcDate);
      } else if (periodicity === DURATION_SELECT.weekly) {
        compareToDate = minusWeeks(localDate, 52);
        compareToUtcDate = convertLocalDateToUtcDate(compareToDate);
        compareToDateText = getWeekText(compareToUtcDate.getTime());
      } else {
        compareToDate = minusMonthsForMonthly(localDate, 12);
        compareToUtcDate = convertLocalDateToUtcDate(compareToDate);
        compareToDateText = getMonthText(compareToUtcDate.getTime());
      }
      break;
    default:
      if (typeof compareTo === "string") {
        try {
          const customEndDateUtc: Date = convertLocalDateToUtcDate(
            new Date(compareTo),
          );
          const diff: number = calculateDifferenceInDays(
            customEndDateUtc,
            getYesterdayUtcMidnight(),
          );
          compareToDate = minusDays(localDate, diff);
          compareToUtcDate = convertLocalDateToUtcDate(compareToDate);
          compareToDateText = formatDate(compareToUtcDate);
        } catch {
          compareToDateText = "No compare-to dates";
        }
      } else {
        compareToDateText = "No compare-to dates";
      }
      break;
  }
  return compareToDateText;
}

export function addDashesToYYYYMMDD(noDashDate: string): string {
  return (
    noDashDate.slice(0, 4) +
    "-" +
    noDashDate.slice(4, 6) +
    "-" +
    noDashDate.slice(6, 8)
  );
}
