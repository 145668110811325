import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import { ValidationTypes } from "constants/WidgetValidation";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../zuora.svg";
import styled from "styled-components";
import type { AutoLayoutConfig } from "WidgetProvider/constants";
import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";
import MeterInsightsBaseComponent from "../component/MeterInsightsBaseComponent";
import { DROPDOWN_VARIANT } from "components/editorComponents/WidgetQueryGeneratorForm/CommonControls/DatasourceDropdown/types";
import { createMessage } from "ee/constants/messages";
import {
  JSON_FORM_CONNECT_BUTTON_TEXT,
  SUCCESSFULL_BINDING_MESSAGE,
} from "widgets/JSONFormWidget/constants/messages";
import { FieldOptionsType } from "components/editorComponents/WidgetQueryGeneratorForm/WidgetSpecificControls/OtherFields/Field/Dropdown/types";
import { sourceDataValidationFn } from "widgets/JSONFormWidget/widget/propertyConfig";
import { EvaluationSubstitutionType } from "ee/entities/DataTree/types";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import {
  calculateDatesForPeriod,
  addDashesToYYYYMMDD,
} from "widgets/ZAnalytics/util/DateUtil";
import { DURATION_TYPE } from "widgets/ZAnalytics/util/constants";

const ContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
`;
class ZAnalyticsMeterInsightsWidget extends BaseWidget<
  ZAnalyticsMeterInsightsWidgetProps,
  WidgetState
> {
  static type = "ZAnalyticsMeterInsights_WIDGET";

  static DataConfig = {
    controlConfig: {
      showEditFieldsModal: true, // Shows edit field modals button in the datasource table control
      datasourceDropdownVariant: DROPDOWN_VARIANT.CREATE_OR_EDIT_RECORDS, // Decides the variant of the datasource dropdown which alters the text and some options
      actionButtonCtaText: createMessage(JSON_FORM_CONNECT_BUTTON_TEXT), // CTA text for the connect action button in property pane
      excludePrimaryColumnFromQueryGeneration: true, // Excludes the primary column from the query generation by default
      isConnectableToWidget: true, // Whether this widget can be connected to another widget like Table,List etc
      alertMessage: {
        success: {
          update: createMessage(SUCCESSFULL_BINDING_MESSAGE, "updated"),
        }, // Alert message to show when the binding is successful
      },
      /* other form config options like create or update flow, get default values from widget and data identifier to be used in the generated query as primary key*/
      otherFields: [
        {
          label: "Form Type",
          name: "formType",
          // fieldType: FieldType.SELECT,
          optionType: FieldOptionsType.CUSTOM, // Dropdown options can be custom ( options provided by the widget config like Line 193 ) or widgets ( connectable widgets in the page ) or columns ( columns from the datasource )
          isRequired: true,
          getDefaultValue: () => {
            return "create";
          },
          allowClear: false, // whether the dropdown should have a clear option
          options: [
            {
              label: "Create records",
              value: "create",
              id: "create",
            },
            {
              label: "Edit records",
              value: "edit",
              id: "edit",
            },
          ],
          isVisible: (config: Record<string, any>) => {
            // Whether the field should be visible or not based on the config
            return config?.tableName !== "";
          },
        },
      ],
    },
    isJSConvertible: true,
    placeholderText: '{ "name": "John", "age": 24 }',
    isBindProperty: true,
    isTriggerProperty: false,
    validation: {
      type: ValidationTypes.FUNCTION,
      params: {
        fn: sourceDataValidationFn,
        expected: {
          type: "JSON",
          example: `{ "name": "John Doe", "age": 29 }`,
          autocompleteDataType: AutocompleteDataType.OBJECT,
        },
      },
    },
    evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
  };

  static getConfig() {
    return {
      name: "ZAnalytics Meter Sights",
      tags: [WIDGET_TAGS.ZUORA],
      iconSVG: IconSVG,
      needsMeta: false,
      isCanvas: false,
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getDefaults() {
    return {
      widgetName: "ZAnalyticsMeterInsights",
      rows: 60,
      columns: 60,
      version: 1,
      isVisible: true,
      body: {},
      meterId: "",
      mode: "PERCENT",
      metricType: "SUM",
      dimensions: {},
      duration: DURATION_TYPE.twentyOneDays,
      durationType: DURATION_TYPE.twelveMonth,
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "60 columns",
              minHeight: "60 rows",
            };
          },
        },
      ],
    };
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
      },
    };
  }

  onWidgetRender = () => {
    if (this.props.onLoad) {
      super.executeAction({
        triggerPropertyName: "onLoad",
        dynamicString: this.props.onLoad,
        event: {
          type: EventType.ON_CHANGE,
        },
      });
    }
  };

  setDefaultMeterId = (meterId: string) => {
    this.updateWidgetProperty("meterId", meterId);
  };
  getSegmentation = (
    meterId: any,
    durationType: any,
    metricType = "SUM",
    mode = "PERCENT",
    dimensions = {},
  ) => {
    this.updateWidgetProperty("meterId", meterId);
    // this.updateWidgetProperty("durationType", durationType);
    // this.updateWidgetProperty("metricType", metricType);
    this.updateWidgetProperty("mode", mode);
    this.updateWidgetProperty("dimensions", dimensions);
    this.updateWidgetProperty("duration", durationType);
    this.updateWidgetProperty("durationType", durationType);
    let body = {};
    const { endDate, startDate } = calculateDatesForPeriod(durationType, false);
    if (this.props.getSegmentation) {
      body = {
        endDate: addDashesToYYYYMMDD(endDate),
        startDate: addDashesToYYYYMMDD(startDate),
        meterId,
        metricType,
        mode,
        dimensions,
      };
      this.updateWidgetProperty("body", body);
      super.executeAction({
        triggerPropertyName: "getSegmentation",
        dynamicString: this.props.getSegmentation,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          body: body,
        },
      });
    }
  };

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "metersData",
            helpText: "Input JSON sample for default form layout",
            label: "Meter Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",
            ...ZAnalyticsMeterInsightsWidget.DataConfig,
          },
          {
            propertyName: "segmentationData",
            helpText: "Input JSON sample for default form layout",
            label: "Segementation Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",
            ...ZAnalyticsMeterInsightsWidget.DataConfig,
          },
        ],
      },
      {
        sectionName: "event",
        children: [
          {
            helpText: "when widget is rendered",
            propertyName: "onLoad",
            label: "When widget is rendered",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
          {
            helpText: "Fetch Segmention",
            propertyName: "getSegmentation",
            label: "Fetch Segmention",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      fillColor: "{{appsmith.theme.colors.primaryColor}}",
    };
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    const ZAnalyticsMeterInsightsWidgetProps = {
      ...this.props,
    };

    return (
      <ContainerWrapper>
        <MeterInsightsBaseComponent
          {...ZAnalyticsMeterInsightsWidgetProps}
          durationType={this.props.durationType}
          getSegmentation={this.getSegmentation}
          meterId={this.props.meterId}
          meterList={this.props.metersData?.data || []}
          mode={this.props.mode}
          onWidgetRender={this.onWidgetRender}
          segmentationData={this.props.segmentationData?.data}
          setDefaultMeterId={this.setDefaultMeterId}
        />
      </ContainerWrapper>
    );
  }
}

export interface ZAnalyticsMeterInsightsWidgetProps extends WidgetProps {
  metersData: any;
  onWidgetRender: any;
}

export default ZAnalyticsMeterInsightsWidget;
