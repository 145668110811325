import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import { ValidationTypes } from "constants/WidgetValidation";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../zuora.svg";
import styled from "styled-components";
import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";
import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import type { AutoLayoutConfig } from "WidgetProvider/constants";
import AccountInsightsBaseComponent from "../component/AccountInsightsBaseComponent";
import { sourceDataValidationFn } from "widgets/JSONFormWidget/widget/propertyConfig";
import { EvaluationSubstitutionType } from "ee/entities/DataTree/types";
import { FieldOptionsType } from "components/editorComponents/WidgetQueryGeneratorForm/WidgetSpecificControls/OtherFields/Field/Dropdown/types";
import { DROPDOWN_VARIANT } from "components/editorComponents/WidgetQueryGeneratorForm/CommonControls/DatasourceDropdown/types";
import { createMessage } from "ee/constants/messages";
import {
  JSON_FORM_CONNECT_BUTTON_TEXT,
  SUCCESSFULL_BINDING_MESSAGE,
} from "widgets/JSONFormWidget/constants/messages";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";

const ContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
`;
class ZAnalyticsTableWidget extends BaseWidget<
  ZAnalyticsTableWidgetProps,
  WidgetState
> {
  static type = "ZANALYTICSTABLE_WIDGET";

  static getConfig() {
    return {
      name: "ZAnalytics Table",
      tags: [WIDGET_TAGS.ZUORA],
      iconSVG: IconSVG,
      needsMeta: false,
      isCanvas: false,
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getDefaults() {
    return {
      widgetName: "ZAnalyticsTable",
      rows: 4,
      columns: 4,
      version: 1,
      color: "primary",
      thickness: 3,
      isVisible: true,
      size: "10",
      url: "score/opp",
      page: 0,
      tableType: "opp",
      body: { sort: [{ field: "score", order: "DESC" }] },
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {};
  }

  static queryDataConfig = {
    controlConfig: {
      showEditFieldsModal: true, // Shows edit field modals button in the datasource table control
      datasourceDropdownVariant: DROPDOWN_VARIANT.CREATE_OR_EDIT_RECORDS, // Decides the variant of the datasource dropdown which alters the text and some options
      actionButtonCtaText: createMessage(JSON_FORM_CONNECT_BUTTON_TEXT), // CTA text for the connect action button in property pane
      excludePrimaryColumnFromQueryGeneration: true, // Excludes the primary column from the query generation by default
      isConnectableToWidget: true, // Whether this widget can be connected to another widget like Table,List etc
      alertMessage: {
        success: {
          update: createMessage(SUCCESSFULL_BINDING_MESSAGE, "updated"),
        }, // Alert message to show when the binding is successful
      },
      /* other form config options like create or update flow, get default values from widget and data identifier to be used in the generated query as primary key*/
      otherFields: [
        {
          label: "Form Type",
          name: "formType",
          // fieldType: FieldType.SELECT,
          optionType: FieldOptionsType.CUSTOM, // Dropdown options can be custom ( options provided by the widget config like Line 193 ) or widgets ( connectable widgets in the page ) or columns ( columns from the datasource )
          isRequired: true,
          getDefaultValue: () => {
            return "create";
          },
          allowClear: false, // whether the dropdown should have a clear option
          options: [
            {
              label: "Create records",
              value: "create",
              id: "create",
            },
            {
              label: "Edit records",
              value: "edit",
              id: "edit",
            },
          ],
          isVisible: (config: Record<string, any>) => {
            // Whether the field should be visible or not based on the config
            return config?.tableName !== "";
          },
        },
      ],
    },
    isJSConvertible: true,
    placeholderText: '{ "name": "John", "age": 24 }',
    isBindProperty: true,
    isTriggerProperty: false,
    validation: {
      type: ValidationTypes.FUNCTION,
      params: {
        fn: sourceDataValidationFn,
        expected: {
          type: "JSON",
          example: `{ "name": "John Doe", "age": 29 }`,
          autocompleteDataType: AutocompleteDataType.OBJECT,
        },
      },
    },
    evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
  };

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
      },
    };
  }

  getCountryListData = () => {
    if (this.props.getCountryList) {
      super.executeAction({
        triggerPropertyName: "getCountryList",
        dynamicString: this.props.getCountryList,
        event: {
          type: EventType.ON_CHANGE,
        },
      });
    }
  };

  onTableLoaded = (tableType = "opp", page = 0, size = 10, payload = null) => {
    let url = "score/opp";
    if (tableType === "risk") {
      url = "score/risk";
    }
    if (tableType !== this.props.tableType) {
      page = 0;
      size = 10;
    }
    this.updateWidgetProperty("url", url);
    this.updateWidgetProperty("page", page);
    this.updateWidgetProperty("size", size);
    if (payload) this.updateWidgetProperty("body", payload);
    if (this.props.onTableTypeChanged) {
      super.executeAction({
        triggerPropertyName: "onTableTypeChanged",
        dynamicString: this.props.onTableTypeChanged,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          url: url || this.props.url,
          page: page || this.props.page,
          size: size || this.props.size,
          body: payload || this.props.body,
        },
      });
    }
  };

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "tableType",
            label: "Table type",
            helpText: "Controls the font family being used",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "Risk",
                value: "risk",
              },
              {
                label: "Opportunity",
                value: "opp",
              },
            ],
            defaultValue: "opp",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "riskOrOppData",
            helpText: "Input JSON sample for default form layout",
            label: "Risk or opportunity  Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",
            ...ZAnalyticsTableWidget.queryDataConfig,
          },
          {
            propertyName: "countryData",
            helpText: "Input JSON sample for default form layout",
            label: "Country Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",
            ...ZAnalyticsTableWidget.queryDataConfig,
          },
        ],
      },
      {
        sectionName: "event",
        children: [
          {
            helpText: "when table type is changed",
            propertyName: "onTableTypeChanged",
            label: "When table type is changed",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
          {
            helpText: "Get all country list",
            propertyName: "getCountryList",
            label: "Get all country list",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      fillColor: "{{appsmith.theme.colors.primaryColor}}",
    };
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    const ZAnalyticsTableWidgetProps = {
      ...this.props,
    };

    return (
      <ContainerWrapper>
        <AccountInsightsBaseComponent
          countryData={this.props.countryData?.data}
          getCountryListData={this.getCountryListData}
          onTableLoaded={this.onTableLoaded}
          oppLoading={this.props.riskOrOppData?.isLoading}
          riskOrOppData={this.props.riskOrOppData}
          {...ZAnalyticsTableWidgetProps}
        />
      </ContainerWrapper>
    );
  }
}

export interface ZAnalyticsTableWidgetProps extends WidgetProps {
  size: number | string;
  thickness?: number;
  fillColor: string;
  tableType: any;
}

export default ZAnalyticsTableWidget;
