import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import DurationSelector from "widgets/ZAnalytics/ZAnalyticsForecast/component/DurationSelector";
import { Select, SelectItem, Typography } from "zds";
import AnalyseTypeAhead from "./AnalyseTypeAhead";
import { calculateDatesForPeriod } from "widgets/ZAnalytics/util/DateUtil";
import VerticalBarChart from "./VerticalChart";

interface Props {
  customerOrProductData: any;
  getAccountsOrProducts: any;
  riskOrOppData: any;
  getBillabelUsageOnAnalyzeChange: any;
  analyzeFilter: string;
  duration: any;
  durationType: any;
  filterObjects: any;
}
export type analyzeBy = "analyzeByProduct" | "analyzeByCustomer";
const BillableUsage = (props: Props) => {
  const {
    analyzeFilter,
    customerOrProductData,
    duration,
    durationType,
    getAccountsOrProducts,
    getBillabelUsageOnAnalyzeChange,
    riskOrOppData,
    filterObjects,
  } = props;
  const [selectedDuration, setSelectedDuration] = useState(duration);
  const [selectedObjects, setSelectedObjects] = useState<any>([
    ...filterObjects,
  ]);
  const handleDurationSelection = (
    filterType: any,
    durationType: any,
    selectedDuration: any,
  ) => {
    const { endDate, startDate } = calculateDatesForPeriod(
      selectedDuration || durationType,
      false,
    );
    setSelectedDuration(selectedDuration);
    getAccountsOrProducts(
      startDate,
      endDate,
      analyzeFilter,
      durationType,
      filterType.charAt(0).toUpperCase() + filterType.slice(1),
    );
  };
  useEffect(() => {
    if (
      selectedObjects?.length > -1 &&
      selectedObjects?.length != filterObjects?.length
    ) {
      getBillabelUsageOnAnalyzeChange(
        analyzeFilter,
        duration,
        "",
        selectedObjects,
      );
    }
  }, [selectedObjects]);
  const handleAnalyzeFilterChange = (e: any) => {
    getBillabelUsageOnAnalyzeChange(e.target.value, duration);
  };
  return (
    <Stack direction="column" spacing={2}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="flex-start"
        spacing={2}
      >
        <DurationSelector
          currentDuration={duration}
          durationType={durationType}
          handleDurationSelection={handleDurationSelection}
        />
        <Typography variant="title">Analysed by:</Typography>
        <Select
          dsOnChange={handleAnalyzeFilterChange}
          fullWidth={false}
          value={analyzeFilter}
        >
          <SelectItem value="analyzeByProduct">
            <Typography>Product</Typography>
          </SelectItem>
          <SelectItem value="analyzeByCustomer">
            <Typography>Customer</Typography>
          </SelectItem>
        </Select>
        <AnalyseTypeAhead
          analyzeBy={analyzeFilter}
          customerOrProductData={customerOrProductData}
          getAccountsOrProducts={getAccountsOrProducts}
          selectedDuration={selectedDuration}
          selectedObjects={selectedObjects}
          setSelectedObjects={setSelectedObjects}
        />
      </Stack>
      <VerticalBarChart
        data={
          riskOrOppData?.data?.accountSortByRevenueList ||
          riskOrOppData?.data?.productByRevenueList ||
          []
        }
        loading={false}
      />
    </Stack>
  );
};

export default BillableUsage;
