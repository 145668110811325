export enum DURATION_SELECT {
  daily = "Daily",
  weekly = "Weekly",
  monthly = "Monthly",
}

export enum DURATION_TYPE {
  sevenDays = "7d",
  fourteenDays = "14d",
  twentyOneDays = "21d",
  thirtyDays = "30d",
  sixtyDays = "60d",
  ninetyDays = "90d",
  fourWeeks = "4w",
  eightWeeks = "8w",
  twelveWeeks = "12w",
  twentyFourWeeks = "24w",
  oneMonth = "1m",
  threeMonth = "3m",
  sixMonth = "6m",
  twelveMonth = "12m",
}

// Define revenue types
export const REVENUE_TYPE = {
  MonthToDate: "MonthToDate",
  LastMonthSamePeriod: "LastMonthSamePeriod",
  LastMonthTotal: "LastMonthTotal",
  LastYearTotal: "LastYearTotal",
  Forecast: "Forecast",
};

export enum COMPARE_TO_TYPE {
  none = "None",
  prevDay = "Previous Day",
  prevWeek = "Previous Week",
  prevMonth = "Previous Month",
  prevYear = "Previous Year",
  customEndDate = "Custom End Date",
}
