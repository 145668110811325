import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import {
  ValidationTypes,
  type ValidationResponse,
} from "constants/WidgetValidation";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import ChartBaseComponent from "../component/ChartBaseComponent";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../zuora.svg";
import styled from "styled-components";
import type { SetterConfig, Stylesheet } from "../../../../entities/AppTheming";
import type { AutoLayoutConfig } from "../../../../WidgetProvider/constants";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import { DROPDOWN_VARIANT } from "components/editorComponents/WidgetQueryGeneratorForm/CommonControls/DatasourceDropdown/types";
import { createMessage } from "ee/constants/messages";
import { FieldOptionsType } from "components/editorComponents/WidgetQueryGeneratorForm/WidgetSpecificControls/OtherFields/Field/Dropdown/types";
import { EvaluationSubstitutionType } from "ee/entities/DataTree/types";
import { AutocompleteDataType } from "../../../../utils/autocomplete/AutocompleteDataType";
import {
  JSON_FORM_CONNECT_BUTTON_TEXT,
  SUCCESSFULL_BINDING_MESSAGE,
} from "widgets/JSONFormWidget/constants/messages";
import { calculateDatesForPeriod } from "widgets/ZAnalytics/util/DateUtil";
import {
  DURATION_SELECT,
  DURATION_TYPE,
} from "widgets/ZAnalytics/util/constants";

export const sourceDataValidationFn = (
  value: any,
  props: any,
  _?: any,
): ValidationResponse => {
  if (value === "") {
    return {
      isValid: true,
      parsed: value,
    };
  }

  if (value === null || value === undefined) {
    return {
      isValid: false,
      parsed: value,
      messages: [
        {
          name: "ValidationError",
          message: `Data is undefined`,
        },
      ],
    };
  }

  if (_.isObject(value) && Object.keys(value).length === 0) {
    return {
      isValid: false,
      parsed: value,
      messages: [
        {
          name: "ValidationError",
          message: "Data is empty",
        },
      ],
    };
  }

  if (_.isNumber(value) || _.isBoolean(value)) {
    return {
      isValid: false,
      parsed: {},
      messages: [
        {
          name: "ValidationError",
          message: `Source data cannot be ${value}`,
        },
      ],
    };
  }

  if (_.isNil(value)) {
    return {
      isValid: true,
      parsed: {},
    };
  }

  if (_.isArray(value)) {
    return {
      isValid: false,
      parsed: {},
      messages: [
        {
          name: "TypeError",
          message: `The value does not evaluate to type Object`,
        },
      ],
    };
  }

  if (_.isPlainObject(value)) {
    return {
      isValid: true,
      parsed: value,
    };
  }

  try {
    return {
      isValid: true,
      parsed: JSON.parse(value as string),
    };
  } catch (e) {
    return {
      isValid: false,
      parsed: {},
      messages: [e as Error],
    };
  }
};

const ContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
`;
class ZAnalyticsChartWidget extends BaseWidget<
  ZAnalyticsChartWidgetProps,
  WidgetState
> {
  static type = "ZAnalyticsChart_WIDGET";
  static DataConfig = {
    controlConfig: {
      showEditFieldsModal: true, // Shows edit field modals button in the datasource table control
      datasourceDropdownVariant: DROPDOWN_VARIANT.CREATE_OR_EDIT_RECORDS, // Decides the variant of the datasource dropdown which alters the text and some options
      actionButtonCtaText: createMessage(JSON_FORM_CONNECT_BUTTON_TEXT), // CTA text for the connect action button in property pane
      excludePrimaryColumnFromQueryGeneration: true, // Excludes the primary column from the query generation by default
      isConnectableToWidget: true, // Whether this widget can be connected to another widget like Table,List etc
      alertMessage: {
        success: {
          update: createMessage(SUCCESSFULL_BINDING_MESSAGE, "updated"),
        }, // Alert message to show when the binding is successful
      },
      /* other form config options like create or update flow, get default values from widget and data identifier to be used in the generated query as primary key*/
      otherFields: [
        {
          label: "Form Type",
          name: "formType",
          // fieldType: FieldType.SELECT,
          optionType: FieldOptionsType.CUSTOM, // Dropdown options can be custom ( options provided by the widget config like Line 193 ) or widgets ( connectable widgets in the page ) or columns ( columns from the datasource )
          isRequired: true,
          getDefaultValue: () => {
            return "create";
          },
          allowClear: false, // whether the dropdown should have a clear option
          options: [
            {
              label: "Create records",
              value: "create",
              id: "create",
            },
            {
              label: "Edit records",
              value: "edit",
              id: "edit",
            },
          ],
          isVisible: (config: Record<string, any>) => {
            // Whether the field should be visible or not based on the config
            return config?.tableName !== "";
          },
        },
      ],
    },
    isJSConvertible: true,
    placeholderText: '{ "name": "John", "age": 24 }',
    isBindProperty: true,
    isTriggerProperty: false,
    validation: {
      type: ValidationTypes.FUNCTION,
      params: {
        fn: sourceDataValidationFn,
        expected: {
          type: "JSON",
          example: `{ "name": "John Doe", "age": 29 }`,
          autocompleteDataType: AutocompleteDataType.OBJECT,
        },
      },
    },
    evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
  };

  static getConfig() {
    return {
      name: "ZAnalytics Chart",
      tags: [WIDGET_TAGS.ZUORA],
      iconSVG: IconSVG,
      needsMeta: false,
      isCanvas: false,
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getDefaults() {
    const { endDate, startDate } = calculateDatesForPeriod(
      DURATION_TYPE.sixMonth,
      true,
    );
    return {
      widgetName: "ZAnalyticsChart",
      rows: 30,
      columns: 30,
      version: 1,
      color: "primary",
      isVisible: true,
      size: 5,
      chartType: "risk",
      path: "usage/score/risk",
      filterPath: "customer/accounts?filter=",
      aggregationType: "total",
      startDate: startDate,
      endDate: endDate,
      limit: 5,
      body: { sort: [{ field: "score", order: "DESC" }] },
      currencyIsoCode: "USD",
      sumTrailingCustomer: false,
      analyzeFilter: "analyzeByCustomer",
      duration: DURATION_TYPE.sixMonth,
      durationType: DURATION_SELECT.monthly,
      filterObjects: [],
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "30 columns",
              minHeight: "30 rows",
            };
          },
        },
      ],
    };
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
      },
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "size",
            label: "Size",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "chartType",
            label: "Chart type",
            helpText: "Controls the font family being used",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "Risk",
                value: "risk",
              },
              {
                label: "Opportunity",
                value: "opportunity",
              },
              {
                label: "Accounts",
                value: "accounts",
              },
              {
                label: "Products",
                value: "products",
              },
              {
                label: "Billable Usage Breakdown",
                value: "usageBreakdown",
              },
            ],
            defaultValue: "risk",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "fillColor",
            label: "Fill color",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^((?![<|{{]).+){0,1}/,
                expected: {
                  type: "string (HTML color name or HEX value)",
                  example: `red | #9C0D38`,
                  autocompleteDataType: AutocompleteDataType.STRING,
                },
              },
            },
          },
          {
            propertyName: "riskOrOppData",
            helpText: "Input JSON sample for default form layout",
            label: "Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",
            ...ZAnalyticsChartWidget.DataConfig,
          },
          {
            propertyName: "customerOrProductData",
            helpText: "Input JSON sample for default form layout",
            label: "Filter Source data",
            controlType: "ONE_CLICK_BINDING_CONTROL",
            ...ZAnalyticsChartWidget.DataConfig,
          },
        ],
      },
      {
        sectionName: "event",
        children: [
          {
            helpText: "when chart type is changed",
            propertyName: "onChartTypeChanged",
            label: "When chart type is changed",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
          {
            helpText: "when chart type is changed",
            propertyName: "getCustomerOrProducts",
            label: "When chart type is changed usageBreakdown",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  getAccountsOrProducts = (
    startDate: any,
    endDate: any,
    type = "analyzeByCustomer",
    duration: any,
    durationType: any,
    makeOnlySearch = false,
    query = "",
  ) => {
    let filterPath = "customer/accounts?filter=";
    let path = null;
    let body: any = null;
    if (this.props.analyzeFilter !== type) {
      this.updateWidgetProperty("filterObjects", []);
    }
    if (type === "analyzeByCustomer") {
      filterPath = "customer/accounts?filter=" + query;
      path = "usage/revenue/top-accounts";
      body = {
        accountIds: [],
      };
    }
    if (type === "analyzeByProduct") {
      filterPath = "products/usage?filter=" + query;
      path = "usage/revenue/top-products";
      body = {
        productIds: [],
      };
    }
    this.updateWidgetProperty("path", path);
    this.updateWidgetProperty("filterPath", filterPath);
    this.updateWidgetProperty("body", body);
    if (duration) {
      this.updateWidgetProperty("duration", duration);
      this.updateWidgetProperty("durationType", durationType);
    }
    if (startDate) {
      this.updateWidgetProperty("startDate", startDate);
      this.updateWidgetProperty("endDate", endDate);
    }
    if (this.props.getCustomerOrProducts) {
      super.executeAction({
        triggerPropertyName: "getCustomerOrProducts",
        dynamicString: this.props.getCustomerOrProducts,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          filterPath: filterPath,
        },
      });
    }
    if (makeOnlySearch) return;
    if (this.props.onChartTypeChanged) {
      super.executeAction({
        triggerPropertyName: "onChartTypeChanged",
        dynamicString: this.props.onChartTypeChanged,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          size: 10,
          chartType: "usageBreakdown",
          path: path,
          aggregationType: "total",
          startDate,
          endDate,
          body: body,
          limit: 10,
          currencyIsoCode: "USD",
          sumTrailingCustomer: false,
        },
      });
    }
  };

  getBillabelUsageOnAnalyzeChange = (
    type = "analyzeByCustomer",
    duration: any,
    query = "",
    selectedObjects = [],
  ) => {
    const { endDate, startDate } = calculateDatesForPeriod(duration, true);
    let filterPath = "customer/accounts?filter=";
    let path = null;
    let body: any = null;
    this.updateWidgetProperty("analyzeFilter", type);
    this.updateWidgetProperty("filterObjects", selectedObjects);
    if (type === "analyzeByCustomer") {
      filterPath = "customer/accounts?filter=" + query;
      path = "usage/revenue/top-accounts";
      body = {
        accountIds: selectedObjects.map((acc: any) => acc.accountId),
      };
    }
    if (type === "analyzeByProduct") {
      filterPath = "products/usage?filter=" + query;
      path = "usage/revenue/top-products";
      body = {
        productIds: selectedObjects.map((pro: any) => pro.productId),
      };
    }
    this.updateWidgetProperty("path", path);
    this.updateWidgetProperty("filterPath", filterPath);
    this.updateWidgetProperty("body", body);
    this.updateWidgetProperty("duration", duration);
    if (this.props.getCustomerOrProducts) {
      super.executeAction({
        triggerPropertyName: "getCustomerOrProducts",
        dynamicString: this.props.getCustomerOrProducts,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          filterPath: filterPath,
        },
      });
    }
    if (this.props.onChartTypeChanged) {
      super.executeAction({
        triggerPropertyName: "onChartTypeChanged",
        dynamicString: this.props.onChartTypeChanged,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          size: 10,
          chartType: "usageBreakdown",
          path: path,
          aggregationType: "total",
          body: body,
          limit: 10,
          startDate: startDate,
          endDate: endDate,
          currencyIsoCode: "USD",
          sumTrailingCustomer: false,
          duration: duration,
        },
      });
    }
  };

  onSizeChange = (size: string | number, chartType: string) => {
    const { endDate, startDate } = calculateDatesForPeriod(
      DURATION_TYPE.twelveMonth,
      true,
    );
    let path = "usage/score/risk";
    let body: any = { sort: [{ field: "score", order: "DESC" }] };
    if (chartType === "opportunity") {
      path = "usage/score/opp";
    }
    if (chartType === "accounts") {
      path = "usage/revenue/top-accounts";
      body = { accountIds: [] };
    }

    if (chartType === "products") {
      path = "usage/revenue/top-products";
      body = { productIds: [] };
    }
    if (chartType === "usageBreakdown") {
      const filterPath = "customer/accounts?filter=";
      const { endDate, startDate } = calculateDatesForPeriod(
        DURATION_TYPE.twentyOneDays,
        true,
      );
      this.updateWidgetProperty("filterPath", filterPath);
      this.updateWidgetProperty("path", path);
      if (this.props.getCustomerOrProducts) {
        super.executeAction({
          triggerPropertyName: "getCustomerOrProducts",
          dynamicString: this.props.getCustomerOrProducts,
          event: {
            type: EventType.ON_CHANGE,
          },
          globalContext: {
            filterPath: filterPath,
            startDate: startDate,
            endDate: endDate,
            path: path,
          },
        });
      }
      if (this.props.onChartTypeChanged) {
        super.executeAction({
          triggerPropertyName: "onChartTypeChanged",
          dynamicString: this.props.onChartTypeChanged,
          event: {
            type: EventType.ON_CHANGE,
          },
          globalContext: {
            size: 10,
            chartType: "usageBreakdown",
            path: path,
            aggregationType: "total",
            startDate,
            endDate,
            body: { accountIds: [] },
            limit: 10,
            currencyIsoCode: "USD",
            sumTrailingCustomer: false,
          },
        });
      }
      this.getAccountsOrProducts(
        startDate,
        endDate,
        this.props.analyzeFilter,
        this.props.duration,
        this.props.durationType,
      );
      return;
    }
    this.updateWidgetProperty("path", path);
    this.updateWidgetProperty("body", body);
    this.updateWidgetProperty("size", +size);
    this.updateWidgetProperty("chartType", this.props.chartType);
    if (this.props.onChartTypeChanged) {
      super.executeAction({
        triggerPropertyName: "onChartTypeChanged",
        dynamicString: this.props.onChartTypeChanged,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          size: +size,
          chartType: chartType,
          path: path,
          aggregationType: "total",
          startDate,
          endDate,
          body: body,
          limit: +size,
          currencyIsoCode: "USD",
          sumTrailingCustomer: false,
        },
      });
    }
  };

  static getStylesheetConfig(): Stylesheet {
    return {
      fillColor: "{{appsmith.theme.colors.primaryColor}}",
    };
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    return (
      <ContainerWrapper>
        <ChartBaseComponent
          analyzeFilter={this.props.analyzeFilter}
          chartType={this.props.chartType}
          customerOrProductData={this.props.customerOrProductData?.data}
          duration={this.props.duration}
          durationType={this.props.durationType}
          fillColor={this.props.fillColor}
          getAccountsOrProducts={this.getAccountsOrProducts}
          getBillabelUsageOnAnalyzeChange={this.getBillabelUsageOnAnalyzeChange}
          isLoading={this.props.riskOrOppData?.isLoading}
          onSizeChange={this.onSizeChange}
          riskOrOppData={this.props.riskOrOppData?.data}
          size={this.props.size}
          filterObjects={this.props.filterObjects}
        />
      </ContainerWrapper>
    );
  }
}

export interface ZAnalyticsChartWidgetProps extends WidgetProps {
  size: number | string;
  fillColor: string;
  color: string;
  isVisible: boolean;
  chartType: string;
  path: string;
  aggregationType: string;
  startDate: string;
  endDate: string;
  limit: number;
  body: any;
  currencyIsoCode: string;
  sumTrailingCustomer: boolean;
}

export default ZAnalyticsChartWidget;
