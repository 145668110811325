import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import { ValidationTypes } from "constants/WidgetValidation";
import { generateClassName, getCanvasClassName } from "utils/generators";
import {
  GridDefaults,
  WIDGET_TAGS,
  WidgetHeightLimits,
} from "constants/WidgetConstants";
import { Card, CardHeader } from "@mui/material";
import { Typography } from "zds";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../zuora.svg";
import {
  isAutoHeightEnabledForWidget,
  isAutoHeightEnabledForWidgetWithLimits,
  scrollCSS,
} from "widgets/WidgetUtils";
import styled from "styled-components";

import { renderAppsmithCanvas } from "layoutSystems/CanvasFactory";
import {
  LayoutDirection,
  Positioning,
} from "layoutSystems/common/utils/constants";
import { LayoutSystemTypes } from "layoutSystems/types";
import {
  type AutocompletionDefinitions,
  type AutoLayoutConfig,
  BlueprintOperationTypes,
  type FlattenedWidgetProps,
} from "WidgetProvider/constants";
import type { CanvasWidgetsReduxState } from "ee/reducers/entityReducers/canvasWidgetsReducer";
import { get, sortBy } from "lodash";
import type { LayoutProps } from "../../../layoutSystems/anvil/utils/anvilTypes";
import { generateDefaultLayoutPreset } from "layoutSystems/anvil/layoutComponents/presets/DefaultLayoutPreset";
import { getWidgetBluePrintUpdates } from "utils/WidgetBlueprintUtils";

import type { HeaderActionItem, ZCardWidgetProps } from "../constants";
import { PageHeaderActions } from "../component/PageHeaderActions";
import {
  EventType,
  type ExecuteTriggerPayload,
} from "constants/AppsmithActionConstants/ActionConstants";
import { ButtonVariantTypes } from "../../ZButtonWidget/constants";
import type { Stylesheet } from "entities/AppTheming";
import { klona as clone } from "klona/full";
import { getBasePropertyPath } from "../../TableWidget/widget/propertyUtils";
import derivedProperties from "./parseDerivedProperties";
import { generateTypeDef } from "utils/autocomplete/defCreatorUtils";
import { EvaluationSubstitutionType } from "ee/entities/DataTree/types";
import { AutocompleteDataType } from "../../../utils/autocomplete/AutocompleteDataType";
import { defaultSelectedValuesValidation } from "../../CheckboxGroupWidget/widget";
import ZEditButtonWidget from "../../ZEditButtonWidget";
import { MenuItemsSource } from "../constants";
import {
  updateMenuItemsSource,
  hiddenForMenuActionOnly,
  hiddenNotMenuActionDynamic,
  hiddenNotMenuActionStatic,
  updateHookOnActionTypeChange,
  getOptionLabelValueExpressionPrefix,
  optionLabelValueExpressionSuffix,
  getLabelValueAdditionalAutocompleteData,
  getLabelValueKeyOptions,
  labelKeyValidation,
  valueKeyValidation,
  hiddenExceptSelect,
} from "./propertyConfig/propertyUtils";
import { sourceDataArrayValidation } from "../../MenuButtonWidget/validations";
import configureMenuItemsConfig from "./propertyConfig/childPanels/configureMenuItemsConfig";

const ScrollCanvas = styled.div<{ $shouldScrollContents: boolean }>`
  overflow: hidden;
  ${(props) => (props.$shouldScrollContents ? scrollCSS : ``)}
  width: 100%;
  padding-bottom: 16px;
`;

const ContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
`;
const Container = styled.div`
  width: 100%;
  && .MuiCardContent-root {
    padding-top: unset;
  }
  && .MuiCardHeader-root {
    padding: 10px;
  }
  && .MuiCardHeader-action {
    margin-right: unset;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
class ZCardWidget extends BaseWidget<ZCardWidgetProps, WidgetState> {
  static type = "ZCARD_WIDGET";

  static getConfig() {
    return {
      name: "ZCard",
      tags: [WIDGET_TAGS.ZUORA],
      iconSVG: IconSVG,
      needsMeta: false,
      isCanvas: true,
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getDefaults() {
    return {
      widgetName: "ZCard",
      rows: 10,
      columns: 50,
      version: 1,
      title: "Card Title",
      titleBar: true,
      maxHeaderActionDisplay: 2,
      headerActions: {
        headerAction1: {
          label: "Favorite",
          starIcon: "heart",
          id: "headerAction1",
          widgetId: "",
          actionType: "BUTTON",
          isVisible: true,
          isDisabled: false,
          divider: true,
          index: 0,
          variant: "contained",
          menuItems: {},
          selectWidth: 100,
          menuItemsSource: "STATIC",
        },
        headerAction2: {
          label: "Enabled",
          id: "headerAction2",
          actionType: "TOGGLE",
          widgetId: "",
          isVisible: true,
          isDisabled: false,
          divider: true,
          index: 1,
          value: true,
          menuItems: {},
          selectWidth: 100,
          menuItemsSource: "STATIC",
        },
        headerAction3: {
          label: "Check",
          id: "headerAction3",
          actionType: "CHECKBOX",
          widgetId: "",
          isVisible: true,
          isDisabled: false,
          divider: true,
          index: 2,
          value: true,
          menuItems: {},
          selectWidth: 100,
          menuItemsSource: "STATIC",
        },
        headerAction4: {
          label: "myRadio",
          id: "headerAction4",
          actionType: "RADIO",
          widgetId: "",
          isVisible: true,
          isDisabled: false,
          divider: true,
          index: 3,
          options: [
            { label: "Blue", value: "BLUE" },
            { label: "Green", value: "GREEN" },
            { label: "Red", value: "RED" },
          ],
          defaultSelectedOption: "BLUE",
          menuItems: {},
          selectWidth: 100,
          menuItemsSource: "STATIC",
        },
        headerAction5: {
          label: "mySelect",
          id: "headerAction5",
          actionType: "SELECT",
          widgetId: "",
          isVisible: true,
          isDisabled: false,
          index: 4,
          options: [
            { label: "Blue", value: "BLUE" },
            { label: "Green", value: "GREEN" },
            { label: "Red", value: "RED" },
          ],
          defaultSelectedOption: "RED",
          menuItems: {},
          selectWidth: 100,
          menuItemsSource: "STATIC",
          optionLabel: "label",
          optionValue: "value",
        },
      },
      showEditButton: "{{appsmith.zCustomVars.Edit || false}}",
      blueprint: {
        view: [
          {
            type: "CANVAS_WIDGET",
            position: { top: 0, left: 0 },
            props: {
              detachFromLayout: true,
              canExtend: true,
              isVisible: true,
              isDisabled: false,
              showEditButton: "{{ appsmith.zCustomVars.Edit || false }}",
              shouldScrollContents: false,
              children: [],
              version: 1,
              bottomRow: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS,
            },
          },
        ],
        operations: [
          {
            type: BlueprintOperationTypes.MODIFY_PROPS,
            fn: (
              widget: FlattenedWidgetProps,
              widgets: CanvasWidgetsReduxState,
              parent: FlattenedWidgetProps,
              layoutSystemType: LayoutSystemTypes,
            ) => {
              if (layoutSystemType !== LayoutSystemTypes.ANVIL) {
                return [];
              }

              //get Canvas Widget
              const canvasWidget: FlattenedWidgetProps = get(
                widget,
                "children.0",
              );

              const layout: LayoutProps[] = generateDefaultLayoutPreset();

              return getWidgetBluePrintUpdates({
                [canvasWidget.widgetId]: {
                  layout,
                },
              });
            },
          },
          {
            type: BlueprintOperationTypes.MODIFY_PROPS,
            fn: (widget: WidgetProps & { children?: WidgetProps[] }) => {
              const headerActions = clone(widget.headerActions) || {};
              const dynamicBindingPathList: any[] = get(
                widget,
                "dynamicBindingPathList",
                [],
              );

              Object.keys(headerActions).map((action) => {
                headerActions[action].buttonColor = get(
                  widget,
                  "childStylesheet.button.buttonColor",
                  "{{appsmith.theme.colors.primaryColor}}",
                );

                dynamicBindingPathList.push({
                  key: `headerActions.${action}.buttonColor`,
                });
              });

              const updatePropertyMap = [
                {
                  widgetId: widget.widgetId,
                  propertyName: "dynamicBindingPathList",
                  propertyValue: dynamicBindingPathList,
                },
                {
                  widgetId: widget.widgetId,
                  propertyName: "headerActions",
                  propertyValue: headerActions,
                },
              ];

              return updatePropertyMap;
            },
          },
        ],
      },
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {};
  }

  static getMethods() {
    return {
      getCanvasHeightOffset: (props: WidgetProps): number => {
        let offset =
          props.borderWidth && props.borderWidth > 1
            ? Math.ceil(
                (2 * parseInt(props.borderWidth, 10) || 0) /
                  GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
              )
            : 0;
        return (offset += 10);
      },
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "title",
            label: "Card Title",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "subtitle",
            label: "Subtitle",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          // {
          //   propertyName: "footer",
          //   label: "Footer",
          //   controlType: "INPUT_TEXT",
          //   isBindProperty: true,
          //   isTriggerProperty: false,
          //   validation: { type: ValidationTypes.TEXT },
          // },
          {
            propertyName: "headerActions",
            label: "Header Action",
            controlType: "HEADER_ACTION",
            dependencies: ["childStylesheet"],
            isBindProperty: false,
            isTriggerProperty: false,
            panelConfig: {
              editableTitle: true,
              titlePropertyName: "label",
              panelIdPropertyName: "id",
              updateHook: (
                props: any,
                propertyPath: string,
                propertyValue: string,
              ) => {
                return [
                  {
                    propertyPath,
                    propertyValue,
                  },
                ];
              },
              contentChildren: [
                {
                  sectionName: "Data",
                  children: [
                    {
                      propertyName: "actionType",
                      label: "Action type",
                      controlType: "DROP_DOWN",
                      fullWidth: true,
                      options: [
                        {
                          label: "Button",
                          value: "BUTTON",
                        },
                        {
                          label: "Menu",
                          value: "MENU",
                        },
                        {
                          label: "Checkbox",
                          value: "CHECKBOX",
                        },
                        {
                          label: "Radio",
                          value: "RADIO",
                        },
                        {
                          label: "Select",
                          value: "SELECT",
                        },
                        {
                          label: "Toggle",
                          value: "TOGGLE",
                        },
                      ],
                      defaultValue: "BUTTON",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: {
                        type: ValidationTypes.TEXT,
                        params: {
                          allowedValues: [
                            "BUTTON",
                            "TOGGLE",
                            "CHECKBOX",
                            "RADIO",
                            "SELECT",
                            "MENU",
                          ],
                        },
                      },
                      updateHook: updateHookOnActionTypeChange,
                    },
                    {
                      propertyName: "menuItemsSource",
                      helpText: "Sets the source for the menu items",
                      label: "Menu items source",
                      controlType: "ICON_TABS",
                      defaultValue: MenuItemsSource.STATIC,
                      fullWidth: true,
                      options: [
                        {
                          label: "Static",
                          value: MenuItemsSource.STATIC,
                        },
                        {
                          label: "Dynamic",
                          value: MenuItemsSource.DYNAMIC,
                        },
                      ],
                      isJSConvertible: false,
                      isBindProperty: false,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                      updateHook: updateMenuItemsSource,
                      dependencies: [
                        "sourceData",
                        "configureMenuItems",
                        "headerActions",
                      ],
                      hidden: hiddenForMenuActionOnly,
                    },
                    {
                      helpText:
                        "Takes in an array of items to display the menu items.",
                      propertyName: "sourceData",
                      // updateHook: (
                      //   props: any,
                      //   propertyPath: string,
                      //   propertyValue: string,
                      // ) => {
                      //   return [
                      //     {
                      //       propertyPath,
                      //       propertyValue,
                      //     },
                      //   ];
                      // },
                      label: "Source data",
                      controlType: "INPUT_TEXT",
                      placeholderText: "{{Query1.data}}",
                      inputType: "ARRAY",
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: {
                        type: ValidationTypes.FUNCTION,
                        params: {
                          fn: sourceDataArrayValidation,
                          expected: {
                            type: "Array of values",
                            example: `['option1', 'option2'] | [{ "label": "label1", "value": "value1" }]`,
                            autocompleteDataType: AutocompleteDataType.ARRAY,
                          },
                        },
                      },
                      evaluationSubstitutionType:
                        EvaluationSubstitutionType.SMART_SUBSTITUTE,
                      hidden: hiddenNotMenuActionDynamic,
                      dependencies: ["menuItemsSource"],
                    },
                    {
                      helpText: "Configure how each menu item will appear.",
                      propertyName: "configureMenuItems",
                      controlType: "OPEN_CONFIG_PANEL",
                      buttonConfig: {
                        label: "Configure",
                        icon: "settings-2-line",
                      },
                      label: "Configure menu items",
                      isBindProperty: false,
                      isTriggerProperty: false,
                      hidden: hiddenNotMenuActionDynamic,
                      dependencies: ["menuItemsSource", "sourceData"],
                      panelConfig: configureMenuItemsConfig,
                    },
                    {
                      hidden: hiddenNotMenuActionStatic,
                      dependencies: ["actionType", "menuItemsSource"],
                      helpText: "Menu items",
                      propertyName: "menuItems",
                      controlType: "MENU_ITEMS",
                      label: "Menu items",
                      isBindProperty: false,
                      isTriggerProperty: false,
                      panelConfig: {
                        editableTitle: true,
                        titlePropertyName: "label",
                        panelIdPropertyName: "id",
                        updateHook: (
                          props: any,
                          propertyPath: string,
                          propertyValue: string,
                        ) => {
                          return [
                            {
                              propertyPath,
                              propertyValue,
                            },
                          ];
                        },
                        contentChildren: [
                          {
                            sectionName: "Label",
                            children: [
                              {
                                propertyName: "label",
                                helpText: "Sets the label of a menu item",
                                label: "Text",
                                controlType: "INPUT_TEXT",
                                placeholderText: "Enter label",
                                isBindProperty: true,
                                isTriggerProperty: false,
                                validation: { type: ValidationTypes.TEXT },
                              },
                            ],
                          },
                          {
                            sectionName: "General",
                            children: [
                              // {
                              //   propertyName: "href",
                              //   label: "href",
                              //   controlType: "INPUT_TEXT",
                              //   isBindProperty: true,
                              //   isTriggerProperty: false,
                              //   validation: { type: ValidationTypes.TEXT },
                              // },
                              {
                                propertyName: "isVisible",
                                helpText:
                                  "Controls the visibility of menu item",
                                label: "Visible",
                                controlType: "SWITCH",
                                isJSConvertible: true,
                                isBindProperty: true,
                                isTriggerProperty: false,
                                validation: {
                                  type: ValidationTypes.BOOLEAN,
                                },
                              },
                              {
                                propertyName: "isDisabled",
                                helpText: "Disables menu item",
                                label: "Disabled",
                                controlType: "SWITCH",
                                isJSConvertible: true,
                                isBindProperty: true,
                                isTriggerProperty: false,
                                validation: {
                                  type: ValidationTypes.BOOLEAN,
                                },
                              },
                              {
                                propertyName: "divider",
                                helpText:
                                  "Divider with next button in menu dropdown",
                                label: "Divider",
                                controlType: "SWITCH",
                                isJSConvertible: true,
                                isBindProperty: true,
                                isTriggerProperty: false,
                                validation: {
                                  type: ValidationTypes.BOOLEAN,
                                },
                              },
                            ],
                          },
                          {
                            sectionName: "Events",
                            children: [
                              {
                                helpText: "when the menu item is clicked",
                                propertyName: "onClick",
                                label: "onClick",
                                controlType: "ACTION_SELECTOR",
                                isJSConvertible: true,
                                isBindProperty: true,
                                isTriggerProperty: true,
                              },
                            ],
                          },
                        ],
                        styleChildren: [
                          {
                            sectionName: "Icon",
                            children: [
                              {
                                propertyName: "icon",
                                label: "Icon",
                                controlType: "ZICON_SELECT",
                                isJSConvertible: true,
                                isBindProperty: true,
                                isTriggerProperty: false,
                                validation: { type: ValidationTypes.TEXT },
                              },
                              {
                                propertyName: "startIcon",
                                label: "StartIcon",
                                controlType: "ZICON_SELECT",
                                isJSConvertible: true,
                                isBindProperty: true,
                                isTriggerProperty: false,
                                validation: { type: ValidationTypes.TEXT },
                              },
                              {
                                propertyName: "endIcon",
                                label: "EndIcon",
                                controlType: "ZICON_SELECT",
                                isJSConvertible: true,
                                isBindProperty: true,
                                isTriggerProperty: false,
                                validation: { type: ValidationTypes.TEXT },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      helpText: "Displays a list of unique options",
                      propertyName: "options",
                      label: "Options",
                      controlType: "OPTION_INPUT",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: {
                        type: ValidationTypes.ARRAY,
                        params: {
                          default: [],
                          children: {
                            type: ValidationTypes.OBJECT,
                            params: {
                              required: true,
                            },
                          },
                        },
                      },
                      updateHook: (
                        props: any,
                        propertyPath: string,
                        propertyValue: string,
                      ) => {
                        return [
                          {
                            propertyPath,
                            propertyValue,
                          },
                        ];
                      },
                      evaluationSubstitutionType:
                        EvaluationSubstitutionType.SMART_SUBSTITUTE,
                      hidden: (
                        props: ZCardWidgetProps,
                        propertyPath: string,
                      ) => {
                        const property = getBasePropertyPath(propertyPath);
                        const actionType = get(
                          props,
                          `${property}.actionType`,
                          "",
                        );
                        return [
                          "BUTTON",
                          "TOGGLE",
                          "CHECKBOX",
                          "MENU",
                        ].includes(actionType);
                      },
                      dependencies: ["actionType"],
                    },
                    {
                      helpText:
                        "Choose or set a field from source data as the display label",
                      propertyName: "optionLabel",
                      label: "Label key",
                      controlType: "DROP_DOWN",
                      customJSControl: "WRAPPED_CODE_EDITOR",
                      controlConfig: {
                        wrapperCode: {
                          prefix: getOptionLabelValueExpressionPrefix,
                          suffix: optionLabelValueExpressionSuffix,
                        },
                      },
                      updateHook: (
                        props: any,
                        propertyPath: string,
                        propertyValue: string,
                      ) => {
                        return [
                          {
                            propertyPath,
                            propertyValue,
                          },
                        ];
                      },
                      hidden: hiddenExceptSelect,
                      placeholderText: "",
                      isBindProperty: true,
                      isTriggerProperty: false,
                      isJSConvertible: true,
                      dependencies: ["headerActions"],
                      evaluatedDependencies: ["options"],
                      options: getLabelValueKeyOptions,
                      alwaysShowSelected: true,
                      validation: {
                        type: ValidationTypes.FUNCTION,
                        params: {
                          fn: labelKeyValidation,
                          expected: {
                            type: "String or Array<string>",
                            example: `color | ["blue", "green"]`,
                            autocompleteDataType: AutocompleteDataType.STRING,
                          },
                        },
                      },
                      additionalAutoComplete:
                        getLabelValueAdditionalAutocompleteData,
                    },
                    {
                      helpText:
                        "Choose or set a field from source data as the value",
                      propertyName: "optionValue",
                      label: "Value key",
                      controlType: "DROP_DOWN",
                      customJSControl: "WRAPPED_CODE_EDITOR",
                      controlConfig: {
                        wrapperCode: {
                          prefix: getOptionLabelValueExpressionPrefix,
                          suffix: optionLabelValueExpressionSuffix,
                        },
                      },
                      placeholderText: "",
                      isBindProperty: true,
                      isTriggerProperty: false,
                      isJSConvertible: true,
                      hidden: hiddenExceptSelect,
                      dependencies: ["headerActions"],
                      evaluatedDependencies: ["options"],
                      options: getLabelValueKeyOptions,
                      alwaysShowSelected: true,
                      validation: {
                        type: ValidationTypes.FUNCTION,
                        params: {
                          fn: valueKeyValidation,
                          expected: {
                            type: "String or Array<string | number | boolean>",
                            example: `color | [1, "orange"]`,
                            autocompleteDataType: AutocompleteDataType.STRING,
                          },
                        },
                      },
                      additionalAutoComplete:
                        getLabelValueAdditionalAutocompleteData,
                    },
                    {
                      helpText:
                        "Sets the value of the option checked by default",
                      propertyName: "defaultSelectedOption",
                      label: "Default selected value",
                      placeholderText: "apple",
                      controlType: "INPUT_TEXT",
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: {
                        type: ValidationTypes.FUNCTION,
                        params: {
                          fn: defaultSelectedValuesValidation,
                          expected: {
                            type: "String or Array<string>",
                            example: `apple | ["apple", "orange"]`,
                            autocompleteDataType: AutocompleteDataType.STRING,
                          },
                        },
                      },
                      hidden: (
                        props: ZCardWidgetProps,
                        propertyPath: string,
                      ) => {
                        const property = getBasePropertyPath(propertyPath);
                        const actionType = get(
                          props,
                          `${property}.actionType`,
                          "",
                        );
                        return [
                          "BUTTON",
                          "TOGGLE",
                          "CHECKBOX",
                          "MENU",
                        ].includes(actionType);
                      },
                      dependencies: ["actionType"],
                    },
                  ],
                },
                {
                  sectionName: "Label",
                  children: [
                    {
                      propertyName: "label",
                      helpText: "Sets the label of a menu item",
                      label: "Text",
                      controlType: "INPUT_TEXT",
                      placeholderText: "Enter label",
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                    },
                    // {
                    //   propertyName: "href",
                    //   label: "href",
                    //   controlType: "INPUT_TEXT",
                    //   isBindProperty: true,
                    //   isTriggerProperty: false,
                    //   validation: { type: ValidationTypes.TEXT },
                    //   hidden: (
                    //     props: ZCardWidgetProps,
                    //     propertyPath: string,
                    //   ) => {
                    //     const property = getBasePropertyPath(propertyPath);
                    //     const actionType = get(
                    //       props,
                    //       `${property}.actionType`,
                    //       "",
                    //     );
                    //     return [
                    //       "RADIO",
                    //       "TOGGLE",
                    //       "CHECKBOX",
                    //       "SELECT",
                    //     ].includes(actionType);
                    //   },
                    // },
                    {
                      propertyName: "icon",
                      label: "Icon",
                      controlType: "ZICON_SELECT",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                      hidden: (
                        props: ZCardWidgetProps,
                        propertyPath: string,
                      ) => {
                        const property = getBasePropertyPath(propertyPath);
                        const actionType = get(
                          props,
                          `${property}.actionType`,
                          "",
                        );
                        return [
                          "RADIO",
                          "TOGGLE",
                          "CHECKBOX",
                          "SELECT",
                        ].includes(actionType);
                      },
                    },
                    {
                      propertyName: "startIcon",
                      label: "StartIcon",
                      controlType: "ZICON_SELECT",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                      hidden: (
                        props: ZCardWidgetProps,
                        propertyPath: string,
                      ) => {
                        const property = getBasePropertyPath(propertyPath);
                        const actionType = get(
                          props,
                          `${property}.actionType`,
                          "",
                        );
                        return [
                          "RADIO",
                          "TOGGLE",
                          "CHECKBOX",
                          "SELECT",
                        ].includes(actionType);
                      },
                    },
                    {
                      propertyName: "endIcon",
                      label: "EndIcon",
                      controlType: "ZICON_SELECT",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                      hidden: (
                        props: ZCardWidgetProps,
                        propertyPath: string,
                      ) => {
                        const property = getBasePropertyPath(propertyPath);
                        const actionType = get(
                          props,
                          `${property}.actionType`,
                          "",
                        );
                        return [
                          "RADIO",
                          "TOGGLE",
                          "CHECKBOX",
                          "SELECT",
                        ].includes(actionType);
                      },
                    },
                  ],
                },
                {
                  sectionName: "General",
                  children: [
                    {
                      propertyName: "selectWidth",
                      helpText: "Sets the fix width to display select action",
                      label: "Width",
                      controlType: "INPUT_TEXT",
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.NUMBER },
                      hidden: (
                        props: ZCardWidgetProps,
                        propertyPath: string,
                      ) => {
                        const property = getBasePropertyPath(propertyPath);
                        const actionType = get(
                          props,
                          `${property}.actionType`,
                          "",
                        );
                        return [
                          "BUTTON",
                          "TOGGLE",
                          "CHECKBOX",
                          "MENU",
                          "RADIO",
                        ].includes(actionType);
                      },
                    },
                    {
                      propertyName: "isVisible",
                      helpText: "Controls the visibility of the widget",
                      label: "Visible",
                      controlType: "SWITCH",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.BOOLEAN },
                    },
                    {
                      propertyName: "isDisabled",
                      helpText: "Disables input to the widget",
                      label: "Disabled",
                      controlType: "SWITCH",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.BOOLEAN },
                    },
                    {
                      propertyName: "divider",
                      helpText: "Divider with next button in menu dropdown",
                      label: "Divider",
                      controlType: "SWITCH",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: {
                        type: ValidationTypes.BOOLEAN,
                      },
                    },
                  ],
                },
                {
                  sectionName: "Events",
                  children: [
                    {
                      propertyName: "onClick",
                      label: "onClick",
                      controlType: "ACTION_SELECTOR",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: true,
                      hidden: (
                        props: ZCardWidgetProps,
                        propertyPath: string,
                      ) => {
                        const property = getBasePropertyPath(propertyPath);
                        const actionType = get(
                          props,
                          `${property}.actionType`,
                          "",
                        );
                        return [
                          "RADIO",
                          "TOGGLE",
                          "CHECKBOX",
                          "SELECT",
                        ].includes(actionType);
                      },
                      dependencies: ["actionType"],
                    },
                    {
                      propertyName: "onChange",
                      label: "onChange",
                      controlType: "ACTION_SELECTOR",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: true,
                      hidden: (
                        props: ZCardWidgetProps,
                        propertyPath: string,
                      ) => {
                        const property = getBasePropertyPath(propertyPath);
                        const actionType = get(
                          props,
                          `${property}.actionType`,
                          "",
                        );
                        return actionType === "BUTTON" || actionType === "MENU";
                      },
                      dependencies: ["actionType"],
                    },
                  ],
                },
              ],
              styleChildren: [
                {
                  sectionName: "Variant",
                  children: [
                    {
                      propertyName: "variant",
                      hidden: (
                        props: ZCardWidgetProps,
                        propertyPath: string,
                      ) => {
                        const property = getBasePropertyPath(propertyPath);
                        const actionType = get(
                          props,
                          `${property}.actionType`,
                          "",
                        );
                        return [
                          "RADIO",
                          "TOGGLE",
                          "CHECKBOX",
                          "SELECT",
                        ].includes(actionType);
                      },
                      dependencies: ["actionType"],
                      label: "Variant",
                      controlType: "ICON_TABS",
                      defaultValue: ButtonVariantTypes.CONTAINED,
                      fullWidth: true,
                      helpText: "Sets the variant of the button",
                      options: [
                        {
                          label: "Contained",
                          value: ButtonVariantTypes.CONTAINED,
                        },
                        {
                          label: "Tertiary",
                          value: ButtonVariantTypes.TERTIARY,
                        },
                        {
                          label: "Text",
                          value: ButtonVariantTypes.TEXT,
                        },
                      ],
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: {
                        type: ValidationTypes.TEXT,
                        params: {
                          allowedValues: [
                            ButtonVariantTypes.CONTAINED,
                            // ButtonVariantTypes.OUTLINED,
                            ButtonVariantTypes.TERTIARY,
                            ButtonVariantTypes.TEXT,
                          ],
                          default: ButtonVariantTypes.CONTAINED,
                        },
                      },
                    },
                  ],
                },
                {
                  sectionName: "Color",
                  children: [
                    {
                      // getStylesheetValue,
                      propertyName: "buttonColor",
                      helpText: "Changes the color of the button",
                      label: "Button color",
                      controlType: "COLOR_PICKER",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                      hidden: (
                        props: ZCardWidgetProps,
                        propertyPath: string,
                      ) => {
                        const property = getBasePropertyPath(propertyPath);
                        const actionType = get(
                          props,
                          `${property}.actionType`,
                          "",
                        );
                        return [
                          "RADIO",
                          "TOGGLE",
                          "CHECKBOX",
                          "SELECT",
                        ].includes(actionType);
                      },
                    },
                  ],
                },
                {
                  sectionName: "Border and shadow",
                  hidden: (props: ZCardWidgetProps, propertyPath: string) => {
                    const actionType = get(
                      props,
                      `${propertyPath}.actionType`,
                      "",
                    );
                    return [
                      "RADIO",
                      "TOGGLE",
                      "CHECKBOX",
                      "BUTTON",
                      "MENU",
                    ].includes(actionType);
                  },
                  children: [
                    {
                      propertyName: "borderRadius",
                      label: "Border radius",
                      helpText:
                        "Rounds the corners of the icon button's outer border edge",
                      controlType: "BORDER_RADIUS_OPTIONS",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                    },
                    {
                      propertyName: "boxShadow",
                      label: "Box shadow",
                      helpText:
                        "Enables you to cast a drop shadow from the frame of the widget",
                      controlType: "BOX_SHADOW_OPTIONS",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                    },
                  ],
                },
                {
                  sectionName: "Label",
                  hidden: (props: ZCardWidgetProps, propertyPath: string) => {
                    const actionType = get(
                      props,
                      `${propertyPath}.actionType`,
                      "",
                    );
                    return ["BUTTON", "MENU", "RADIO"].includes(actionType);
                  },
                  children: [
                    {
                      propertyName: "labelTextSize",
                      label: "Font size",
                      helpText: "Control the font size of the label associated",
                      defaultValue: "0.875rem",
                      controlType: "DROP_DOWN",
                      options: [
                        {
                          label: "XS",
                          value: "0.688rem",
                          subText: "0.688rem",
                        },
                        {
                          label: "S",
                          value: "0.875rem",
                          subText: "0.875rem",
                        },
                        {
                          label: "M",
                          value: "1rem",
                          subText: "1rem",
                        },
                        {
                          label: "L",
                          value: "1.25rem",
                          subText: "1.25rem",
                        },
                        {
                          label: "XL",
                          value: "1.875rem",
                          subText: "1.875rem",
                        },
                        {
                          label: "XXL",
                          value: "3rem",
                          subText: "3rem",
                        },
                        {
                          label: "3XL",
                          value: "3.75rem",
                          subText: "3.75rem",
                        },
                      ],
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                    },
                    {
                      propertyName: "labelStyle",
                      label: "Emphasis",
                      helpText:
                        "Control if the label should be bold or italics",
                      controlType: "BUTTON_GROUP",
                      options: [
                        {
                          icon: "text-bold",
                          value: "BOLD",
                        },
                        {
                          icon: "text-italic",
                          value: "ITALIC",
                        },
                      ],
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                    },
                    {
                      propertyName: "labelPlacement",
                      label: "labelPlacement",
                      controlType: "ICON_TABS",
                      options: [
                        { label: "Bottom", value: "bottom" },
                        { label: "Top", value: "top" },
                        { label: "End", value: "end" },
                        { label: "Start", value: "start" },
                      ],
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                      hidden: (
                        props: ZCardWidgetProps,
                        propertyPath: string,
                      ) => {
                        const property = getBasePropertyPath(propertyPath);
                        const actionType = get(
                          props,
                          `${property}.actionType`,
                          "",
                        );
                        return ["SELECT", "CHECKBOX", "RADIO"].includes(
                          actionType,
                        );
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            propertyName: "maxHeaderActionDisplay",
            label: "Max Header Action Display",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          {
            propertyName: "titleBar",
            label: "TitleBar",
            controlType: "SWITCH",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "showEditButton",
            label: "Show Edit Button",
            controlType: "SWITCH",
            defaultValue: "{{appsmith.zCustomVars.Edit || false}}",
            isBindProperty: true,
            isJSConvertible: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "isVisible",
            helpText: "Controls the visibility of the widget",
            label: "Visible",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      // borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      // boxShadow: "none",
      childStylesheet: {
        button: {
          buttonColor: "{{appsmith.theme.colors.primaryColor}}",
        },
      },
    };
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return (widget: ZCardWidgetProps) => ({
      headerActionList: generateTypeDef(widget.headerActions),
    });
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      headerActionList: `{{(()=>{${derivedProperties.transformHeaderActions}})()}}`,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  renderChildWidget = () => {
    const childData = { ...this.props.children?.filter(Boolean)[0] };
    childData.parentId = this.props.widgetId;

    childData.canExtend = this.props.shouldScrollContents;
    const { componentHeight, componentWidth } = this.props;

    childData.containerStyle = "none";
    childData.minHeight = componentHeight;
    childData.rightColumn = componentWidth;
    childData.bottomRow = this.props.shouldScrollContents
      ? childData.bottomRow
      : componentHeight - 1;

    childData.positioning = this.props.positioning;
    childData.alignment = this.props.alignment;
    childData.spacing = this.props.spacing;
    const positioning: Positioning =
      this.props.layoutSystemType == LayoutSystemTypes.AUTO
        ? Positioning.Vertical
        : Positioning.Fixed;
    childData.positioning = positioning;
    childData.useAutoLayout = positioning !== Positioning.Fixed;
    childData.direction =
      positioning === Positioning.Vertical
        ? LayoutDirection.Vertical
        : LayoutDirection.Horizontal;
    return renderAppsmithCanvas(childData as WidgetProps);
  };

  handleClick = (
    onClick: string | undefined,
    action?: HeaderActionItem,
    index?: number,
  ): void => {
    if (onClick) {
      const config: ExecuteTriggerPayload = {
        triggerPropertyName: "onClick",
        dynamicString: onClick,
        event: {
          type: EventType.ON_CLICK,
        },
      };

      if (
        action &&
        action.actionType === "MENU" &&
        action.menuItemsSource === MenuItemsSource.DYNAMIC
      ) {
        config.globalContext = {
          currentItem:
            action.sourceData && index !== undefined
              ? action.sourceData[index]
              : {},
          currentIndex: index,
        };
      }

      super.executeAction(config);
    }
  };

  handleOnchange = (
    onChange: string | undefined,
    propertyPath?: string,
    value?: any,
  ): void => {
    if (propertyPath && value != undefined) {
      this.updateWidgetProperty(propertyPath, value);
      // this.forceUpdate();
    }
    if (onChange) {
      super.executeAction({
        triggerPropertyName: "onChange",
        dynamicString: onChange,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          selectedValue: value,
        },
      });
    }
  };

  getVisibleActions = () => {
    const actions = this.props.headerActions;
    if (!actions) return {};

    let items = Object.keys(actions)
      .map((itemKey) => actions[itemKey])
      .filter((item) => item.isVisible === true);
    // sort btns by index
    items = sortBy(items, ["index"]);
    return items;
  };

  getWidgetView() {
    const isAutoHeightEnabled: boolean =
      isAutoHeightEnabledForWidget(this.props) &&
      !isAutoHeightEnabledForWidgetWithLimits(this.props);

    return (
      <ContainerWrapper>
        <Container>
          <Card>
            <HeaderWrapper>
              <CardHeader
                action={
                  this.props.headerActions && (
                    <PageHeaderActions
                      actions={this.getVisibleActions()}
                      buttonClickHandler={this.handleClick}
                      handleOnchange={this.handleOnchange}
                      maxHeaderActionDisplay={this.props.maxHeaderActionDisplay}
                    />
                  )
                }
                subheader={
                  <Typography variant="subtitle1">
                    {this.props.subtitle}
                  </Typography>
                }
                sx={{ flexGrow: 1 }}
                title={
                  <Typography titleBar={this.props.titleBar} variant="h5">
                    {this.props.title}
                  </Typography>
                }
              />
              {this.props.showEditButton && (
                <div style={{ marginLeft: "auto" }}>
                  <ZEditButtonWidget
                    {...this.props} // Pass all props to ensure required properties are included
                    autoFocus={this.props.autoFocus}
                    dsOnClick={this.props.dsOnClick}
                    parentWidgetId={this.props.widgetId}
                    variant={this.props.variant}
                  />
                </div>
              )}
            </HeaderWrapper>

            {/*<CardContent>*/}
            <ScrollCanvas
              $shouldScrollContents={
                !!this.props.shouldScrollContents && !isAutoHeightEnabled
              }
              className={`${
                this.props.shouldScrollContents &&
                this.props.layoutSystemType === LayoutSystemTypes.FIXED
                  ? getCanvasClassName()
                  : ""
              } ${generateClassName(this.props.widgetId)}`}
            >
              {this.renderChildWidget()}
            </ScrollCanvas>
            {/*</CardContent>*/}
          </Card>
        </Container>
      </ContainerWrapper>
    );
  }
}

export default ZCardWidget;
