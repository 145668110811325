import React, { useMemo, useState } from "react";
import type {
  BaseFieldComponentProps,
  FieldComponentBaseProps,
} from "../constants";
import FieldLabel, { BASE_LABEL_TEXT_SIZE } from "../component/FieldLabel";
import LinesEllipsis from "react-lines-ellipsis";
import { Popover, Typography } from "zds";
import { FontStyleTypes } from "../../../constants/WidgetConstants";
import { dateFormatOptions } from "../../../WidgetProvider/constants";
import moment from "moment";
import DOMPurify from "dompurify";

type DateFieldComponentProps = FieldComponentBaseProps & {
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  borderRadius?: string;
  boxShadow?: string;
  cellBackgroundColor?: string;
  cellBorderColor?: string;
  cellBorderWidth?: number;
  cellBorderRadius?: string;
  cellBoxShadow?: string;
  accentColor?: string;
  defaultFormat?: string;
  dateFormat?: string;
  isCollapsible: boolean;
  detailName?: string;
  detailTitle?: string;
  detailContent?: string;
};

export type DateFieldProps = BaseFieldComponentProps<DateFieldComponentProps>;

const COMPONENT_DEFAULT_VALUES = {
  isDisabled: true,
  isRequired: false,
  isSpellCheck: false,
  isVisible: true,
  labelTextSize: BASE_LABEL_TEXT_SIZE,
  label: "",
  isChecked: true,
};

export const isValidType = (dateValue: string) =>
  dateFormatOptions.some(({ value: format }) =>
    moment(dateValue, format, true).isValid(),
  );

const PopoverName = ({
  detailName,
  isHovered,
  onHover,
}: {
  detailName: string;
  isHovered: boolean;
  onHover: (isEntering: boolean, event?: React.MouseEvent<HTMLElement>) => void;
}) => (
  <span
    onMouseEnter={(event) => onHover.bind(event, true)}
    onMouseLeave={() => onHover(false)}
    style={{
      color: "blue",
      cursor: "pointer",
      textDecoration: isHovered ? "underline" : "none",
      whiteSpace: "nowrap",
      marginLeft: "5px",
      flexShrink: 0,
    }}
  >
    [{detailName}]
  </span>
);

const PopoverContent = ({
  anchorEl,
  detailContent,
  detailTitle,
  onHover,
  open,
}: {
  anchorEl: HTMLElement | null;
  open: boolean;
  onHover: (isEntering: boolean, event?: React.MouseEvent<HTMLElement>) => void;
  detailTitle?: string | undefined;
  detailContent?: string | undefined;
}) => {
  const sanitizeHtml = (htmlContent: string | undefined): string => {
    return DOMPurify.sanitize(htmlContent || "");
  };

  return (
    <Popover
      a11yId="mouse-over-popover"
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      arrow
      body={
        <div>
          <Typography variant="body1">{detailTitle}</Typography>
          <div style={{ marginBottom: "16px" }} />
          <Typography variant="body2">
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(detailContent),
              }}
            />
          </Typography>
        </div>
      }
      disableRestoreFocus
      dsOnClose={() => onHover(false)}
      open={open}
      paperProps={{
        onMouseEnter: (event) => onHover(true, event),
        onMouseLeave: () => onHover(false),
      }}
      pointerEventsNone
      transformOrigin={{
        horizontal: "left",
        vertical: "top",
      }}
    />
  );
};

function DateField({
  inlineLabelParent,
  labelAlignmentParent,
  labelStyleParent,
  labelTextColorParent,
  labelTextSizeParent,
  labelWidthParent,
  schemaItem,
}: DateFieldProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onHover = (
    isHovered: boolean,
    event?: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEl(isHovered ? event?.currentTarget || null : null);
    setIsHovered(isHovered);
  };

  const open = !!anchorEl;
  const { detailContent, detailName, detailTitle } = schemaItem;
  const validPopoverName = detailName && detailName.trim().length > 0;
  const showPopover =
    (detailTitle && detailTitle.trim().length > 0) ||
    (detailContent && detailContent.trim().length > 0);

  const formattedDate = useMemo(() => {
    const { dateFormat, defaultFormat, defaultValue } = schemaItem;

    if (defaultValue) {
      if (!defaultFormat || !dateFormat) {
        // Show defaultValue directly if either format is missing
        return defaultValue as string;
      }
      // Convert from defaultFormat to dateFormat
      const valueInSelectedFormat = moment(defaultValue, defaultFormat, true);
      return valueInSelectedFormat.isValid()
        ? valueInSelectedFormat.format(dateFormat)
        : (defaultValue as string); // Fallback if parsing fails
    }

    return "--";
  }, [
    schemaItem.defaultValue,
    schemaItem.defaultFormat,
    schemaItem.dateFormat,
  ]);

  const {
    inlineLabel,
    label,
    labelAlignment,
    labelStyle,
    labelTextColor,
    labelTextSize,
    labelWidth,
    tooltip,
    useDefaultStyles,
  } = schemaItem;

  const ellipsisContainerStyle = {
    flex: "0 1 auto",
    minWidth: 0,
    overflow: "hidden",
    wordWrap: "break-word" as const,
    width: "100%",
    display: "flex",
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: (useDefaultStyles ? inlineLabelParent : inlineLabel)
            ? "row"
            : "column",
        }}
      >
        <FieldLabel
          alignment={useDefaultStyles ? labelAlignmentParent : labelAlignment}
          direction={
            (useDefaultStyles ? inlineLabelParent : inlineLabel)
              ? "row"
              : "column"
          }
          label={label}
          labelStyle={useDefaultStyles ? labelStyleParent : labelStyle}
          labelTextColor={
            useDefaultStyles ? labelTextColorParent : labelTextColor
          }
          labelTextSize={useDefaultStyles ? labelTextSizeParent : labelTextSize}
          tooltip={tooltip}
          width={useDefaultStyles ? labelWidthParent : labelWidth}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flex: 1,
            overflowX: "hidden",
          }}
        >
          <div
            onMouseEnter={
              !validPopoverName ? onHover.bind(null, true) : undefined
            }
            onMouseLeave={
              !validPopoverName ? onHover.bind(null, false) : undefined
            }
            style={ellipsisContainerStyle}
          >
            <LinesEllipsis
              basedOn="words"
              maxLine={schemaItem.linesDisplayed || 1}
              style={{
                flexGrow: 1,
                width: "100%",
                display: "-webkit-box",
                WebkitLineClamp: schemaItem.linesDisplayed || 1,
                WebkitBoxOrient: "vertical",
                whiteSpace: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: schemaItem.valueTextColor,
                fontSize: schemaItem.valueTextSize || BASE_LABEL_TEXT_SIZE,
                fontWeight: schemaItem.valueStyle?.includes(FontStyleTypes.BOLD)
                  ? "600"
                  : "normal",
                fontStyle: schemaItem.valueStyle?.includes(
                  FontStyleTypes.ITALIC,
                )
                  ? "italic"
                  : "",
              }}
              text={formattedDate}
              trimRight
            />
          </div>
          {validPopoverName && (
            <PopoverName
              detailName={schemaItem.detailName!}
              isHovered={isHovered}
              onHover={onHover}
            />
          )}
          {showPopover && (
            <PopoverContent
              anchorEl={anchorEl}
              detailContent={schemaItem.detailContent}
              detailTitle={schemaItem.detailTitle}
              onHover={onHover}
              open={open}
            />
          )}
        </div>
      </div>
    </div>
  );
}

DateField.componentDefaultValues = COMPONENT_DEFAULT_VALUES;
DateField.isValidType = isValidType;

export default DateField;
