import { get } from "lodash";

import type { PanelConfig } from "constants/PropertyControlConstants";
import type { SchemaItem } from "widgets/ZJsonSummaryWidget/constants";
import { FieldType } from "widgets/ZJsonSummaryWidget/constants";
import type { HiddenFnParams } from "./helper";
import { getSchemaItem, isFieldTypeArrayOrObject } from "./helper";
import {
  CHECKBOX_PROPERTIES,
  DATE_PROPERTIES,
  TEXT_PROPERTIES,
  COMMON_PROPERTIES,
  OBJECT_PROPERTIES,
  SELECT_PROPERTIES,
  CHIP_PROPERTIES,
} from "./properties";
import type { ZJSONSummaryWidgetProps } from "../index";

function generatePanelPropertyConfig(
  nestingLevel: number,
): PanelConfig | undefined {
  if (nestingLevel === 0) return;

  return {
    editableTitle: true,
    titlePropertyName: "label",
    panelIdPropertyName: "identifier",
    contentChildren: [
      {
        sectionName: "Data",
        children: [
          ...COMMON_PROPERTIES.content.data,
          ...COMMON_PROPERTIES.content.dataToggles,
          ...CHECKBOX_PROPERTIES.content.data,
          ...DATE_PROPERTIES.content.data,
          ...SELECT_PROPERTIES.content.data,
          ...CHIP_PROPERTIES.content.data,
          {
            propertyName: "children",
            label: "Field configuration",
            helpText: "Field configuration",
            controlType: "ZJSON_SUMMARY_FIELD_CONFIGURATION",
            isBindProperty: false,
            isTriggerProperty: false,
            panelConfig: generatePanelPropertyConfig(nestingLevel - 1),
            hidden: (...args: HiddenFnParams) => {
              return getSchemaItem(...args).compute((schemaItem) => {
                return (
                  schemaItem.fieldType !== FieldType.OBJECT &&
                  schemaItem.fieldType !== FieldType.ZUORA_OBJECT
                );
              });
            },
            dependencies: ["schema", "childStylesheet"],
          },
        ],
      },
      {
        sectionName: "Label",
        children: [
          ...COMMON_PROPERTIES.content.label,
          ...CHECKBOX_PROPERTIES.content.label,
        ],
      },
      {
        sectionName: "Popover",
        children: [...TEXT_PROPERTIES.content.detail],
      },
      {
        sectionName: "Validation",
        children: [],
        hidden: isFieldTypeArrayOrObject,
      },
      {
        sectionName: "General",
        children: [
          ...COMMON_PROPERTIES.content.general,
          ...DATE_PROPERTIES.content.general,
          ...SELECT_PROPERTIES.content.general,
          ...COMMON_PROPERTIES.content.generalSwitch,
        ],
      },
      {
        sectionName: "Events",
        children: [
          ...CHECKBOX_PROPERTIES.content.events,
          ...COMMON_PROPERTIES.content.events,
          ...SELECT_PROPERTIES.content.events,
          ...TEXT_PROPERTIES.content.events,
          ...OBJECT_PROPERTIES.content.events,
        ],
        // hidden: isFieldTypeArrayOrObject,
      },
    ],
    styleChildren: [
      {
        sectionName: "Label styles",
        children: [...COMMON_PROPERTIES.style.label],
      },
      {
        sectionName: "Value styles",
        children: [...COMMON_PROPERTIES.style.value],
        hidden: (props: ZJSONSummaryWidgetProps, propertyPath: string) => {
          const schemaItem: SchemaItem = get(props, propertyPath, {});
          return (
            schemaItem.fieldType === FieldType.CHECKBOX ||
            schemaItem.fieldType === FieldType.SELECT
          );
        },
      },
      // {
      //   sectionName: "Icon",
      //   children: [...INPUT_PROPERTIES.style.icon],
      //   hidden: (props: ZJSONSummaryWidgetProps, propertyPath: string) => {
      //     const schemaItem: SchemaItem = get(props, propertyPath, {});
      //     return !(schemaItem.fieldType === FieldType.TEXT_INPUT);
      //   },
      // },
      {
        sectionName: "Color",
        children: [...COMMON_PROPERTIES.style.color],
        hidden: isFieldTypeArrayOrObject,
      },
      {
        sectionName: "Border and shadow",
        children: [...COMMON_PROPERTIES.style.borderShadow],
        hidden: (props: ZJSONSummaryWidgetProps, propertyPath: string) => {
          const schemaItem: SchemaItem = get(props, propertyPath, {});
          return schemaItem.fieldType === FieldType.OBJECT;
        },
      },
      ...OBJECT_PROPERTIES.style.root,
    ],
  } as PanelConfig;
}

export default generatePanelPropertyConfig;
