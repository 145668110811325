import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import {
  type ValidationResponse,
  ValidationTypes,
} from "constants/WidgetValidation";
import {
  GridDefaults,
  WIDGET_TAGS,
  WidgetHeightLimits,
} from "constants/WidgetConstants";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../zuora.svg";
import ZTabsComponent from "../component";
import type { ZTabContainerWidgetProps, ZTabsWidgetProps } from "../constants";
import {
  LayoutDirection,
  Positioning,
} from "layoutSystems/common/utils/constants";
import { LayoutSystemTypes } from "layoutSystems/types";
import { renderAppsmithCanvas } from "layoutSystems/CanvasFactory";
import {
  DefaultAutocompleteDefinitions,
  isAutoHeightEnabledForWidget,
  isAutoHeightEnabledForWidgetWithLimits,
} from "widgets/WidgetUtils";
import type {
  AnvilConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";
import { BlueprintOperationTypes } from "WidgetProvider/constants";
import type { WidgetProperties } from "selectors/propertyPaneSelectors";
import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";
import type { SetterConfig } from "entities/AppTheming";
import { find } from "lodash";
import { EventType } from "../../../constants/AppsmithActionConstants/ActionConstants";

export function selectedTabValidation(
  value: unknown,
  props: ZTabContainerWidgetProps,
): ValidationResponse {
  const tabs: Array<{
    label: string;
    id: string;
  }> = props.tabsObj ? Object.values(props.tabsObj) : props.tabs || [];

  const tabNames = tabs.map((i: { label: string; id: string }) => i.label);

  const duplicateLabels = tabNames.filter(
    (label, index) => tabNames.indexOf(label) !== index,
  );

  const isDuplicate = duplicateLabels.length > 0;

  return {
    isValid:
      !isDuplicate &&
      (value === "" ? true : tabNames.includes(value as string)),
    parsed: value,
    messages: isDuplicate
      ? [
          {
            name: "ValidationError",
            message: `Duplicate tab labels found: ${[...new Set(duplicateLabels)].join(", ")}`,
          },
        ]
      : [
          {
            name: "ValidationError",
            message: `Tab name ${value} does not exist`,
          },
        ],
  };
}

class ZTabsWidget extends BaseWidget<
  ZTabsWidgetProps<ZTabContainerWidgetProps>,
  WidgetState
> {
  static type = "ZTABS_WIDGET";

  static getConfig() {
    return {
      name: "ZTabs",
      tags: [WIDGET_TAGS.ZUORA],
      iconSVG: IconSVG,
      needsMeta: true,
      isCanvas: true,
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 1, // Index of the property pane "General" section
        active: true,
      },
    };
  }

  static getDefaults() {
    return {
      widgetName: "ZTabs",
      rows: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS + 5,
      columns: 24,
      shouldScrollContents: false,
      version: 1,
      minDynamicHeight: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS + 5,
      tabsObj: {
        tab1: {
          label: "Tab 1",
          id: "tab1",
          widgetId: "",
          isVisible: true,
          index: 0,
          positioning: Positioning.Vertical,
        },
        tab2: {
          label: "Tab 2",
          id: "tab2",
          widgetId: "",
          isVisible: true,
          index: 1,
          positioning: Positioning.Vertical,
        },
      },
      activeTab: "Tab 1",
      defaultTab: "Tab 1",
      blueprint: {
        view: [
          {
            type: "CANVAS_WIDGET",
            position: { left: 0, top: 0 },
            props: {
              detachFromLayout: true,
              canExtend: true,
              isVisible: true,
              isDisabled: false,
              shouldScrollContents: false,
              tabId: "tab1",
              tabName: "Tab 1",
              children: [],
              version: 1,
              bottomRow: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS,
            },
          },
          {
            type: "CANVAS_WIDGET",
            position: { left: 0, top: 0 },
            props: {
              detachFromLayout: true,
              canExtend: true,
              isVisible: true,
              isDisabled: false,
              shouldScrollContents: false,
              tabId: "tab2",
              tabName: "Tab 2",
              children: [],
              version: 1,
              bottomRow: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS,
            },
          },
        ],
        operations: [
          {
            type: BlueprintOperationTypes.MODIFY_PROPS,
            fn: (widget: WidgetProps & { children?: WidgetProps[] }) => {
              const tabs = Object.values({ ...widget.tabsObj });
              const tabIds: Record<string, string> = (
                widget.children || []
              ).reduce((idsObj, eachChild) => {
                idsObj = { ...idsObj, [eachChild.tabId]: eachChild.widgetId };
                return idsObj;
              }, {});
              const tabsObj = tabs.reduce((obj: any, tab: any) => {
                const newTab = { ...tab };
                newTab.widgetId = tabIds[newTab.id];
                obj[newTab.id] = newTab;
                return obj;
              }, {});
              const updatePropertyMap = [
                {
                  widgetId: widget.widgetId,
                  propertyName: "tabsObj",
                  propertyValue: tabsObj,
                },
              ];
              return updatePropertyMap;
            },
          },
        ],
      },
    };
  }

  static getMethods() {
    return {
      getCanvasHeightOffset: (props: WidgetProps): number => {
        const offset =
          props.borderWidth && props.borderWidth > 1
            ? Math.ceil(
                (2 * parseInt(props.borderWidth, 10) || 0) /
                  GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
              )
            : 0;
        return offset + 4;
      },
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "280px",
              minHeight: "300px",
            };
          },
        },
      ],
      disableResizeHandles: {
        vertical: true,
      },
    };
  }

  static getAnvilConfig(): AnvilConfig | null {
    return {
      isLargeWidget: false,
      widgetSize: {
        maxHeight: {},
        maxWidth: {},
        minHeight: { base: "300px" },
        minWidth: { base: "280px" },
      },
    };
  }

  static getDependencyMap(): Record<string, string[]> {
    return {
      defaultTab: ["tabsObj", "tabs"],
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "Data",
        children: [
          {
            propertyName: "tabsObj",
            isJSConvertible: false,
            label: "Tabs",
            helpText: "Tabs",
            controlType: "TABS_INPUT",
            isBindProperty: false,
            isTriggerProperty: false,
            updateRelatedWidgetProperties: (
              propertyPath: string,
              propertyValue: string,
              props: WidgetProperties,
            ) => {
              const propertyPathSplit = propertyPath.split(".");
              const property = propertyPathSplit.pop();
              if (property === "label") {
                const itemId = propertyPathSplit.pop() || "";
                const item = props.tabsObj[itemId];
                if (item) {
                  return [
                    {
                      widgetId: item.widgetId,
                      updates: {
                        modify: {
                          tabName: propertyValue,
                        },
                      },
                    },
                  ];
                }
              }
              return [];
            },
            panelConfig: {
              editableTitle: true,
              titlePropertyName: "label",
              panelIdPropertyName: "id",
              updateHook: (
                props: any,
                propertyPath: string,
                propertyValue: string,
              ) => {
                return [
                  {
                    propertyPath,
                    propertyValue,
                  },
                ];
              },
              children: [
                {
                  sectionName: "General",
                  children: [
                    {
                      propertyName: "isVisible",
                      label: "Visible",
                      helpText: "Controls the visibility of the tab",
                      controlType: "SWITCH",
                      useValidationMessage: true,
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.BOOLEAN },
                    },
                  ],
                },
              ],
            },
          },
          {
            propertyName: "defaultTab",
            helpText: "Selects a tab name specified by default",
            placeholderText: "Tab 1",
            label: "Default tab",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.FUNCTION,
              params: {
                fn: selectedTabValidation,
                expected: {
                  type: "Tab Name (string)",
                  example: "Tab 1",
                  autocompleteDataType: AutocompleteDataType.STRING,
                },
              },
            },
            dependencies: ["tabsObj", "tabs"],
          },
          {
            propertyName: "orientation",
            label: "Orientation",
            controlType: "DROP_DOWN",
            fullWidth: true,
            options: [
              {
                label: "Horizontal",
                value: "horizontal",
              },
              {
                label: "Vertical",
                value: "vertical",
              },
              // {
              //   label: "Checkbox",
              //   value: "CHECKBOX",
              // },
            ],
            defaultValue: "horizontal",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                allowedValues: ["vertical", "horizontal"],
              },
            },
          },
        ],
      },
      {
        sectionName: "General",
        children: [
          {
            propertyName: "isVisible",
            label: "Visible",
            helpText: "Controls the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText: "Enables scrolling for content inside the widget",
            propertyName: "shouldScrollContents",
            label: "Scroll contents",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "animateLoading",
            label: "Animate loading",
            controlType: "SWITCH",
            helpText: "Controls the loading of the widget",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "Events",
        children: [
          {
            helpText: "when the button is clicked",
            propertyName: "onTabSelected",
            label: "onTabSelected",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
        setActiveTab: {
          path: "activeTab",
          type: "string",
        },
      },
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  callDynamicHeightUpdates = () => {
    const { checkContainersForAutoHeight } = this.context;
    checkContainersForAutoHeight && checkContainersForAutoHeight();
  };

  callPositionUpdates = (tabWidgetId: string) => {
    const { updatePositionsOnTabChange } = this.context;
    updatePositionsOnTabChange &&
      updatePositionsOnTabChange(this.props.widgetId, tabWidgetId);
  };

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      selectedTab: "string",
      activeTab: "string",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedTabWidgetId: undefined,
    };
  }

  tabWidth: number;

  constructor(props: ZTabsWidgetProps<ZTabContainerWidgetProps>) {
    super(props);
    this.tabWidth = 0;
  }

  renderComponent = () => {
    const selectedTabWidgetId = this.getSelectedTabWidgetId();
    const childWidgetData = {
      ...this.props.children?.filter(Boolean).filter((item) => {
        return selectedTabWidgetId === item.widgetId;
      })[0],
    };
    if (!childWidgetData) {
      return null;
    }

    childWidgetData.canExtend = this.props.shouldScrollContents;
    const { componentHeight, componentWidth } = this.props;
    childWidgetData.containerStyle = "none";
    const isVertical = this.props.orientation === "vertical";
    childWidgetData.rightColumn =
      componentWidth - (isVertical ? this.tabWidth : 0);
    childWidgetData.isVisible = this.props.isVisible;
    childWidgetData.bottomRow = this.props.shouldScrollContents
      ? childWidgetData.bottomRow
      : componentHeight - 1;
    childWidgetData.parentId = this.props.widgetId;
    childWidgetData.minHeight = componentHeight;
    const selectedTabProps = Object.values(this.props.tabsObj)?.filter(
      (item) => item.widgetId === selectedTabWidgetId,
    )[0];
    const positioning: Positioning =
      this.props.layoutSystemType == LayoutSystemTypes.AUTO
        ? Positioning.Vertical
        : Positioning.Fixed;
    childWidgetData.positioning = positioning;
    childWidgetData.useAutoLayout = positioning !== Positioning.Fixed;
    childWidgetData.direction =
      positioning === Positioning.Vertical
        ? LayoutDirection.Vertical
        : LayoutDirection.Horizontal;
    childWidgetData.alignment = selectedTabProps?.alignment;
    childWidgetData.spacing = selectedTabProps?.spacing;
    return renderAppsmithCanvas(childWidgetData as WidgetProps);
  };

  private getSelectedTabWidgetId() {
    let selectedTabWidgetId = this.props.selectedTabWidgetId;
    if (this.props.children) {
      selectedTabWidgetId =
        this.props.children.find((tab) =>
          this.props.selectedWidgetAncestry?.includes(tab.widgetId),
        )?.widgetId ?? this.props.selectedTabWidgetId;
    }
    return selectedTabWidgetId;
  }

  onTabChange = (activeTabLabel: string) => {
    this.updateWidgetProperty("activeTab", activeTabLabel);
  };

  handleTabContainerWidthChange = (width: number) => {
    this.tabWidth = width;
  };

  getWidgetView() {
    const ZTabsWidgetProps = {
      ...this.props,
      activeTab: this.props.activeTab,
      tabs: this.getVisibleTabs(),
      onTabChange: this.onTabChange,
      onTabContainerWidthChange: this.handleTabContainerWidthChange,
    };
    const isAutoHeightEnabled: boolean =
      isAutoHeightEnabledForWidget(this.props) &&
      !isAutoHeightEnabledForWidgetWithLimits(this.props);

    return (
      <ZTabsComponent
        {...ZTabsWidgetProps}
        $noScroll={isAutoHeightEnabled}
        shouldScrollContents={
          this.props.shouldScrollContents &&
          this.props.layoutSystemType === LayoutSystemTypes.FIXED
        }
      >
        {this.renderComponent()}
      </ZTabsComponent>
    );
  }

  async componentDidUpdate(
    prevProps: ZTabsWidgetProps<ZTabContainerWidgetProps>,
  ) {
    if (this.props.activeTab !== prevProps.activeTab) {
      this.updateWidgetProperty("activeTab", this.props.activeTab);
      const visibleTabs = this.getVisibleTabs();
      const activeTab = find(visibleTabs, {
        label: this.props.activeTab,
      });
      if (activeTab) {
        this.props.updateWidgetMetaProperty(
          "selectedTabWidgetId",
          activeTab.widgetId,
        );
        this.props.updateWidgetMetaProperty(
          "selectedTabWidgetId",
          activeTab.widgetId,
          {
            triggerPropertyName: "onTabSelected",
            dynamicString: this.props.onTabSelected,
            event: {
              type: EventType.ON_TAB_CHANGE,
            },
          },
        );
        setTimeout(this.callDynamicHeightUpdates, 0);
        setTimeout(() => this.callPositionUpdates(activeTab.widgetId), 0);
      }
    }
  }

  getVisibleTabs = () => {
    const tabs = Object.values(this.props.tabsObj || {});
    if (tabs.length) {
      return tabs
        .filter(
          (tab) => tab.isVisible === undefined || !!tab.isVisible === true,
        )
        .sort((tab1, tab2) => tab1.index - tab2.index);
    }
    return [];
  };

  setDefaultSelectedTabWidgetId = () => {
    const visibleTabs = this.getVisibleTabs();
    // Find the default Tab object
    const defaultTab = find(visibleTabs, {
      label: this.props.defaultTab,
    });
    // Find the default Tab id
    const defaultTabWidgetId =
      defaultTab?.widgetId ?? visibleTabs?.[0]?.widgetId; // in case the default tab is deleted

    const defaultTabLabel = defaultTab?.label ?? visibleTabs?.[0]?.label;
    // If we have a legitimate default tab Id and it is not already the selected Tab
    if (
      defaultTabWidgetId &&
      defaultTabWidgetId !== this.props.selectedTabWidgetId
    ) {
      // Select the default tab
      this.props.updateWidgetMetaProperty(
        "selectedTabWidgetId",
        defaultTabWidgetId,
      );
      //change the active tab to defaultTab
      this.updateWidgetProperty("activeTab", defaultTabLabel);
      setTimeout(this.callDynamicHeightUpdates, 0);
    }
  };

  componentDidMount() {
    Object.keys(this.props.tabsObj || {}).length &&
      this.setDefaultSelectedTabWidgetId();
  }
}
export default ZTabsWidget;
