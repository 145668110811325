import React, { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import type { CustomerAccounts } from "../types/accounts";
import type { Products } from "../types/products";
import { TypeAhead } from "zds";
import { Checkbox as MuiCheckBox, Box } from "@mui/material";
import { calculateDatesForPeriod } from "widgets/ZAnalytics/util/DateUtil";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type AnalyseOptionType =
  | { accountId: string; accountName: string }
  | { productId: string; productName: string };

interface AnalyseTypeAhead {
  analyzeBy: "analyzeByProduct" | "analyzeByCustomer" | string;
  selectedObjects: CustomerAccounts | Products;
  setSelectedObjects: React.Dispatch<
    React.SetStateAction<CustomerAccounts | Products>
  >;
  customerOrProductData: any;
  getAccountsOrProducts: any;
  selectedDuration: any;
}

const AnalyseTypeAhead = (props: AnalyseTypeAhead) => {
  const {
    analyzeBy,
    customerOrProductData,
    getAccountsOrProducts,
    selectedDuration,
    selectedObjects,
    setSelectedObjects,
  } = props;
  const [query, setQuery] = useState("");

  const invalid = false;
  const getKey = () => {
    if (analyzeBy === "analyzeByCustomer") {
      return { id: "accountId", name: "accountName" };
    }
    return { id: "productId", name: "productName" };
  };
  useEffect(() => {
    if (selectedDuration) {
      const { endDate, startDate } = calculateDatesForPeriod(
        selectedDuration,
        false,
      );
      if (getAccountsOrProducts)
        getAccountsOrProducts(
          startDate,
          endDate,
          analyzeBy,
          null,
          null,
          true,
          query,
        );
    }
  }, [analyzeBy, selectedDuration]);

  useEffect(() => {
    if (query && query.length) {
      if (getAccountsOrProducts) {
        getAccountsOrProducts(null, null, analyzeBy, null, null, true, query);
      }
    }
  }, [query]);

  const isChecked = (val: AnalyseOptionType[], id: string) => {
    if (!val) {
      return false;
    }
    return (
      val &&
      val?.some((value) => {
        if ("accountId" in value) {
          return id === value.accountId;
        }
        if ("productId" in value) {
          return id === value.productId;
        }
        return false;
      })
    );
  };
  return (
    <Box
      sx={{
        width: "350px",
        "& .MuiOutlinedInput-root.typeAhead-multiple": {
          padding: "1px",
        },
      }}
    >
      <TypeAhead
        dsOnChange={(_, val) => {
          if (val.length > 5) {
            alert({
              message: `You can select maximum 5 ${
                analyzeBy === "analyzeByCustomer" ? "accounts" : "products"
              }`,
              severity: "error",
              duration: 3000,
            });
          } else {
            setSelectedObjects([...val]);
          }
        }}
        dsOnInputChange={(_, val) => setQuery(val)}
        dsRenderOption={(option) => {
          return (
            <div>
              <MuiCheckBox
                checked={
                  isChecked(
                    selectedObjects,
                    option.accountId || option.productId,
                  ) || false
                }
                checkedIcon={checkedIcon}
                icon={icon}
                style={{ marginRight: 8 }}
              />
              {analyzeBy === "analyzeByCustomer"
                ? option.accountName
                : option.productName}
            </div>
          );
        }}
        e2e="customerSearchTypeAhead"
        error={invalid}
        getOptionLabel={(option: AnalyseOptionType) => {
          const key = getKey().name;
          return key in option
            ? `${option[key as keyof AnalyseOptionType]}`
            : "";
        }}
        helperText={invalid ? "required" : ""}
        inputChangeDebounce={300}
        inputValue={query}
        multiple
        options={customerOrProductData || []}
        placeholder={`${analyzeBy === "analyzeByCustomer" ? "Search for accounts" : "Search for products"}  `}
        value={selectedObjects}
      />
    </Box>
  );
};

export default AnalyseTypeAhead;
