import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import {
  FlexVerticalAlignment,
  LayoutDirection,
  Positioning,
} from "layoutSystems/common/utils/constants";
import { WIDGET_PADDING } from "constants/WidgetConstants";
import { ValidationTypes } from "constants/WidgetValidation";
import { find, get } from "lodash";
import React from "react";
import { LayoutSystemTypes } from "layoutSystems/types";
import type { WidgetProperties } from "selectors/propertyPaneSelectors";
import type { WidgetState } from "../../BaseWidget";
import BaseWidget from "../../BaseWidget";
import StepperComponent from "../component";
import type {
  StepperContainerWidgetProps,
  StepperDataProps,
  StepperWidgetProps,
} from "../constants";
import derivedProperties from "./parseDerivedProperties";
import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import {
  isAutoHeightEnabledForWidget,
  isAutoHeightEnabledForWidgetWithLimits,
  DefaultAutocompleteDefinitions,
} from "widgets/WidgetUtils";
import type {
  AnvilConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";
import { ResponsiveBehavior } from "layoutSystems/common/utils/constants";
import { Colors } from "constants/Colors";
import { FILL_WIDGET_MIN_WIDTH } from "constants/minWidthConstants";
import {
  GridDefaults,
  WIDGET_TAGS,
  WidgetHeightLimits,
} from "constants/WidgetConstants";
import type { WidgetProps } from "widgets/BaseWidget";
import { BlueprintOperationTypes } from "WidgetProvider/constants";
import IconSVG from "../icon.svg";
import { renderAppsmithCanvas } from "layoutSystems/CanvasFactory";
import { generateTypeDef } from "utils/autocomplete/defCreatorUtils";
import type { ExecuteTriggerPayload } from "../../../constants/AppsmithActionConstants/ActionConstants";
import { EventType } from "../../../constants/AppsmithActionConstants/ActionConstants";
import ThumbnailSVG from "../zuora.svg";
import { klona as clone } from "klona/full";
import headerActionConfig from "./propertyConfig/headerActionConfig";

class StepperWidget extends BaseWidget<
  StepperWidgetProps<StepperContainerWidgetProps>,
  WidgetState
> {
  static type = "STEPPER_WIDGET";

  constructor(props: StepperWidgetProps<StepperContainerWidgetProps>) {
    super(props);
    this.state = {
      onLoad: null,
      onNextStep: null,
      onPreviousStep: null,
    };
  }

  static getConfig() {
    return {
      name: "Stepper",
      iconSVG: IconSVG,
      tags: [WIDGET_TAGS.ZUORA],
      needsMeta: true,
      isCanvas: true,
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 3,
        active: true,
      },
    };
  }

  static getDefaults() {
    return {
      flexVerticalAlignment: FlexVerticalAlignment.Stretch,
      responsiveBehavior: ResponsiveBehavior.Fill,
      minWidth: FILL_WIDGET_MIN_WIDTH,
      rows: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS + 5,
      columns: 100,
      shouldScrollContents: false,
      widgetName: "Stepper",
      animateLoading: true,
      borderWidth: 1,
      borderColor: Colors.GREY_5,
      backgroundColor: Colors.WHITE,
      minDynamicHeight: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS + 5,
      orientation: "horizontal",
      stepperData: {
        step1: {
          label: "Step 1",
          id: "step1",
          widgetId: "",
          isVisible: true,
          index: 0,
          positioning: Positioning.Vertical,
        },
        step2: {
          label: "Step 2",
          id: "step2",
          widgetId: "",
          isVisible: true,
          index: 1,
          positioning: Positioning.Vertical,
        },
      },
      activeStep: 0,
      maxLeftHeaderActionDisplay: 2,
      leftHeaderVisible: true,
      leftHeaderActions: {
        headerAction1: {
          label: "Group",
          starIcon: "heart",
          id: "headerAction1",
          widgetId: "",
          actionType: "GROUP_BUTTONS",
          isVisible: true,
          isDisabled: false,
          divider: true,
          index: 0,
          variant: "contained",
          menuItems: {},
          selectWidth: 100,
          groupButtons: {
            groupButton1: {
              label: "Favorite",
              iconName: "heart",
              id: "groupButton1",
              widgetId: "",
              buttonType: "SIMPLE",
              placement: "CENTER",
              isVisible: true,
              isDisabled: false,
              index: 0,
              menuItems: {},
            },
          },
        },
      },
      maxRightHeaderActionDisplay: 2,
      rightHeaderVisible: true,
      rightHeaderActions: {
        headerAction1: {
          label: "Favorite",
          starIcon: "heart",
          id: "headerAction1",
          widgetId: "",
          actionType: "BUTTON",
          isVisible: true,
          isDisabled: false,
          divider: true,
          index: 0,
          variant: "contained",
          menuItems: {},
          selectWidth: 100,
          menuItemsSource: "STATIC",
        },
      },
      blueprint: {
        view: [
          {
            type: "CANVAS_WIDGET",
            position: { left: 0, top: 0 },
            props: {
              detachFromLayout: true,
              canExtend: true,
              isVisible: true,
              isDisabled: false,
              shouldScrollContents: false,
              stepId: "step1",
              stepName: "Step 1",
              children: [],
              version: 1,
              bottomRow: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS,
            },
          },
          {
            type: "CANVAS_WIDGET",
            position: { left: 0, top: 0 },
            props: {
              detachFromLayout: true,
              canExtend: true,
              isVisible: true,
              isDisabled: false,
              shouldScrollContents: false,
              stepId: "step2",
              stepName: "Step 2",
              children: [],
              version: 1,
              bottomRow: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS,
            },
          },
        ],
        operations: [
          {
            type: BlueprintOperationTypes.MODIFY_PROPS,
            fn: (widget: WidgetProps & { children?: WidgetProps[] }) => {
              const rightHeaderActions = clone(widget.rightHeaderActions) || {};
              const leftHeaderActions = clone(widget.leftHeaderActions) || {};
              const dynamicBindingPathList: any[] = get(
                widget,
                "dynamicBindingPathList",
                [],
              );
              const steps = Object.values({ ...widget.stepperData });
              const stepIds: Record<string, string> = (
                widget.children || []
              ).reduce((idsObj, eachChild) => {
                idsObj = {
                  ...idsObj,
                  [eachChild.stepId]: eachChild.widgetId,
                };
                return idsObj;
              }, {});
              const stepperData = steps.reduce((obj: any, step: any) => {
                const newStep = { ...step };
                newStep.widgetId = stepIds[newStep.id];
                obj[newStep.id] = newStep;
                return obj;
              }, {});

              Object.keys(rightHeaderActions).map((action) => {
                rightHeaderActions[action].buttonColor = get(
                  widget,
                  "childStylesheet.button.buttonColor",
                  "{{appsmith.theme.colors.primaryColor}}",
                );

                dynamicBindingPathList.push({
                  key: `rightHeaderActions.${action}.buttonColor`,
                });
              });
              Object.keys(leftHeaderActions).map((action) => {
                leftHeaderActions[action].buttonColor = get(
                  widget,
                  "childStylesheet.button.buttonColor",
                  "{{appsmith.theme.colors.primaryColor}}",
                );

                dynamicBindingPathList.push({
                  key: `leftHeaderActions.${action}.buttonColor`,
                });
              });

              const updatePropertyMap = [
                {
                  widgetId: widget.widgetId,
                  propertyName: "stepperData",
                  propertyValue: stepperData,
                },
                {
                  widgetId: widget.widgetId,
                  propertyName: "dynamicBindingPathList",
                  propertyValue: dynamicBindingPathList,
                },
                {
                  widgetId: widget.widgetId,
                  propertyName: "leftHeaderActions",
                  propertyValue: leftHeaderActions,
                },
                {
                  widgetId: widget.widgetId,
                  propertyName: "rightHeaderActions",
                  propertyValue: rightHeaderActions,
                },
              ];

              return updatePropertyMap;
            },
          },
        ],
      },
      version: 3,
    };
  }

  static getMethods() {
    return {
      getCanvasHeightOffset: (props: WidgetProps): number => {
        let offset =
          props.borderWidth && props.borderWidth > 1
            ? Math.ceil(
                (2 * parseInt(props.borderWidth, 10) || 0) /
                  GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
              )
            : 0;
        // if (props.alternativeLabel === true) {
        //   offset += 4;
        // }
        return (offset += 6);
      },
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "280px",
              minHeight: "300px",
            };
          },
        },
      ],
    };
  }

  static getAnvilConfig(): AnvilConfig | null {
    return {
      isLargeWidget: false,
      widgetSize: {
        maxHeight: {},
        maxWidth: {},
        minHeight: { base: "300px" },
        minWidth: { base: "280px" },
      },
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "Data",
        children: [
          {
            propertyName: "stepperData",
            isJSConvertible: false,
            label: "Steps",
            helpText: "Steps",
            controlType: "STEPPER_INPUT",
            isBindProperty: false,
            isTriggerProperty: false,
            updateRelatedWidgetProperties: (
              propertyPath: string,
              propertyValue: string,
              props: WidgetProperties,
            ) => {
              const propertyPathSplit = propertyPath.split(".");
              const property = propertyPathSplit.pop();
              if (property === "label") {
                const itemId = propertyPathSplit.pop() || "";
                const item = props.stepperData[itemId];
                if (item) {
                  return [
                    {
                      widgetId: item.widgetId,
                      updates: {
                        modify: {
                          stepName: propertyValue,
                        },
                      },
                    },
                  ];
                }
              }
              return [];
            },
            panelConfig: {
              editableTitle: true,
              titlePropertyName: "label",
              panelIdPropertyName: "id",
              updateHook: (
                props: any,
                propertyPath: string,
                propertyValue: string,
              ) => {
                return [
                  {
                    propertyPath,
                    propertyValue,
                  },
                ];
              },
              children: [
                {
                  sectionName: "General",
                  children: [
                    {
                      propertyName: "isVisible",
                      label: "Visible",
                      helpText: "Controls the visibility of the step",
                      controlType: "SWITCH",
                      useValidationMessage: true,
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.BOOLEAN },
                    },
                    {
                      propertyName: "isValidated",
                      label: "Validated",
                      helpText: "Step validation",
                      controlType: "SWITCH",
                      useValidationMessage: true,
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.BOOLEAN },
                    },
                  ],
                },
                {
                  sectionName: "Events",
                  children: [
                    {
                      propertyName: "onLoad",
                      label: "onLoad",
                      controlType: "ACTION_SELECTOR",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: true,
                    },
                    {
                      propertyName: "onNextStep",
                      label: "onNextStep",
                      controlType: "ACTION_SELECTOR",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: true,
                    },
                    {
                      propertyName: "onPreviousStep",
                      label: "onPreviousStep",
                      controlType: "ACTION_SELECTOR",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: true,
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      {
        sectionName: "Left Header Action",
        children: [
          {
            propertyName: "leftHeaderActions",
            label: "Left Header Action",
            controlType: "HEADER_ACTION",
            dependencies: ["childStylesheet"],
            isBindProperty: false,
            isTriggerProperty: false,
            hidden: (
              props: StepperWidgetProps<StepperContainerWidgetProps>,
            ) => {
              return props.orientation === "vertical";
            },
            panelConfig: headerActionConfig,
          },
          {
            propertyName: "maxLeftHeaderActionDisplay",
            label: "Max Left Header Action Display",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
            hidden: (
              props: StepperWidgetProps<StepperContainerWidgetProps>,
            ) => {
              return props.orientation === "vertical";
            },
          },
          {
            propertyName: "leftHeaderVisible",
            label: "Visible",
            helpText: "Controls the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "Right Header Action",
        children: [
          {
            propertyName: "rightHeaderActions",
            label: "Right Header Action",
            controlType: "HEADER_ACTION",
            dependencies: ["childStylesheet"],
            isBindProperty: false,
            isTriggerProperty: false,
            hidden: (
              props: StepperWidgetProps<StepperContainerWidgetProps>,
            ) => {
              return props.orientation === "vertical";
            },
            panelConfig: headerActionConfig,
          },
          {
            propertyName: "maxRightHeaderActionDisplay",
            label: "Max Right Header Action Display",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
            hidden: (
              props: StepperWidgetProps<StepperContainerWidgetProps>,
            ) => {
              return props.orientation === "vertical";
            },
          },
          {
            propertyName: "rightHeaderVisible",
            label: "Visible",
            helpText: "Controls the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "General",
        children: [
          {
            hidden: (
              props: StepperWidgetProps<StepperContainerWidgetProps>,
            ) => {
              return props.orientation === "vertical";
            },
            propertyName: "alternativeLabel",
            label: "Alternative Label",
            helpText:
              "Labels can be placed below the step icon by setting alternativeLabel",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            hidden: (
              props: StepperWidgetProps<StepperContainerWidgetProps>,
            ) => {
              return props.orientation === "vertical";
            },
            propertyName: "stepButton",
            label: "Previous/Next Button",
            helpText: "Show Next/previous Button",
            controlType: "SWITCH",
            defaultValue: false,
            isJSConvertible: false,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "nonLinear",
            label: "Non-linear",
            helpText:
              "Non-linear steppers allow the user to enter a multi-step flow at any point",
            controlType: "SWITCH",
            isJSConvertible: true,
            defaultValue: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "hideStepper",
            label: "Hide Stepper",
            controlType: "SWITCH",
            isJSConvertible: true,
            defaultValue: false,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "orientation",
            label: "Steppers Orientation",
            controlType: "DROP_DOWN",
            isBindProperty: false,
            isTriggerProperty: false,
            defaultValue: "horizontal",
            options: [
              { label: "Horizontal", value: "horizontal" },
              { label: "Vertical", value: "vertical" },
            ],
          },
          {
            propertyName: "isVisible",
            label: "Visible",
            helpText: "Controls the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText: "Enables scrolling for content inside the widget",
            propertyName: "shouldScrollContents",
            label: "Scroll contents",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "animateLoading",
            label: "Animate loading",
            controlType: "SWITCH",
            helpText: "Controls the loading of the widget",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "Events",
        children: [
          {
            propertyName: "onChange",
            label: "onChange",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  callDynamicHeightUpdates = () => {
    const { checkContainersForAutoHeight } = this.context;
    checkContainersForAutoHeight && checkContainersForAutoHeight();
  };

  callPositionUpdates = (stepWidgetId: string) => {
    const { updatePositionsOnStepChange } = this.context;
    updatePositionsOnStepChange &&
      updatePositionsOnStepChange(this.props.widgetId, stepWidgetId);
  };

  onStepChange = (activeStep: number) => {
    this.updateWidgetProperty("activeStep", activeStep);
  };

  onLoad = () => {
    if (this.state.onLoad && typeof this.state.onLoad === "string") {
      super.executeAction({
        triggerPropertyName: "onLoad",
        dynamicString: this.state.onLoad,
        event: {
          type: EventType.ON_LOAD,
        },
      });
    }
  };

  onChange = () => {
    if (this.props.onChange) {
      super.executeAction({
        triggerPropertyName: "onChange",
        dynamicString: this.props.onChange,
        event: {
          type: EventType.ON_CHANGE,
        },
      });
    }
  };

  async onNextStep() {
    if (this.state.onNextStep && typeof this.state.onNextStep === "string") {
      await super.executeAction({
        triggerPropertyName: "onNextStep",
        dynamicString: this.state.onNextStep,
        event: {
          type: EventType.ON_NEXT_STEP,
        },
      });
    }
    this.setDefaultSelectedStepWidgetId();
  }

  async onPreviousStep() {
    if (
      this.state.onNextStep &&
      typeof this.state.onPreviousStep === "string"
    ) {
      await super.executeAction({
        triggerPropertyName: "onPreviousStep",
        dynamicString: this.state.onPreviousStep,
        event: {
          type: EventType.ON_PREVIOUS_STEP,
        },
      });
    }
    this.setDefaultSelectedStepWidgetId();
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "Colors, Borders and Shadows",
        children: [
          {
            propertyName: "activeColor",
            helpText: "Changes the color of the active step",
            label: "Active Step Color",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "completedColor",
            helpText: "Changes the color of the completed step",
            label: "Completed Step Color",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "Use a html color name, HEX, RGB or RGBA value",
            placeholderText: "#FFFFFF / Gray / rgb(255, 99, 71)",
            propertyName: "backgroundColor",
            label: "Background color",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "Use a html color name, HEX, RGB or RGBA value",
            placeholderText: "#FFFFFF / Gray / rgb(255, 99, 71)",
            propertyName: "borderColor",
            label: "Border color",
            controlType: "COLOR_PICKER",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "Enter value for border width",
            propertyName: "borderWidth",
            label: "Border width",
            placeholderText: "Enter value in px",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
            postUpdateAction: ReduxActionTypes.CHECK_CONTAINERS_FOR_AUTO_HEIGHT,
          },
          {
            propertyName: "borderRadius",
            label: "Border radius",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",

            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "boxShadow",
            label: "Box shadow",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
        setActiveStep: {
          path: "activeStep",
          type: "number",
        },
        setActiveColor: {
          path: "activeColor",
          type: "string",
        },
        setCompletedColor: {
          path: "completedColor",
          type: "string",
        },
      },
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      activeColor: "{{appsmith.theme.colors.primaryColor}}",
      completedColor: "{{appsmith.theme.colors.secondaryColor}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
      childStylesheet: {
        button: {
          buttonColor: "{{appsmith.theme.colors.primaryColor}}",
        },
      },
    };
  }

  static getDerivedPropertiesMap() {
    return {
      selectedStep: `{{(()=>{${derivedProperties.getSelectedStep}})()}}`,
      stepValidated: `{{(()=>{${derivedProperties.getStepValidated}})()}}`,
      isFirstStep: `{{(()=>{${derivedProperties.isFirstStep}})()}}`,
      isLastStep: `{{(()=>{${derivedProperties.isLastStep}})()}}`,
      isPastLastStep: `{{(()=>{${derivedProperties.isPastLastStep}})()}}`,
      steps: `{{(()=>{${derivedProperties.getVisibleSteps}})()}}`,
    };
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return (widget: StepperWidgetProps<StepperContainerWidgetProps>) => ({
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      activeStep: "number",
      selectedStep: "string",
      stepperData: generateTypeDef(widget.stepperData),
      steps: generateTypeDef(widget.steps),
      stepValidated: "bool",
      isFirstStep: "bool",
      isLastStep: "bool",
    });
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedStepWidgetId: undefined,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  handleClick = (onClick: string | undefined): void => {
    if (onClick) {
      const config: ExecuteTriggerPayload = {
        triggerPropertyName: "onClick",
        dynamicString: onClick,
        event: {
          type: EventType.ON_CLICK,
        },
      };
      super.executeAction(config);
    }
  };

  groupButtonHandleClick = (
    onClick: string | undefined,
    callback?: () => void,
  ): void => {
    if (onClick) {
      super.executeAction({
        triggerPropertyName: "onClick",
        dynamicString: onClick,
        event: {
          type: EventType.ON_CLICK,
          callback,
        },
      });
    }
  };

  handleOnchange = (
    onChange: string | undefined,
    propertyPath?: string,
    value?: any,
  ): void => {
    if (propertyPath && value != undefined) {
      this.updateWidgetProperty(propertyPath, value);
      // this.forceUpdate();
    }
    if (onChange) {
      super.executeAction({
        triggerPropertyName: "onChange",
        dynamicString: onChange,
        event: {
          type: EventType.ON_CHANGE,
        },
        globalContext: {
          selectedValue: value,
        },
      });
    }
  };

  getWidgetView() {
    const { componentWidth } = this.props;
    const stepperComponentProps = {
      ...this.props,
      steps: this.getVisibleSteps(),
      activeStep: this.props.activeStep,
      width: componentWidth - WIDGET_PADDING * 2,
      buttonClickHandler: this.handleClick,
      groupButtonHandleClick: this.groupButtonHandleClick,
      handleOnchange: this.handleOnchange,
      leftHeaderActions: this.props.leftHeaderActions || [],
      maxLeftHeaderActionDisplay: this.props.maxLeftHeaderActionDisplay || 0,
      leftHeaderVisible: this.props.leftHeaderVisible,
      rightHeaderActions: this.props.rightHeaderActions || [],
      maxRightHeaderActionDisplay: this.props.maxRightHeaderActionDisplay || 0,
      rightHeaderVisible: this.props.rightHeaderVisible,
    };
    const isAutoHeightEnabled: boolean =
      isAutoHeightEnabledForWidget(this.props) &&
      !isAutoHeightEnabledForWidgetWithLimits(this.props);

    return (
      <StepperComponent
        {...stepperComponentProps}
        $noScroll={isAutoHeightEnabled}
        activeColor={this.props.activeColor}
        backgroundColor={this.props.backgroundColor}
        borderRadius={this.props.borderRadius}
        boxShadow={this.props.boxShadow}
        completedColor={this.props.completedColor}
        groupButtonHandleClick={this.groupButtonHandleClick}
        isAlternativeLabel={this.props.alternativeLabel}
        onLoad={this.onLoad}
        onStepChange={this.onStepChange}
        selectedStepWidgetId={this.getSelectedStepWidgetId()}
        shouldScrollContents={
          this.props.shouldScrollContents &&
          this.props.layoutSystemType === LayoutSystemTypes.FIXED
        }
        stepButton={this.props.stepButton}
      >
        {this.renderComponent()}
      </StepperComponent>
    );
  }

  renderComponent = () => {
    const selectedStepWidgetId = this.getSelectedStepWidgetId();
    const childWidgetData = {
      ...this.props.children?.filter(Boolean).filter((item) => {
        return selectedStepWidgetId === item.widgetId;
      })[0],
    };
    if (!childWidgetData) {
      return null;
    }

    childWidgetData.canExtend = this.props.shouldScrollContents;
    const { componentHeight, componentWidth } = this.props;
    childWidgetData.rightColumn = componentWidth;
    childWidgetData.isVisible = this.props.isVisible;
    childWidgetData.bottomRow = this.props.shouldScrollContents
      ? childWidgetData.bottomRow
      : componentHeight - 1;
    childWidgetData.parentId = this.props.widgetId;
    childWidgetData.minHeight = componentHeight;
    const selectedStepProps = Object.values(this.props.stepperData)?.filter(
      (item) => item.widgetId === selectedStepWidgetId,
    )[0];
    const positioning: Positioning =
      this.props.layoutSystemType == LayoutSystemTypes.AUTO
        ? Positioning.Vertical
        : Positioning.Fixed;
    childWidgetData.positioning = positioning;
    childWidgetData.useAutoLayout = positioning !== Positioning.Fixed;
    childWidgetData.direction =
      positioning === Positioning.Vertical
        ? LayoutDirection.Vertical
        : LayoutDirection.Horizontal;
    childWidgetData.alignment = selectedStepProps?.alignment;
    childWidgetData.spacing = selectedStepProps?.spacing;
    return renderAppsmithCanvas(childWidgetData as WidgetProps);
  };

  private getSelectedStepWidgetId() {
    let selectedStepWidgetId = this.props.selectedStepWidgetId;
    if (this.props.children) {
      selectedStepWidgetId =
        this.props.children.find((step) =>
          this.props.selectedWidgetAncestry?.includes(step.widgetId),
        )?.widgetId ?? this.props.selectedStepWidgetId;
    }
    return selectedStepWidgetId;
  }

  async componentDidUpdate(
    prevProps: StepperWidgetProps<StepperContainerWidgetProps>,
  ) {
    const visibleSteps = this.getVisibleSteps();
    const selectedStep = find(visibleSteps, {
      widgetId: this.props.selectedStepWidgetId,
    });

    const numbOfStepchange = this.stepChange(
      this.props.stepperData,
      prevProps.stepperData,
    );

    if (numbOfStepchange < 0 && this.props.activeStep > visibleSteps.length) {
      this.updateWidgetProperty(
        "activeStep",
        this.props.activeStep + numbOfStepchange < 0
          ? 0
          : this.props.activeStep + numbOfStepchange,
      );
    }

    if (this.props.activeStep !== prevProps.activeStep || !selectedStep) {
      this.updateWidgetProperty("activeStep", this.props.activeStep);
      this.onChange();
      if (this.props.activeStep - 1 === prevProps.activeStep) {
        this.onNextStep();
      } else if (this.props.activeStep + 1 === prevProps.activeStep) {
        this.onPreviousStep();
      } else {
        this.setDefaultSelectedStepWidgetId();
      }
    } else {
      setTimeout(this.callDynamicHeightUpdates, 0);
      setTimeout(
        () => this.callPositionUpdates(this.props.selectedStepWidgetId),
        0,
      );
    }
  }

  stepChange = (
    newstepData: Record<string, StepperDataProps>,
    oldstepData: Record<string, StepperDataProps>,
  ) => {
    let newSteps = Object.values(newstepData || {});
    if (newSteps.length) {
      newSteps = newSteps
        .filter(
          (step) => step.isVisible === undefined || !!step.isVisible === true,
        )
        .sort((step1, step2) => step1.index - step2.index);
    }

    let oldSteps = Object.values(oldstepData || {});
    if (oldSteps.length) {
      oldSteps = oldSteps
        .filter(
          (step) => step.isVisible === undefined || !!step.isVisible === true,
        )
        .sort((step1, step2) => step1.index - step2.index);
    }

    return newSteps.length - oldSteps.length;
  };

  getVisibleSteps = () => {
    const steps = Object.values(this.props.stepperData || {});
    if (steps.length) {
      return steps
        .filter(
          (step) => step.isVisible === undefined || !!step.isVisible === true,
        )
        .sort((step1, step2) => step1.index - step2.index);
    }
    return [];
  };

  setDefaultSelectedStepWidgetId = () => {
    const visibleSteps = this.getVisibleSteps();
    let activeStep = visibleSteps[this.props.activeStep];
    if (!activeStep) {
      activeStep = visibleSteps[0];
      this.updateWidgetProperty("activeStep", 0);
    }
    const activeStepWidgetId =
      activeStep?.widgetId ?? visibleSteps?.[0]?.widgetId;

    if (
      activeStepWidgetId &&
      activeStepWidgetId !== this.props.selectedStepWidgetId
    ) {
      this.setState(
        {
          onLoad: activeStep.onLoad,
          onNextStep: activeStep.onNextStep,
          onPreviousStep: activeStep.onPreviousStep,
          isRollback: false,
        },
        () => {
          this.props.updateWidgetMetaProperty(
            "selectedStepWidgetId",
            activeStepWidgetId,
          );
        },
      );
      setTimeout(this.callDynamicHeightUpdates, 0);
      setTimeout(() => this.callPositionUpdates(activeStepWidgetId), 0);
    }
  };

  componentDidMount() {
    Object.keys(this.props.stepperData || {}).length &&
      this.setDefaultSelectedStepWidgetId();
  }
}

export default StepperWidget;
