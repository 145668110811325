import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import { ValidationTypes } from "constants/WidgetValidation";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import { CircularProgress } from "@mui/material";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../zuora.svg";
import styled from "styled-components";
import { AutocompleteDataType } from "../../../utils/autocomplete/AutocompleteDataType";
import type { SetterConfig, Stylesheet } from "../../../entities/AppTheming";
import type { AutoLayoutConfig } from "../../../WidgetProvider/constants";

const ContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
`;
class ZCircularProgressWidget extends BaseWidget<
  ZCircularProgressWidgetProps,
  WidgetState
> {
  static type = "ZCIRCULARPROGRESS_WIDGET";

  static getConfig() {
    return {
      name: "ZCircular Progress",
      tags: [WIDGET_TAGS.ZUORA],
      iconSVG: IconSVG,
      needsMeta: false,
      isCanvas: false,
      thumbnailSVG: ThumbnailSVG,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 0, // Index of the property pane "General" section
        active: true,
      },
    };
  }

  static getDefaults() {
    return {
      widgetName: "ZCircularProgress",
      rows: 40,
      columns: 40,
      version: 1,
      color: "primary",
      thickness: 3,
      isVisible: true,
      size: "30",
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {
      // Specify the default height and width in rows and columns
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => ({
            minWidth: "40 columns", // Minimum width in columns
            minHeight: "40 rows", // Minimum height in rows
          }),
        },
      ],
    };
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
      },
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "fillColor",
            label: "Fill color",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^((?![<|{{]).+){0,1}/,
                expected: {
                  type: "string (HTML color name or HEX value)",
                  example: `red | #9C0D38`,
                  autocompleteDataType: AutocompleteDataType.STRING,
                },
              },
            },
          },
          // {
          //   propertyName: "size",
          //   label: "Size",
          //   controlType: "INPUT_TEXT",
          //   isBindProperty: true,
          //   isTriggerProperty: false,
          //   validation: { type: ValidationTypes.TEXT },
          // },
          {
            propertyName: "thickness",
            label: "Thickness",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "isVisible",
            helpText: "Controls the visibility of the widget",
            label: "Visible",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      fillColor: "{{appsmith.theme.colors.primaryColor}}",
    };
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    const ZCircularProgressWidgetProps = {
      ...this.props,
    };

    return (
      <ContainerWrapper>
        <CircularProgress
          {...ZCircularProgressWidgetProps}
          sx={{ color: this.props.fillColor }}
        />
      </ContainerWrapper>
    );
  }
}

export interface ZCircularProgressWidgetProps extends WidgetProps {
  size: number | string;
  thickness?: number;
  fillColor: string;
}

export default ZCircularProgressWidget;
