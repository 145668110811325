import { Box, IconButton, Stack } from "@mui/material";
import { designTokens, Tooltip, Typography } from "zds";
import ChangeIndicator from "./ChangeIndicator";
import React from "react";

interface InfoCardProps {
  title: string;
  subtitle?: string; // Mark subtitle as optional
  value: string | number;
  changeValue: number;
  tooltipText: string;
  dateText: string;
  currency?: string; // Assuming currency might also be optional
}

export const InfoCard: React.FC<InfoCardProps> = ({
  changeValue,
  currency,
  dateText,
  subtitle,
  title,
  value,
}) => {
  return (
    <Box
      sx={{
        height: "108px",
        boxShadow: 1,
        borderRadius: 1,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        p: 1.5,
      }}
    >
      <Stack direction="column">
        <Stack alignItems="center" direction="row" spacing={0}>
          <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }} variant="overline">
            {title}
          </Typography>
          <Tooltip
            dsOnClose={undefined}
            dsOnOpen={undefined}
            e2e={""}
            title={""}
          >
            <IconButton size="small">
              {/* <InfoOutlinedIcon
                sx={{ color: 'text.secondary' }}
                fontSize="small"
              /> */}
            </IconButton>
          </Tooltip>
        </Stack>
        {subtitle && (
          <Typography
            component="div"
            sx={{ color: "rgba(0, 0, 0, 0.6)" }}
            variant="overline"
          >
            {subtitle}
          </Typography>
        )}
      </Stack>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography variant="h6">
            {value}{" "}
            {currency && (
              <Typography
                component="span"
                sx={{ color: designTokens.colors.lightEmphasisLow }}
                variant="overline"
              >
                {currency}
              </Typography>
            )}
          </Typography>
          <Stack alignItems="flex-end" direction="column">
            <ChangeIndicator value={changeValue} />
            <Typography variant="caption">{dateText}</Typography>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
