import { Box, debounce, Divider, Slider, Stack } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  designTokens,
  Grid,
  Icon,
  Select,
  SelectItem,
  Typography,
} from "zds";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsTreemap from "highcharts/modules/treemap";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import styled from "styled-components";
import { DURATION_TYPE } from "widgets/ZAnalytics/util/constants";
HighchartsTreemap(Highcharts);
HighchartsHeatmap(Highcharts);
interface MeterSegmentationChart {
  segmentationData: any;
  mode: string;
  selectedMeterId: string;
  selectedMetric: string;
  getSegmentation: any;
}

const StyledSelect = styled.div`
  width: 200px;
`;

const MeterSegmentationChart = (props: MeterSegmentationChart) => {
  const {
    getSegmentation,
    mode,
    segmentationData,
    selectedMeterId,
    selectedMetric,
  } = props;
  const [dimensionsForHighchartsList, setDimensionsForHighchartsList] =
    useState<string[]>([]);
  const [highchartsJSONList, setHighchartsJSONList] = useState<any>([]);
  const [minPercentage, setMinPercentage] = useState(0);
  const [highchartsMasterJSONList, setHighchartsMasterJSONList] = useState([]);

  const debouncedSetMinPercentage = useCallback(
    debounce((value) => {
      const newChartOptions = highchartsMasterJSONList.map((chart: any) => {
        return {
          ...chart,
          series: chart.series.map((series: { data: any[] }) => {
            return {
              ...series,
              data: series.data.filter(({ data }) => {
                if (mode !== "PERCENT") {
                  return Math.abs(data.percentContribution) >= value;
                }
                return Math.abs(data.percentChange) >= value;
              }),
            };
          }),
        };
      });
      setHighchartsJSONList(newChartOptions);
    }, 300),
    [highchartsMasterJSONList],
  );

  const handleSliderChange = (e: any) => {
    const value = e.target.value;
    setMinPercentage(value);
    debouncedSetMinPercentage(value);
  };

  function groupAndSummarizeSeries(seriesData: any[]) {
    if (seriesData.length <= 100) {
      return seriesData;
    }

    const totalSum = seriesData.reduce((sum, item) => sum + item.value, 0);

    // Sort the seriesData array based on the value in descending order
    seriesData.sort((a, b) => b.value - a.value);

    const firstHundred = seriesData.slice(0, 100);
    const remainingSum = seriesData
      .slice(100)
      .reduce((sum, item) => sum + item.value, 0);

    const truncatedPercent = parseFloat(
      ((remainingSum / totalSum) * 100).toFixed(3),
    );
    const label = `Other (${truncatedPercent}%)`;

    const otherItem = {
      name: label,
      value: remainingSum,
      data: null,
      percent: truncatedPercent,
      color: "#cccccc", // Gray color
    };

    const summarizedSeriesData = [...firstHundred, otherItem];
    return summarizedSeriesData;
  }

  function assignHexCode(
    lower: number | null,
    higher: number | null,
    target: any,
  ) {
    if (lower === null || isNaN(lower)) {
      return "#cccccc";
    }
    if (higher === null || isNaN(higher)) {
      return "#cccccc";
    }
    if (target === null || isNaN(target)) {
      return "#cccccc";
    }

    let minHex: any;
    let maxHex: any;
    if (target >= 0) {
      minHex = "#DEFFDF"; // Light green
      maxHex = "#006400"; // Dark green
      minHex = "#98de92";
      maxHex = "#41ba38";
      lower = 0;
    }
    if (target < 0) {
      minHex = "#941010"; // Dark red
      maxHex = "#c96d6d"; // Light red
      minHex = "#e62c2f";
      maxHex = "#dea492";
      higher = 0;
    }

    // Ensure target is within the range
    if (target <= lower) {
      return minHex;
    }
    if (target >= higher) {
      return maxHex;
    }

    // Calculate the percentage of target between lower and higher
    const percentage = (target - lower) / (higher - lower);

    // Interpolate between minHex and maxHex based on the percentage
    const r = Math.floor(
      parseInt(minHex.slice(1, 3), 16) +
        percentage *
          (parseInt(maxHex.slice(1, 3), 16) - parseInt(minHex.slice(1, 3), 16)),
    );
    const g = Math.floor(
      parseInt(minHex.slice(3, 5), 16) +
        percentage *
          (parseInt(maxHex.slice(3, 5), 16) - parseInt(minHex.slice(3, 5), 16)),
    );
    const b = Math.floor(
      parseInt(minHex.slice(5, 7), 16) +
        percentage *
          (parseInt(maxHex.slice(5, 7), 16) - parseInt(minHex.slice(5, 7), 16)),
    );

    // Construct the hexcode
    const hexCode = `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

    return hexCode;
  }
  const createHighchartsJSONForPercentChange = (
    _dimensionName: string,
    dimensionData: any,
  ) => {
    let hasSeriesData = true;
    let seriesData: any[] = [];

    if (dimensionData && dimensionData.length > 0) {
      // get lower and upper for percent change range
      const percentChanges = dimensionData.map(
        (item: any) => item.percentChange,
      );
      const filteredPercentChanges = percentChanges.filter(
        (value: any) => !isNaN(value),
      );
      const lowerNumber = Math.min(...filteredPercentChanges);
      const upperNumber = Math.max(...filteredPercentChanges);

      seriesData = dimensionData.map((item: any) => {
        const percentChange = parseFloat(item.percentChange);
        const truncatedPercentValue = isNaN(percentChange)
          ? null
          : percentChange.toFixed(3);
        const label =
          truncatedPercentValue === null
            ? `${item.name} (New)`
            : `${item.name} (${truncatedPercentValue}%)`;

        return {
          name: label,
          value: item.value,
          data: item,
          percent: truncatedPercentValue,
          color: assignHexCode(lowerNumber, upperNumber, truncatedPercentValue),
        };
      });
      // Only display 100 highest value data points
      seriesData = groupAndSummarizeSeries(seriesData);
    } else {
      hasSeriesData = false;
    }

    const chartJSON: any = {
      chart: {
        type: "treemap",
        height: 100,
        marginLeft: 100, // Adjust the left margin to create space for the title
      },
      title: {
        text: "",
      },
      series: [
        {
          type: "treemap",
          layoutAlgorithm: "strip",
          data: seriesData,
        },
      ],
      credits: {
        enabled: false,
      },
      plotOptions: {
        treemap: {
          dataLabels: {
            enabled: true,
            style: {
              fontFamily: "Arial",
              color: "black",
              fontWeight: "bold",
              fontSize: "12px",
              textOutline: "none",
            },
          },
        },
        series: {
          cursor: "pointer",
          // events: {
          //   click: function (event) {
          //     if (!event.point.data) {
          //       return;
          //     }
          //     const inputValue: string = event.point.data.name;
          //     const newDimensionFilter = dimensionName + ':' + inputValue;
          //     setDimensionFieldsWithValues((prevFilters) => [
          //       ...prevFilters,
          //       newDimensionFilter,
          //     ]);
          //     setDimensionFilters((prevFilters) => [
          //       ...prevFilters,
          //       newDimensionFilter,
          //     ]);
          //     setDimensionFields((prev) => [
          //       ...prev,
          //       {
          //         selectedItem: dimensionName,
          //         inputValue: inputValue,
          //       },
          //     ]);
          //     setDimensionFiltersChanged(true);
          //   },
          // },
        },
      },
    };

    return hasSeriesData ? chartJSON : null;
  };

  const createHighchartsJSONForContribution = (
    dimensionName: string,
    dimensionData: any,
  ) => {
    const colors = ["#76b5c5", "#063970", "#1e81b0"];
    let colorIndex = 0;

    let seriesData = [];

    let totalValue = 0;
    if (dimensionData && dimensionData.length > 0) {
      // Calculate the sum value for percentage contribution calculation
      totalValue = dimensionData.reduce(
        (acc: any, item: { value: any }) => acc + item.value,
        0,
      );

      seriesData = dimensionData.map((item: { value: number; name: any }) => {
        const color = colors[colorIndex];
        colorIndex = (colorIndex + 1) % colors.length;

        const percentage = (item.value / totalValue) * 100;
        const label = isNaN(percentage)
          ? `${item.name} (New)`
          : `${item.name} (${percentage.toFixed(3)}%)`;

        return {
          name: label,
          value: item.value,
          data: item,
          percent: percentage,
          color: color,
        };
      });

      seriesData = groupAndSummarizeSeries(seriesData);
    } else {
      return null;
    }

    const chartJSON: any = {
      chart: {
        type: "treemap",
        height: 100,
        marginLeft: 100,
      },
      title: {
        text: "",
      },
      series: [
        {
          type: "treemap",
          layoutAlgorithm: "strip",
          data: seriesData,
        },
      ],
      credits: {
        enabled: false,
      },
    };

    // Add plotOptions only if dimensionData is not empty
    if (dimensionData && dimensionData.length > 0) {
      chartJSON.plotOptions = {
        series: {
          cursor: "pointer",
          events: {
            click: function (event: any) {
              if (!event.point.data) {
                return;
              }
              //const value: string = event.point.data.name;
              //const newFilter: string = dimensionName + ": " + value;
              // if (!dimensionFilters.includes(newFilter)) {
              //   const newDimensionFilter: string = dimensionName + ':' + value;
              //   setDimensionFieldsWithValues((prevFilters) => [
              //     ...prevFilters,
              //     newDimensionFilter,
              //   ]);
              //   setDimensionFilters((prevFilters) => [
              //     ...prevFilters,
              //     newDimensionFilter,
              //   ]);
              //   setDimensionFields((prev) => [
              //     ...prev,
              //     {
              //       selectedItem: dimensionName,
              //       inputValue: value,
              //     },
              //   ]);
              //   setDimensionFiltersChanged(true);
              // }
            },
          },
        },
      };
    }

    return chartJSON;
  };

  const constructChartList = () => {
    const newHighchartsJSONList: any = Object.entries(
      segmentationData?.dimensions,
    ).map(([dimensionName, dimensionData]) => {
      setDimensionsForHighchartsList((prevList) => [
        ...prevList,
        dimensionName,
      ]);
      if (mode === "PERCENT") {
        return createHighchartsJSONForPercentChange(
          dimensionName,
          dimensionData,
        );
      } else {
        return createHighchartsJSONForContribution(
          dimensionName,
          dimensionData,
        );
      }
    });
    setHighchartsJSONList(newHighchartsJSONList);
    setHighchartsMasterJSONList(newHighchartsJSONList);
  };
  useEffect(() => {
    if (segmentationData && segmentationData?.dimensions) {
      constructChartList();
    }
  }, [segmentationData]);
  const addChartInteractionEvent = useCallback((highchartsJSON: any) => {
    const charts = highchartsJSON.chart || {};
    const events = charts.events || {};
    return {
      ...highchartsJSON,
      chart: {
        ...charts,
        events: {
          ...events,
        },
      },
    };
  }, []);
  return (
    <Card
      body={
        <>
          <Box
            mt={2}
            p={2}
            style={{
              background: designTokens.colors.darkDividerLow,
            }}
          >
            <Grid container spacing={1}>
              <Grid item md={4}>
                <Stack direction="row" spacing={1}>
                  <Typography color={mode === "PERCENT" ? "gray" : ""}>
                    Set minimum contribution value
                  </Typography>
                  <Slider
                    aria-label="Small"
                    defaultValue={0}
                    disabled={mode === "PERCENT"}
                    onChange={handleSliderChange}
                    size="small"
                    style={{
                      width: "40%",
                    }}
                    value={mode !== "PERCENT" ? minPercentage : 0}
                    valueLabelDisplay="auto"
                  />
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack direction="row" spacing={1}>
                  <Typography color={mode !== "PERCENT" ? "gray" : ""}>
                    {" "}
                    Minimum percentage change value
                  </Typography>
                  <Slider
                    aria-label="Small"
                    defaultValue={0}
                    disabled={mode !== "PERCENT"}
                    onChange={handleSliderChange}
                    size="small"
                    style={{
                      width: "40%",
                    }}
                    value={mode === "PERCENT" ? minPercentage : 0}
                    valueLabelDisplay="auto"
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Stack spacing={0.5}>
            {highchartsJSONList.map((highchartsJSON: any, index: any) => (
              <div
                key={index}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "0.5rem",
                }}
              >
                <Typography
                  sx={{ fontSize: "13px", width: "150px", flexShrink: 0 }}
                  variant="subtitle1"
                >
                  {dimensionsForHighchartsList[index]}
                </Typography>
                {highchartsJSON && Object.keys(highchartsJSON).length > 0 ? (
                  <HighchartsReact
                    containerProps={{
                      style: { width: "100%" },
                    }}
                    highcharts={Highcharts}
                    key={index}
                    options={addChartInteractionEvent(highchartsJSON)}
                  />
                ) : (
                  <div
                    style={{
                      width: "calc(100% - 150px)",
                      height: "75px",
                      backgroundColor: "#cccccc", // Set background color to gray
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>No Data</Typography>
                  </div>
                )}
              </div>
            ))}
          </Stack>
        </>
      }
      header={
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack direction="column">
            <Stack
              direction="row"
              divider={<Divider flexItem orientation="vertical" />}
              spacing={1}
            >
              Meter Segmentation
              <Box
                alignItems="center"
                display="flex"
                sx={{ marginLeft: "4px", marginTop: "2px", fontSize: "18px" }}
              >
                <Icon
                  body="info"
                  fontSize="inherit"
                  tooltip={
                    "Analyze meters by break down of its dimensions and their values."
                  }
                />
              </Box>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            divider={<Divider flexItem orientation="vertical" />}
            spacing={1}
          >
            <StyledSelect>
              <Select
                dsOnChange={(e) => {
                  getSegmentation(
                    selectedMeterId,
                    DURATION_TYPE.twentyOneDays,
                    selectedMetric,
                    e.target.value,
                  );
                }}
                fullWidth
                value={mode}
              >
                <SelectItem value="PERCENT">
                  <Stack alignItems="center" direction="row">
                    Root Cause Analysis
                    <Box
                      alignItems="center"
                      display="flex"
                      sx={{
                        marginLeft: "4px",
                        fontSize: "18px",
                      }}
                    >
                      <Icon body="info" fontSize="inherit" />
                    </Box>
                  </Stack>
                </SelectItem>
                <SelectItem value="CONTRIBUTION">
                  Drill-down Analysis
                </SelectItem>
              </Select>
            </StyledSelect>
          </Stack>
        </Stack>
      }
      id="segementation-chart"
    />
  );
};

export default MeterSegmentationChart;
