import React from "react";
import type { MaybeElement } from "@blueprintjs/core";
import type { Alignment } from "@blueprintjs/core";
import type { IconName } from "@blueprintjs/icons";

import type { ComponentProps } from "widgets/BaseComponent";
import type { ButtonPlacement, ButtonVariant } from "components/constants";
import { BaseButton, type ButtonType } from "widgets/ButtonWidget/component";
import { useSelector } from "react-redux";
import { getCurrentApplication } from "ee/selectors/applicationSelectors";
import store from "store";

interface ButtonComponentProps extends ComponentProps {
  text?: string;
  icon?: IconName | MaybeElement;
  tooltip?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isDisabled?: boolean;
  isLoading: boolean;
  shouldFitContent: boolean;
  rightIcon?: IconName | MaybeElement;
  type: ButtonType;
  buttonColor?: string;
  buttonVariant?: ButtonVariant;
  borderRadius?: string;
  boxShadow?: string;
  boxShadowColor?: string;
  iconName?: IconName;
  iconAlign?: Alignment;
  placement?: ButtonPlacement;
  className?: string;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  loginType?: string;
}

function SSOLoginComponent(props: ButtonComponentProps) {
  const application = useSelector(getCurrentApplication);
  const button = <SSOBaseButton {...props} />;
  if (props.isDisabled === true) {
    return button;
  }
  const iframed = window.self !== window.top;
  let domain = window.location.origin;
  let embedded = false;
  let redirect = "";
  const state = store.getState();
  const externalUrl = state?.embedProps?.externalUrl;
  if (externalUrl != null && externalUrl != "") {
    domain = externalUrl;
    embedded = true;
  }
  if (embedded || iframed) {
    redirect = `?sso_redirect=${encodeURIComponent(domain + "/user/sso_callback")}`;
  }
  const url = `${domain}/api/v1/zuora/users/sso_login/${props.loginType}/${application?.id}${redirect}`;
  const handleSso = () => {
    if (embedded || iframed) {
      window.open(url, "ssoLogin", "width=600,height=600");
    }
    window.parent.postMessage(url, "*", undefined);
  };
  const btnWrapper = (
    <a
      href={embedded || iframed ? undefined : url}
      onClick={handleSso}
      style={{ textDecoration: "none" }}
    >
      {button}
    </a>
  );
  return btnWrapper;
}

function SSOBaseButton(props: ButtonComponentProps) {
  return (
    <BaseButton
      borderRadius={props.borderRadius}
      boxShadow={props.boxShadow}
      boxShadowColor={props.boxShadowColor}
      buttonColor={props.buttonColor}
      buttonVariant={props.buttonVariant}
      disabled={props.isDisabled}
      icon={props.icon}
      iconAlign={props.iconAlign}
      iconName={props.iconName}
      loading={props.isLoading}
      maxWidth={props.maxWidth}
      minHeight={props.minHeight}
      minWidth={props.minWidth}
      placement={props.placement}
      rightIcon={props.rightIcon}
      shouldFitContent={props.shouldFitContent}
      text={props.text}
      type={props.type}
    />
  );
}

export interface SSOLoginComponentProps {}

export default SSOLoginComponent;
