import {
  createMessage,
  TABLE_WIDGET_TOTAL_RECORD_TOOLTIP,
} from "ee/constants/messages";
import type { PropertyPaneConfig } from "constants/PropertyControlConstants";
import { ValidationTypes } from "constants/WidgetValidation";
import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";
import {
  InlineEditingSaveOptions,
  type ZTableWidgetProps,
} from "widgets/ZTableWidget/constants";
import { composePropertyUpdateHook } from "widgets/WidgetUtils";
import {
  tableDataValidation,
  totalRecordsCountValidation,
  uniqueColumnNameValidation,
  updateColumnOrderHook,
  updateCustomColumnAliasOnLabelChange,
  updateInlineEditingOptionDropdownVisibilityHook,
  updateInlineEditingSaveOptionHook,
} from "../propertyUtils";
import panelConfig from "./PanelConfig";
import {
  hiddenForMenuActionOnly,
  hiddenNotMenuActionDynamic,
  hiddenNotMenuActionStatic,
  updateMenuItemsSource,
} from "../../../PageHeaderWidget/widget/propertyConfig/propertyUtils";
import { MenuItemsSource } from "../../../PageHeaderWidget/constants";
import { sourceDataArrayValidation } from "../../validation";
import configureMenuItemsConfig from "../../../PageHeaderWidget/widget/propertyConfig/childPanels/configureMenuItemsConfig";
import { ButtonVariantTypes } from "../../../ZButtonWidget/constants";
import { EvaluationSubstitutionType } from "ee/entities/DataTree/types";

export default [
  {
    sectionName: "Data",
    children: [
      {
        helpText:
          "Takes in an array of objects to display rows in the table. Bind data from an API using {{}}",
        propertyName: "tableData",
        label: "Table data",
        controlType: "ONE_CLICK_BINDING_CONTROL",
        controlConfig: {
          searchableColumn: true,
        },
        placeholderText: '[{ "name": "John" }]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        isJSConvertible: true,
        validation: {
          type: ValidationTypes.FUNCTION,
          params: {
            fn: tableDataValidation,
            expected: {
              type: "Array",
              example: `[{ "name": "John" }]`,
              autocompleteDataType: AutocompleteDataType.ARRAY,
            },
          },
        },
        evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
        shouldSwitchToNormalMode: (
          isDynamic: boolean,
          isToggleDisabled: boolean,
          triggerFlag?: boolean,
        ) => triggerFlag && isDynamic && !isToggleDisabled,
      },
      {
        propertyName: "primaryColumns",
        controlType: "PRIMARY_COLUMNS_V2",
        label: "Columns",
        updateHook: composePropertyUpdateHook([
          updateColumnOrderHook,
          updateInlineEditingOptionDropdownVisibilityHook,
          updateCustomColumnAliasOnLabelChange,
        ]),
        dependencies: [
          "primaryColumns",
          "columnOrder",
          "childStylesheet",
          "inlineEditingSaveOption",
          "textColor",
          "textSize",
          "fontStyle",
          "cellBackground",
          "verticalAlignment",
          "horizontalAlignment",
        ],
        isBindProperty: false,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.FUNCTION,
          params: {
            fn: uniqueColumnNameValidation,
            expected: {
              type: "Unique column names",
              example: "abc",
              autocompleteDataType: AutocompleteDataType.STRING,
            },
          },
        },
        panelConfig,
      },
      {
        propertyName: "inlineEditingSaveOption",
        helpText: "Choose the save experience to save the edited cell",
        label: "Update mode",
        controlType: "ICON_TABS",
        defaultValue: InlineEditingSaveOptions.ROW_LEVEL,
        fullWidth: true,
        isBindProperty: true,
        isTriggerProperty: false,
        hidden: (props: ZTableWidgetProps) => {
          return (
            !props.showInlineEditingOptionDropdown &&
            !Object.values(props.primaryColumns).find(
              (column) => column.isEditable,
            )
          );
        },
        dependencies: [
          "primaryColumns",
          "columnOrder",
          "childStylesheet",
          "showInlineEditingOptionDropdown",
        ],
        options: [
          {
            label: "Single Row",
            value: InlineEditingSaveOptions.ROW_LEVEL,
          },
          {
            label: "Multi Row",
            value: InlineEditingSaveOptions.CUSTOM,
          },
        ],
        updateHook: updateInlineEditingSaveOptionHook,
      },
      {
        helpText:
          "Assigns a unique column which helps maintain selectedRows and triggeredRows based on value",
        propertyName: "primaryColumnId",
        dependencies: ["primaryColumns"],
        label: "Primary key column",
        controlType: "PRIMARY_COLUMNS_DROPDOWN",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
    // Added this prop to indicate that data section needs to be expanded by default
    // Rest all sections needs to be collapsed
    // We already have a isDefaultOpen prop configured to keep a section expanded or not
    // but introducing new prop so that we can control is based on flag
    // Once we decide to keep this feature, we can go back to using isDefaultOpen and removeexpandedByDefault
    expandedByDefault: true,
  },
  {
    sectionName: "Table Actions",
    children: [
      {
        propertyName: "tableActions",
        label: "Header Action",
        controlType: "HEADER_ACTION",
        dependencies: ["childStylesheet"],
        isBindProperty: false,
        isTriggerProperty: false,
        panelConfig: {
          editableTitle: true,
          titlePropertyName: "label",
          panelIdPropertyName: "id",
          updateHook: (
            props: any,
            propertyPath: string,
            propertyValue: string,
          ) => {
            return [
              {
                propertyPath,
                propertyValue,
              },
            ];
          },
          contentChildren: [
            {
              sectionName: "Data",
              children: [
                {
                  propertyName: "actionType",
                  label: "Action type",
                  controlType: "DROP_DOWN",
                  fullWidth: true,
                  options: [
                    {
                      label: "Button",
                      value: "BUTTON",
                    },
                    {
                      label: "Menu",
                      value: "MENU",
                    },
                  ],
                  defaultValue: "BUTTON",
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: {
                    type: ValidationTypes.TEXT,
                    params: {
                      allowedValues: ["BUTTON", "MENU"],
                    },
                  },
                },
                {
                  propertyName: "menuItemsSource",
                  helpText: "Sets the source for the menu items",
                  label: "Menu items source",
                  controlType: "ICON_TABS",
                  defaultValue: MenuItemsSource.STATIC,
                  fullWidth: true,
                  options: [
                    {
                      label: "Static",
                      value: MenuItemsSource.STATIC,
                    },
                    {
                      label: "Dynamic",
                      value: MenuItemsSource.DYNAMIC,
                    },
                  ],
                  isJSConvertible: false,
                  isBindProperty: false,
                  isTriggerProperty: false,
                  validation: { type: ValidationTypes.TEXT },
                  updateHook: updateMenuItemsSource,
                  dependencies: [
                    "sourceData",
                    "configureMenuItems",
                    "headerActions",
                  ],
                  hidden: hiddenForMenuActionOnly,
                },
                {
                  helpText:
                    "Takes in an array of items to display the menu items.",
                  propertyName: "sourceData",
                  // updateHook: (
                  //   props: any,
                  //   propertyPath: string,
                  //   propertyValue: string,
                  // ) => {
                  //   return [
                  //     {
                  //       propertyPath,
                  //       propertyValue,
                  //     },
                  //   ];
                  // },
                  label: "Source data",
                  controlType: "TABLE_COMPUTE_VALUE",
                  placeholderText: "{{Query1.data}}",
                  inputType: "ARRAY",
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: {
                    type: ValidationTypes.FUNCTION,
                    params: {
                      fn: sourceDataArrayValidation,
                      expected: {
                        type: "Array of values",
                        example: `['option1', 'option2'] | [{ "label": "label1", "value": "value1" }]`,
                        autocompleteDataType: AutocompleteDataType.ARRAY,
                      },
                    },
                  },
                  evaluationSubstitutionType:
                    EvaluationSubstitutionType.SMART_SUBSTITUTE,
                  hidden: hiddenNotMenuActionDynamic,
                  dependencies: ["menuItemsSource"],
                },
                {
                  helpText: "Configure how each menu item will appear.",
                  propertyName: "configureMenuItems",
                  controlType: "OPEN_CONFIG_PANEL",
                  buttonConfig: {
                    label: "Configure",
                    icon: "settings-2-line",
                  },
                  label: "Configure menu items",
                  isBindProperty: false,
                  isTriggerProperty: false,
                  hidden: hiddenNotMenuActionDynamic,
                  dependencies: ["menuItemsSource", "sourceData"],
                  panelConfig: configureMenuItemsConfig,
                },
                {
                  hidden: hiddenNotMenuActionStatic,
                  dependencies: ["actionType", "menuItemsSource"],
                  helpText: "Menu items",
                  propertyName: "menuItems",
                  controlType: "MENU_ITEMS",
                  label: "Menu items",
                  isBindProperty: false,
                  isTriggerProperty: false,
                  panelConfig: {
                    editableTitle: true,
                    titlePropertyName: "label",
                    panelIdPropertyName: "id",
                    updateHook: (
                      props: any,
                      propertyPath: string,
                      propertyValue: string,
                    ) => {
                      return [
                        {
                          propertyPath,
                          propertyValue,
                        },
                      ];
                    },
                    contentChildren: [
                      {
                        sectionName: "Label",
                        children: [
                          {
                            propertyName: "label",
                            helpText: "Sets the label of a menu item",
                            label: "Text",
                            controlType: "INPUT_TEXT",
                            placeholderText: "Enter label",
                            isBindProperty: true,
                            isTriggerProperty: false,
                            validation: { type: ValidationTypes.TEXT },
                          },
                        ],
                      },
                      {
                        sectionName: "General",
                        children: [
                          // {
                          //   propertyName: "href",
                          //   label: "href",
                          //   controlType: "INPUT_TEXT",
                          //   isBindProperty: true,
                          //   isTriggerProperty: false,
                          //   validation: { type: ValidationTypes.TEXT },
                          // },
                          {
                            propertyName: "isVisible",
                            helpText: "Controls the visibility of menu item",
                            label: "Visible",
                            controlType: "SWITCH",
                            isJSConvertible: true,
                            isBindProperty: true,
                            isTriggerProperty: false,
                            validation: {
                              type: ValidationTypes.BOOLEAN,
                            },
                          },
                          {
                            propertyName: "isDisabled",
                            helpText: "Disables menu item",
                            label: "Disabled",
                            controlType: "SWITCH",
                            isJSConvertible: true,
                            isBindProperty: true,
                            isTriggerProperty: false,
                            validation: {
                              type: ValidationTypes.BOOLEAN,
                            },
                          },
                          {
                            propertyName: "divider",
                            helpText:
                              "Divider with next button in menu dropdown",
                            label: "Divider",
                            controlType: "SWITCH",
                            isJSConvertible: true,
                            isBindProperty: true,
                            isTriggerProperty: false,
                            validation: {
                              type: ValidationTypes.BOOLEAN,
                            },
                          },
                        ],
                      },
                      {
                        sectionName: "Events",
                        children: [
                          {
                            helpText: "when the menu item is clicked",
                            propertyName: "onClick",
                            label: "onClick",
                            controlType: "ACTION_SELECTOR",
                            isJSConvertible: true,
                            isBindProperty: true,
                            isTriggerProperty: true,
                          },
                        ],
                      },
                    ],
                    styleChildren: [
                      {
                        sectionName: "Icon",
                        children: [
                          {
                            propertyName: "icon",
                            label: "Icon",
                            controlType: "ZICON_SELECT",
                            isJSConvertible: true,
                            isBindProperty: true,
                            isTriggerProperty: false,
                            validation: { type: ValidationTypes.TEXT },
                          },
                          {
                            propertyName: "startIcon",
                            label: "StartIcon",
                            controlType: "ZICON_SELECT",
                            isJSConvertible: true,
                            isBindProperty: true,
                            isTriggerProperty: false,
                            validation: { type: ValidationTypes.TEXT },
                          },
                          {
                            propertyName: "endIcon",
                            label: "EndIcon",
                            controlType: "ZICON_SELECT",
                            isJSConvertible: true,
                            isBindProperty: true,
                            isTriggerProperty: false,
                            validation: { type: ValidationTypes.TEXT },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
            {
              sectionName: "Label",
              children: [
                {
                  propertyName: "label",
                  helpText: "Sets the label of a menu item",
                  label: "Text",
                  controlType: "INPUT_TEXT",
                  placeholderText: "Enter label",
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: { type: ValidationTypes.TEXT },
                },
                {
                  propertyName: "icon",
                  label: "Icon",
                  controlType: "ZICON_SELECT",
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: { type: ValidationTypes.TEXT },
                },
                {
                  propertyName: "startIcon",
                  label: "StartIcon",
                  controlType: "ZICON_SELECT",
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: { type: ValidationTypes.TEXT },
                },
                {
                  propertyName: "endIcon",
                  label: "EndIcon",
                  controlType: "ZICON_SELECT",
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: { type: ValidationTypes.TEXT },
                },
                {
                  helpText: "Show help text or details about current action",
                  propertyName: "tooltip",
                  label: "Tooltip",
                  controlType: "INPUT_TEXT",
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: { type: ValidationTypes.TEXT },
                },
              ],
            },
            {
              sectionName: "General",
              children: [
                {
                  propertyName: "isVisible",
                  helpText: "Controls the visibility of the widget",
                  label: "Visible",
                  controlType: "SWITCH",
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: { type: ValidationTypes.BOOLEAN },
                },
                {
                  propertyName: "isDisabled",
                  helpText: "Disables input to the widget",
                  label: "Disabled",
                  controlType: "SWITCH",
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: { type: ValidationTypes.BOOLEAN },
                },
                {
                  propertyName: "divider",
                  helpText: "Divider with next button in menu dropdown",
                  label: "Divider",
                  controlType: "SWITCH",
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: {
                    type: ValidationTypes.BOOLEAN,
                  },
                },
              ],
            },
            {
              sectionName: "Events",
              children: [
                {
                  propertyName: "onClick",
                  label: "onClick",
                  controlType: "ACTION_SELECTOR",
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: true,
                  dependencies: ["actionType"],
                },
              ],
            },
          ],
          styleChildren: [
            {
              sectionName: "Variant",
              children: [
                {
                  propertyName: "variant",
                  dependencies: ["actionType"],
                  label: "Variant",
                  controlType: "ICON_TABS",
                  defaultValue: ButtonVariantTypes.CONTAINED,
                  fullWidth: true,
                  helpText: "Sets the variant of the button",
                  options: [
                    {
                      label: "Contained",
                      value: ButtonVariantTypes.CONTAINED,
                    },
                    {
                      label: "Tertiary",
                      value: ButtonVariantTypes.TERTIARY,
                    },
                    {
                      label: "Text",
                      value: ButtonVariantTypes.TEXT,
                    },
                  ],
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: {
                    type: ValidationTypes.TEXT,
                    params: {
                      allowedValues: [
                        ButtonVariantTypes.CONTAINED,
                        // ButtonVariantTypes.OUTLINED,
                        ButtonVariantTypes.TERTIARY,
                        ButtonVariantTypes.TEXT,
                      ],
                      default: ButtonVariantTypes.CONTAINED,
                    },
                  },
                },
              ],
            },
            {
              sectionName: "Color",
              children: [
                {
                  // getStylesheetValue,
                  propertyName: "buttonColor",
                  helpText: "Changes the color of the button",
                  label: "Button color",
                  controlType: "COLOR_PICKER",
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: { type: ValidationTypes.TEXT },
                },
              ],
            },
          ],
        },
      },
      {
        propertyName: "maxTableActionDisplay",
        label: "Max Table Action Display",
        controlType: "INPUT_TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.NUMBER },
      },
    ],
  },
  {
    sectionName: "Pagination",
    children: [
      {
        propertyName: "isVisiblePagination",
        helpText: "Toggle visibility of the pagination",
        label: "Show pagination",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        helpText:
          "Bind the Table.pageNo property in your API and call it onPageChange",
        propertyName: "serverSidePaginationEnabled",
        label: "Server side pagination",
        controlType: "SWITCH",
        isBindProperty: false,
        isTriggerProperty: false,
      },
      {
        helpText: createMessage(TABLE_WIDGET_TOTAL_RECORD_TOOLTIP),
        propertyName: "totalRecordsCount",
        label: "Total Records",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter total record count",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.FUNCTION,
          params: {
            fn: totalRecordsCountValidation,
            expected: {
              type: "Number",
              example: "10",
              autocompleteDataType: AutocompleteDataType.STRING,
            },
          },
        },
        hidden: (props: ZTableWidgetProps) =>
          !props.serverSidePaginationEnabled,
        dependencies: ["serverSidePaginationEnabled"],
      },
      {
        helpText: "when a table page is changed",
        propertyName: "onPageChange",
        label: "onPageChange",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
        hidden: (props: ZTableWidgetProps) =>
          !props.serverSidePaginationEnabled,
        dependencies: ["serverSidePaginationEnabled"],
      },
      {
        helpText: "when a table page size is changed",
        propertyName: "onPageSizeChange",
        label: "onPageSizeChange",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
        hidden: (props: ZTableWidgetProps) =>
          !props.serverSidePaginationEnabled,
        dependencies: ["serverSidePaginationEnabled"],
      },
    ],
    expandedByDefault: false,
  },
  {
    sectionName: "Filters",
    children: [
      {
        propertyName: "enableServerSideFiltering",
        label: "Server side filtering",
        helpText: "Filters all the results on the server side",
        controlType: "SWITCH",
        isBindProperty: false,
        isTriggerProperty: false,
        defaultValue: false,
        // hidden: () =>
        //   !Widget.getFeatureFlag(ALLOW_TABLE_WIDGET_SERVER_SIDE_FILTERING),
      },
      {
        propertyName: "serverSideFilterOptions",
        label: "Server side filtering options",
        controlType: "INPUT_TEXT",
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        hidden: (props: ZTableWidgetProps) => !props.enableServerSideFiltering,
        dependencies: ["enableServerSideFiltering"],
        validation: {
          type: ValidationTypes.FUNCTION,
          params: {
            fn: sourceDataArrayValidation,
            expected: {
              type: "Array of values",
              example: `['filter1', 'filter2']`,
              autocompleteDataType: AutocompleteDataType.ARRAY,
            },
          },
        },
        evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
      },
      {
        propertyName: "onTableFilterUpdate",
        label: "onTableFilterUpdate",
        helpText: "when table filter is modified by the user",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
        hidden: (props: ZTableWidgetProps) => !props.enableServerSideFiltering,
        dependencies: ["enableServerSideFiltering"],
      },
      {
        propertyName: "isVisibleFilters",
        helpText: "Toggle visibility of the filters",
        label: "Allow filtering",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
    ],
    expandedByDefault: false,
  },
  {
    sectionName: "Search",
    children: [
      {
        propertyName: "isVisibleSearch",
        helpText: "Toggle visibility of the search box",
        label: "Allow searching",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "enableClientSideSearch",
        label: "Client side search",
        helpText: "Searches all results only on the data which is loaded",
        controlType: "SWITCH",
        isBindProperty: false,
        isTriggerProperty: false,
        hidden: (props: ZTableWidgetProps) => !props.isVisibleSearch,
        dependencies: ["isVisibleSearch"],
      },
      {
        propertyName: "serverSideSearchOptions",
        label: "Server side search options",
        controlType: "INPUT_TEXT",
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        hidden: (props: ZTableWidgetProps) => !props.isVisibleSearch,
        dependencies: ["isVisibleSearch"],
        validation: {
          type: ValidationTypes.FUNCTION,
          params: {
            fn: sourceDataArrayValidation,
            expected: {
              type: "Array of values",
              example: `['filter1', 'filter2']`,
              autocompleteDataType: AutocompleteDataType.ARRAY,
            },
          },
        },
        evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
      },
      {
        propertyName: "defaultSearchText",
        label: "Default search text",
        helpText: "Adds a search text by default",
        controlType: "INPUT_TEXT",
        placeholderText: "{{appsmith.user.name}}",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (props: ZTableWidgetProps) => !props.isVisibleSearch,
        dependencies: ["isVisibleSearch"],
      },
      {
        propertyName: "onSearchTextChanged",
        label: "onSearchTextChanged",
        helpText: "when search text is modified by the user",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
        hidden: (props: ZTableWidgetProps) => !props.isVisibleSearch,
        dependencies: ["isVisibleSearch"],
      },
    ],
    expandedByDefault: false,
  },
  {
    sectionName: "Row selection",
    children: [
      {
        helpText: "Selects row(s) by default",
        propertyName: "defaultSelectedRowIndices",
        label: "Default selected rows",
        controlType: "INPUT_TEXT",
        placeholderText: "[0]",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY,
          params: {
            children: {
              type: ValidationTypes.NUMBER,
              params: {
                min: -1,
                default: -1,
              },
            },
          },
        },
        hidden: (props: ZTableWidgetProps) => {
          return !props.multiRowSelection;
        },
        dependencies: ["multiRowSelection"],
      },
      {
        helpText: "Selects row by default",
        propertyName: "defaultSelectedRowIndex",
        label: "Default selected row",
        controlType: "INPUT_TEXT",
        defaultValue: 0,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.NUMBER,
          params: {
            min: -1,
            default: -1,
          },
        },
        hidden: (props: ZTableWidgetProps) => {
          return props.multiRowSelection;
        },
        dependencies: ["multiRowSelection"],
      },
      {
        propertyName: "multiRowSelection",
        label: "Enable multi-row selection",
        helpText: "Allows users to select multiple rows",
        controlType: "SWITCH",
        isBindProperty: false,
        isTriggerProperty: false,
      },
      {
        helpText: "when a table row is selected",
        propertyName: "onRowSelected",
        label: "onRowSelected",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
    ],
    expandedByDefault: false,
  },
  {
    sectionName: "Sorting",
    children: [
      {
        helpText: "Controls sorting in View Mode",
        propertyName: "isSortable",
        isJSConvertible: true,
        label: "Column sorting",
        controlType: "SWITCH",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.BOOLEAN,
          params: {
            default: true,
          },
        },
      },
      {
        helpText: "when a table column is sorted",
        propertyName: "onSort",
        label: "onSort",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
        hidden: (props: ZTableWidgetProps) => !props.isSortable,
        dependencies: ["isSortable"],
      },
    ],
    expandedByDefault: false,
  },
  {
    sectionName: "Adding a row",
    children: [
      {
        propertyName: "allowAddNewRow",
        helpText: "Enables adding a new row",
        isJSConvertible: true,
        label: "Allow adding a row",
        controlType: "SWITCH",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.BOOLEAN,
        },
      },
      {
        propertyName: "onAddNewRowSave",
        helpText: "when a add new row save button is clicked",
        label: "onSave",
        controlType: "ACTION_SELECTOR",
        hidden: (props: ZTableWidgetProps) => {
          return !props.allowAddNewRow;
        },
        dependencies: ["allowAddNewRow", "primaryColumns"],
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
        additionalAutoComplete: (props: ZTableWidgetProps) => {
          const newRow: Record<string, unknown> = {};

          if (props.primaryColumns) {
            Object.values(props.primaryColumns)
              .filter((column) => !column.isDerived)
              .forEach((column) => {
                newRow[column.alias] = "";
              });
          }

          return {
            newRow,
          };
        },
      },
      {
        propertyName: "onAddNewRowDiscard",
        helpText: "when a add new row discard button is clicked",
        label: "onDiscard",
        controlType: "ACTION_SELECTOR",
        hidden: (props: ZTableWidgetProps) => {
          return !props.allowAddNewRow;
        },
        dependencies: ["allowAddNewRow"],
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
      {
        propertyName: "defaultNewRow",
        helpText: "Default new row values",
        label: "Default values",
        controlType: "INPUT_TEXT",
        dependencies: ["allowAddNewRow"],
        hidden: (props: ZTableWidgetProps) => {
          return !props.allowAddNewRow;
        },
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.OBJECT,
          params: {
            default: {},
          },
        },
      },
    ],
    expandedByDefault: false,
  },
  {
    sectionName: "General",
    children: [
      {
        helpText: "Controls the visibility of the widget",
        propertyName: "isVisible",
        isJSConvertible: true,
        label: "Visible",
        controlType: "SWITCH",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.BOOLEAN,
        },
      },
      {
        propertyName: "animateLoading",
        label: "Animate loading",
        controlType: "SWITCH",
        helpText: "Controls the loading of the widget",
        defaultValue: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "allowColumnCustomisation",
        helpText: "Toggle visibility of column Customisation",
        label: "Allow Column Customisation",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "canFreezeColumn",
        helpText: "Controls whether the user can freeze columns",
        label: "Allow column freeze",
        controlType: "SWITCH",
        defaultValue: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "delimiter",
        label: "CSV separator",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter CSV separator",
        helpText: "The character used for separating the CSV download file.",
        isBindProperty: true,
        isTriggerProperty: false,
        defaultValue: ",",
        validation: {
          type: ValidationTypes.TEXT,
        },
        hidden: (props: ZTableWidgetProps) => !props.isVisibleDownload,
        dependencies: ["isVisibleDownload"],
      },
    ],
    expandedByDefault: false,
  },
] as PropertyPaneConfig[];
