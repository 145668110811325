import { Box, Chip, Stack } from "@mui/material";
import React, { useMemo, useState } from "react";
import {
  Card,
  Grid,
  Icon,
  Link,
  Pagination,
  Table,
  Typography,
  type ColumnType,
} from "zds";
export const BLANK_VALUE = "--";
export const EMPTY_STRING = "";
export const DEFAULT_ROW_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100, 150, 200];
interface AccountInsightsProps {
  oppRows: any;
  oppTotalElements: any;
  oppLoading: boolean;
  tableType: "opp" | "risk";
  onTableLoaded: any;
  oppCountriesList: any;
}

const AccountInsights = (props: AccountInsightsProps) => {
  const {
    onTableLoaded,
    oppCountriesList,
    oppLoading,
    oppRows,
    oppTotalElements,
    tableType,
  } = props;
  // const [oppRows, setOppRows] = useState(riskOppData ? [...riskOppData] : []);
  const [oppPage, setOppPage] = useState(0);
  const [oppRowsPerPage, setOppRowsPerPage] = useState(10);
  //const [oppTotalElements, setOppTotalElements] = useState(0);
  const [oppFilterConditions, setOppFilterConditions] = useState({});
  const [oppSortCondition, setOppSortCondition] = React.useState([
    {
      field: "score",
      order: "DESC",
    },
  ]);
  // THE API CALL GOES HERE
  //const [oppCountriesList, setOppCountriesList] = useState([]);

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const oppHandlePageChange = async (
    _: any,
    newPage: React.SetStateAction<number>,
  ) => {
    // BELOW LINE NOT REQUIRED
    setOppPage(newPage);
    onTableLoaded(tableType, newPage, oppRowsPerPage, oppFilterConditions);
  };

  const oppRelationshipQueryCallback = async (searchString: string) => {
    const basis: { label: string; value: string }[] = oppCountriesList.map(
      (t: any) => ({
        value: t,
        label: `${t}`,
      }),
    );
    return Promise.resolve(
      basis.filter(({ label }) =>
        label.toLowerCase().includes(searchString.toLowerCase()),
      ),
    );
  };

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const oppFilterHandler = (filterObject: any) => {
    setOppFilterConditions(filterObject);
    onTableLoaded(tableType, oppPage, oppRowsPerPage, filterObject);
  };

  const oppSortHandler = (
    sortObject: React.SetStateAction<{ field: string; order: string }[]>,
  ) => {
    setOppSortCondition(sortObject);
  };

  const oppHandleRowsPerPageChange = async (e: any) => {
    setOppRowsPerPage(e.target.value);
    onTableLoaded(tableType, oppPage, oppRowsPerPage, oppFilterConditions);
  };

  const oppFilterableFields = useMemo(() => {
    return [
      { name: "customer_name", label: "Customer Name", type: "STRING" },
      {
        name: "country",
        label: "country",
        type: "STRING",
        relationshipQueryCallback: oppRelationshipQueryCallback,
      },
      { name: "revenue", label: "revenue", type: "DECIMAL" },
    ];
  }, [oppCountriesList]);
  const OPP_COLUMNS: ColumnType[] = [
    {
      field: "account_name",
      label: "Customer Name",
      sortable: false,
      dsRenderCell: ({ row }) => {
        return (
          <Link
            dsOnClick={() => {
              //handleCustomerNameClick(row);
            }}
          >
            {row.account_name || BLANK_VALUE}
          </Link>
        );
      },
    },
    {
      field: "country",
      label: "Country",
      sortable: false,
      dsRenderCell: ({ value }) => {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{value || BLANK_VALUE}</>;
      },
    },
    {
      field: "score",
      label: "Growth Score",
      sortable: true,
      dsRenderCell: ({ value }) => (
        <Chip
          label={value}
          sx={{ bgcolor: "#E1F3E6", color: "green", borderRadius: "4px" }}
        />
      ),
    },
    {
      field: "actions",
      label: "Actions",
      sortable: false,
      dsRenderCell: ({}) => {
        return (
          <Link
            dsOnClick={() => {
              //handleCustomerNameClick(row);
            }}
          >
            View
          </Link>
        );
      },
    },
  ];

  const RISK_COLUMNS: ColumnType[] = [
    {
      field: "account_name",
      label: "Customer Name",
      sortable: false,
      dsRenderCell: ({ row }) => {
        return (
          <Link
            dsOnClick={() => {
              //handleCustomerNameClick(row);
            }}
          >
            {row.account_name || BLANK_VALUE}
          </Link>
        );
      },
    },
    {
      field: "country",
      label: "Country",
      sortable: false,
      dsRenderCell: ({ value }) => {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{value || BLANK_VALUE}</>;
      },
    },
    {
      field: "revenue",
      label: "Billable Usage",
      sortable: false,
      dsRenderCell: ({ value }) => (
        <span>{value?.toString() || BLANK_VALUE}</span>
      ),
    },
    {
      field: "score",
      label: "Risk Score",
      sortable: true,
      dsRenderCell: ({ value }) => (
        <Chip
          label={value}
          sx={{ bgcolor: "#FFE9E5", color: "red", borderRadius: "4px" }}
        />
      ),
    },
    {
      field: "actions",
      label: "Actions",
      sortable: false,
      dsRenderCell: ({}) => {
        return (
          <Link
            dsOnClick={() => {
              //handleCustomerNameClick(row);
            }}
          >
            View
          </Link>
        );
      },
    },
  ];
  return (
    <>
      <Card
        body={
          <Stack spacing={2}>
            <Typography
              sx={{ paddingLeft: "10px", marginTop: "-30px" }}
              variant="caption"
            >
              Scores based on the last 12 months
            </Typography>
            <Table
              columns={tableType === "opp" ? OPP_COLUMNS : RISK_COLUMNS}
              defaultFields={["customer_name", "country", "revenue"]}
              dsOnFilter={oppFilterHandler}
              dsOnSort={oppSortHandler}
              enableFilterBarSearch={false}
              enableLegacyFilters={false}
              filterBarOptions={{
                disableFilterBarAdd: true,
                disableAddingFilteredFields: true,
                disableSearchOperator: true,
                disableSearchExclusionBtn: true,
              }}
              filterableFields={oppFilterableFields}
              hidePagination
              initialSort={oppSortCondition}
              loading={oppLoading}
              rows={oppRows}
              showNoRowsMessage={oppRows.length === 0}
              sortStrategy="server"
              sortable
              uniqueKey="accounts-with-expansion-opportunities-table"
            />
            <Grid container justify="flex-end" xs={12}>
              <Grid item xs={6} />
              <Grid item justify="flex-end" sx={{ display: "flex" }} xs={6}>
                <Pagination
                  a11yLabel={"Opportunity score pagination"}
                  count={oppTotalElements}
                  dsOnPageChange={oppHandlePageChange}
                  dsOnRowsPerPageChange={oppHandleRowsPerPageChange}
                  page={oppPage}
                  rowsPerPage={oppRowsPerPage}
                  rowsPerPageOptions={DEFAULT_ROW_PER_PAGE_OPTIONS}
                />
              </Grid>
            </Grid>
          </Stack>
        }
        header={
          <Stack direction="row">
            <Typography variant="h6">
              {tableType === "opp"
                ? "Accounts with Expansion Opportunities"
                : "Accounts at Risk"}
            </Typography>
            <Box
              alignItems="center"
              display="flex"
              sx={{
                marginLeft: "4px",
                marginTop: "2px",
                fontSize: "18px",
              }}
            >
              <Icon body="info" fontSize="inherit" />
            </Box>
          </Stack>
        }
        id="opp-risk-tables-card"
        titleBar
      />
    </>
  );
};

export default AccountInsights;
