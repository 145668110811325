import React from "react";

const NoDataSourceBanner = () => {
  return (
    <div
      style={{
        backgroundColor: "#fdecea",
        border: "1px solid #f5c6cb",
        color: "#721c24",
        padding: "16px",
        borderRadius: "4px",
        position: "relative",
        fontFamily: "Arial, sans-serif",
      }}
      role="alert"
    >
      <strong style={{ fontWeight: "bold" }}>
        No Data Source named "Zuora Default Store"
      </strong>
      <p style={{ marginTop: "8px" }}>To set it up, follow these steps:</p>
      <ol style={{ marginTop: "8px", paddingLeft: "20px" }}>
        <li>
          <a
            href={`https://${window.location.hostname}/apps/UserLogin.do?method=list`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#007bff", textDecoration: "underline" }}
          >
            Click here
          </a>{" "}
          to generate Client ID and Secret.
        </li>
        <li>
          Click the Data icon on the left pane and create a new Data Source
          named <strong>"Zuora Default Store"</strong> using the credentials
          generated above.
        </li>
      </ol>
    </div>
  );
};

export default NoDataSourceBanner;
