import type { ControllerRenderProps } from "react-hook-form/dist/types/controller";

import type { InputType } from "widgets/InputWidget/constants";
import {
  CheckboxField,
  DateField,
  SelectField,
  HyperlinkField,
  ObjectField,
  TextField,
  ZuoraObjectField,
} from "./fields";
import type { Color } from "../../constants/Colors";
import moment from "moment";
import ChipField from "./fields/ChipField";

// CAUTION! When changing the enum value, make sure any direct comparison
// e.g. fieldType === "Array" instead of fieldType === FieldType.ARRAY is taking place
// and modified accordingly
export enum FieldType {
  CHECKBOX = "Checkbox",
  DATE = "Date",
  HYPERLINK = "Hyperlink",
  OBJECT = "Object",
  SELECT = "Select",
  TEXT_FIELD = "Text Field",
  ZUORA_OBJECT = "Zuora Object",
  CHIP = "Chip",
}

export type FieldTypeKey = keyof typeof FieldType;

export const inverseFieldType = Object.entries(FieldType).reduce<
  Record<FieldType, FieldTypeKey>
>(
  (previousValue, currentValue) => {
    const [key, value] = currentValue;
    previousValue[value] = key as FieldTypeKey;
    return previousValue;
  },
  {} as Record<FieldType, FieldTypeKey>,
);

export enum DataType {
  STRING = "string",
  NUMBER = "number",
  ARRAY = "array",
  BOOLEAN = "boolean",
  OBJECT = "object",
  BIGINT = "bigint",
  SYMBOL = "symbol",
  UNDEFINED = "undefined",
  NULL = "null",
  FUNCTION = "function",
}

export type Obj = Record<string, any>;
export type JSON = Obj | Obj[];

export interface FieldComponentBaseProps {
  defaultValue?: string | number;
  displayValue?: string | number;
  isDisabled: boolean;
  shouldAllowAutofill?: boolean;
  isRequired?: boolean;
  isVisible: boolean;
  label: string;
  labelStyle?: string;
  labelTextColor?: string;
  labelTextSize?: string;
  tooltip?: string;
  isChecked: boolean;
  inlineLabel?: boolean;
  valueStyle?: string;
  valueTextColor?: string;
  valueTextSize?: string;
  labelAlignment?: "left" | "right";
  labelWidth?: number;
  useDefaultStyles?: boolean;
  linesDisplayed?: number;
}

export interface FieldEventProps {
  onFocus?: string;
  onBlur?: string;
}

export interface BaseFieldComponentProps<TProps = any> {
  hideLabel?: boolean;
  isRootField?: boolean;
  fieldClassName: string;
  name: ControllerRenderProps["name"];
  propertyPath: string;
  passedDefaultValue?: unknown;
  schemaItem: SchemaItem & TProps;
  itemInnerMargin?: number;
  maxColumns?: number;
  labelTextColorParent?: Color;
  labelTextSizeParent?: string;
  labelStyleParent?: string;
  inlineLabelParent?: boolean;
  labelAlignmentParent?: "left" | "right";
  labelWidthParent?: number;
}

export type Schema = Record<string, SchemaItem>;

/**
 * dataType - result of "typeof value" -> string/number/boolean etc.
 * fieldType - the field component that this represents -> Text/Switch/Email etc.
 * sourceData - the data that is used to compute initial dataType and fieldType.
 * isCustomField - this is set to true only for fields created using the "Add new field" in Property Pane
 * name - this is a sanitized value used to identify a field uniquely -> firstName, age etc.
 * position - a number from 0..n specifying the order in a form.
 * originalIdentifier - This is derived from the sourceData key, in its unsanitized form.
 *    It is used as a marker to identify this field in the sourceData to detect any change. As the actual
 *    identifier used can be modified during sanitization process.
 * identifier - This is derived from the sourceData key, in it's sanitized form. This acts as a marker
 *    in the schema and helps to identify from nested property changes.
 * accessor - This is very similar to the name property. This is directly exposed in the property pane to be
 *    modified at free will. It acts as a staging state for the name, as name cannot have invalid values. So
 *    when accessor is updated, it checks if this value can be used as name and then updates it, else keeps the
 *    name property intact for data sanity.
 */
export type SchemaItem = FieldComponentBaseProps & {
  accessor: string;
  children: Schema;
  dataType: DataType;
  enableNullValues: boolean;
  toggleBooleans: boolean;
  fieldType: FieldType;
  identifier: string;
  isCustomField: boolean;
  originalIdentifier: string;
  position: number;
  sourceData: any;
  footerRef?: string;
  footerDisplay?: string;
  columnSpan: number;
};

export interface ComponentDefaultValuesFnProps<TSourceData = any> {
  sourceDataPath?: string;
  fieldType: FieldType;
  bindingTemplate: {
    suffixTemplate: string;
    prefixTemplate: string;
  };
  isCustomField: boolean;
  sourceData: TSourceData;
  skipDefaultValueProcessing: boolean;
  columnSpan: number;
}

// This defines a react component with componentDefaultValues property attached to it.
export interface FieldComponent {
  (props: BaseFieldComponentProps): JSX.Element | null;
  componentDefaultValues?:
    | FieldComponentBaseProps
    | ((props: ComponentDefaultValuesFnProps) => FieldComponentBaseProps);
  isValidType?: (value: any, options?: any) => boolean;
}

export type FieldState<TObj> =
  | {
      [k: string]: TObj | TObj[] | FieldState<TObj> | FieldState<TObj>[];
    }
  | FieldState<TObj>[]
  | TObj;

export type HookResponse =
  | Array<{ propertyPath: string; propertyValue: any }>
  | undefined;

export type FieldThemeStylesheet = Record<
  FieldTypeKey,
  { [key: string]: string }
>;

export enum ActionUpdateDependency {
  FORM_DATA = "FORM_DATA",
}

export const ARRAY_ITEM_KEY = "__array_item__";
export const ROOT_SCHEMA_KEY = "__root_schema__";

export const MAX_FIELD_LEVEL = 1;
export const MAX_ALLOWED_FIELDS = 500;

export const RESTRICTED_KEYS = [ARRAY_ITEM_KEY, ROOT_SCHEMA_KEY];

export const FIELD_MAP: Record<FieldType, FieldComponent> = {
  [FieldType.CHECKBOX]: CheckboxField,
  [FieldType.DATE]: DateField,
  [FieldType.HYPERLINK]: HyperlinkField,
  [FieldType.OBJECT]: ObjectField,
  [FieldType.SELECT]: SelectField,
  [FieldType.TEXT_FIELD]: TextField,
  [FieldType.ZUORA_OBJECT]: ZuoraObjectField,
  [FieldType.CHIP]: ChipField,
};
export const INPUT_TYPES = [] as const;

/**
 * This translates FieldType to Input component inputType
 * As InputField would handle all the below types (Text/Number), this map
 * would help use identify what inputType it is based on the FieldType.
 */
export const INPUT_FIELD_TYPE: Record<(typeof INPUT_TYPES)[number], InputType> =
  {};

export const FIELD_EXPECTING_OPTIONS = [FieldType.SELECT];

export const DATA_TYPE_POTENTIAL_FIELD = {
  [DataType.STRING]: FieldType.TEXT_FIELD,
  [DataType.BOOLEAN]: FieldType.TEXT_FIELD,
  [DataType.NUMBER]: FieldType.TEXT_FIELD,
  [DataType.BIGINT]: FieldType.TEXT_FIELD,
  [DataType.SYMBOL]: FieldType.TEXT_FIELD,
  [DataType.UNDEFINED]: FieldType.TEXT_FIELD,
  [DataType.NULL]: FieldType.TEXT_FIELD,
  [DataType.OBJECT]: FieldType.ZUORA_OBJECT,
  [DataType.ARRAY]: FieldType.TEXT_FIELD,
  [DataType.FUNCTION]: FieldType.TEXT_FIELD,
};

// The potential value here is just for representation i.e it won't be used to set default value anywhere.
// This will just help to transform a field type (when modified in custom field) to appropriate schemaItem
// using schemaParser.
export const FIELD_TYPE_TO_POTENTIAL_DATA: Record<FieldType, any> = {
  [FieldType.CHECKBOX]: true,
  [FieldType.DATE]: moment().toISOString(),
  [FieldType.SELECT]: "",
  [FieldType.HYPERLINK]: "",
  [FieldType.OBJECT]: {},
  [FieldType.TEXT_FIELD]: "",
  [FieldType.ZUORA_OBJECT]: {},
  [FieldType.CHIP]: "",
};

export const FIELD_SUPPORTING_FOCUS_EVENTS = [FieldType.CHECKBOX];

// These are the fields who's defaultValue property control's JS
// mode would be enabled by default.
export const AUTO_JS_ENABLED_FIELDS: Record<
  FieldType,
  (keyof SchemaItem)[] | null
> = {
  [FieldType.CHECKBOX]: ["defaultValue"],
  [FieldType.DATE]: ["defaultValue"],
  [FieldType.SELECT]: ["defaultValue"],
  [FieldType.HYPERLINK]: null,
  [FieldType.OBJECT]: null,
  [FieldType.TEXT_FIELD]: null,
  [FieldType.ZUORA_OBJECT]: null,
  [FieldType.CHIP]: null,
};

export const getBindingTemplate = (widgetName: string) => {
  const prefixTemplate = `{{((sourceData, formData, fieldState) => (`;
  const suffixTemplate = `))(${widgetName}.sourceData, ${widgetName}.formData, ${widgetName}.fieldState)}}`;

  return { prefixTemplate, suffixTemplate };
};

export const itemHeight = 45;

export const noOfItemsToDisplay = 4;

// 12px for the (noOfItemsToDisplay+ 1) item to let the user know there are more items to scroll
export const extraSpace = 12;
