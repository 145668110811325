import { getLastDayOfMonth } from "./DateUtil";

export const calculateChange = (oldValue: number, newValue: number): number => {
  if (isNaN(oldValue) || isNaN(newValue)) {
    return Infinity; // Return 0 if either value is not a number
  }

  if (oldValue === 0) {
    if (newValue === 0) {
      // Both oldValue and newValue are zero; no change, so return 0
      return 0.0;
    } else {
      // oldValue is zero but newValue is not; return Infinity or a special value
      return Infinity; // Use `Infinity` for positive infinity
    }
  }

  // Calculate the percentage change
  const change = ((newValue - oldValue) / oldValue) * 100;
  const roundedChange = Math.round(change * 100) / 100; // Round to 2 decimal places

  return roundedChange;
};
const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const formatDate = (date: string) => {
  const year = date.substring(0, 4);
  const month = parseInt(date.substring(4, 6), 10) - 1;
  const day = date.substring(6, 8);
  return { year, monthName: monthNames[month], day, month };
};

export function formatDateRange(startDate: string, endDate: string) {
  const start = formatDate(startDate);
  const end = formatDate(endDate);

  // If both dates are in the same year
  if (start.year === end.year) {
    if (start.monthName === end.monthName) {
      if (start.day === end.day) {
        // Same day: return "Sep 1, 2024"
        return `${start.monthName} ${start.day}, ${start.year}`;
      } else if (
        start.day === "01" &&
        end.day === getLastDayOfMonth(start.year, start.month).toString()
      ) {
        // Spanning the entire month: return "August 2024"
        return `${start.monthName} ${start.year}`;
      } else {
        // Same month but different days: return "Sep 1-30, 2024"
        return `${start.monthName} ${start.day}-${end.day}, ${start.year}`;
      }
    } else {
      // Same year but different months: return "From Sep 1 to Oct 30, 2024"
      return `${start.monthName} ${start.day}-${end.monthName} ${end.day}, ${start.year}`;
    }
  } else {
    // Different years: return "From Sep 2024 to Oct 2025"
    return `${start.monthName} ${start.year}-${end.monthName} ${end.year}`;
  }
}

export function numberWithCommas(x: any, isCurrency = false) {
  if (x === null || isNaN(x)) {
    return "--";
  }

  // If it's a currency, always format with two decimal places
  if (isCurrency) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // For non-currency values: format as an integer if there's no decimal part
  if (Number.isInteger(x)) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // For non-currency values with decimals, return as-is with commas
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const REVENUE_TYPE = {
  MonthToDate: "MonthToDate",
  LastMonthSamePeriod: "LastMonthSamePeriod",
  LastMonthTotal: "LastMonthTotal",
  LastYearTotal: "LastYearTotal",
  Forecast: "Forecast",
};
