import { DURATION_SELECT } from "widgets/ZAnalytics/util/constants";
import {
  getForecastedRevenueSeasonality,
  getPeriodicity,
} from "widgets/ZAnalytics/util/DateUtil";
import { getSeasonality } from "../component/RevenueChart";

export const formatForecastedRevenueData = (
  revenueData: any,
  duration: any,
  isACB = false,
) => {
  const data = revenueData?.data?.revenueByPeriod || revenueData;
  if (data) {
    const periodicity: DURATION_SELECT = getPeriodicity(duration);
    const timestamps: string[] = [];
    const values: number[] = [];

    const isMonthly: boolean = periodicity === DURATION_SELECT.monthly;

    for (const timestamp in data) {
      if (Object.prototype.hasOwnProperty.call(data, timestamp)) {
        const date = new Date(parseInt(timestamp));
        const utcDate = new Date(
          Date.UTC(
            date.getUTCFullYear(),
            isMonthly ? date.getUTCMonth() + 1 : date.getUTCMonth(),
            isMonthly ? 0 : date.getUTCDate(),
          ),
        );

        const formattedDate = `${utcDate.getUTCFullYear()}-${(
          utcDate.getUTCMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${utcDate
          .getUTCDate()
          .toString()
          .padStart(2, "0")}`;
        timestamps.push(formattedDate);
        values.push(data[timestamp]);
      }
    }

    const formattedData = timestamps.map((timestamp, index) => ({
      x: timestamp,
      y: values[index],
    }));

    // Sorting formattedData by timestamp
    formattedData.sort(
      (a, b) => new Date(a.x).getTime() - new Date(b.x).getTime(),
    );

    const sortedTimestamps = formattedData.map((entry) => entry.x);
    const sortedValues = formattedData.map((entry) => entry.y);
    /* Check on this whether the backend doesn't require this removal of last value */
    if (isACB && periodicity !== DURATION_SELECT.daily) {
      sortedTimestamps.pop();
      sortedValues.pop();
    }

    const forecastPayload = {
      timestamp: sortedTimestamps,
      value: sortedValues,
    };

    const periods = Math.round(Object.keys(data).length / 2);

    const seasonality = getSeasonality(
      getForecastedRevenueSeasonality(duration),
    );
    return { forecastPayload, periods, seasonality };
  }
  return {
    forecastPayload: null,
    periods: null,
    seasonality: null,
  };
};
