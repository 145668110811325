import React, { useEffect, useState } from "react";
import { calculateChange, numberWithCommas } from "../../util/cardUtil";
import { getPreviousMonthAndYear } from "../../util/DateUtil";
import { InfoCard } from "./InfoCard";

interface Props {
  previousMonthTotalAccounts: any;
  currentMonthTotalAccounts: any;
}

const TotalAccountsCards = (props: Props) => {
  const { currentMonthTotalAccounts, previousMonthTotalAccounts } = props;

  const [totalAccounts, setTotalAccounts] = useState<any>({});
  useEffect(() => {
    if (previousMonthTotalAccounts && currentMonthTotalAccounts) {
      const change = calculateChange(
        previousMonthTotalAccounts,
        currentMonthTotalAccounts,
      );

      const dateText = "From " + getPreviousMonthAndYear();

      setTotalAccounts({
        value: numberWithCommas(currentMonthTotalAccounts),
        change: change,
        dateText: dateText,
        tooltipText: "Tool tip",
      });
    }
  }, [previousMonthTotalAccounts, currentMonthTotalAccounts]);
  return (
    <div>
      <InfoCard
        changeValue={totalAccounts.change}
        dateText={totalAccounts.dateText}
        title="Total Accounts"
        tooltipText={totalAccounts.tooltipText}
        value={totalAccounts.value}
      />
    </div>
  );
};

export default TotalAccountsCards;
