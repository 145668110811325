import React, { useEffect, useState } from "react";
import { isEqual } from "lodash";
import {
  addCompareToTime,
  calculateDatesForPeriod,
  formatDate,
  getAggregationTypePeriodAndPeriodicity,
  getCompareToDateText,
  getForecastedRevenueSeasonality,
  getLastDurationStr,
  getMonthText,
  getPeriodicity,
  getWeekText,
} from "widgets/ZAnalytics/util/DateUtil";
import type { DURATION_TYPE } from "widgets/ZAnalytics/util/constants";
import {
  COMPARE_TO_TYPE,
  DURATION_SELECT,
} from "widgets/ZAnalytics/util/constants";
import { Select, SelectItem } from "zds";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DurationSelector from "./DurationSelector";
import CompareToSelector from "./CompareToSelector";
import { Card, designTokens, Typography } from "zds";
import { IconButton, Stack, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ChangeIndicator from "widgets/ZAnalytics/ZAnalyticsCard/components/ChangeIndicator";
import { formatForecastedRevenueData } from "../util/forecastRevenue";
import AnalyseTypeAhead from "widgets/ZAnalytics/ZAnanlyticsChart/component/AnalyseTypeAhead";

export function getSeasonality(selectedTimePeriod: string) {
  let seasonality = "";
  switch (selectedTimePeriod) {
    case "DAY":
      seasonality = "D";
      break;
    case "MONTH":
      seasonality = "M";
      break;
    case "WEEK":
      seasonality = "W";
      break;
    case "YEAR":
      seasonality = "Y";
      break;
    default:
      break;
  }
  return seasonality;
}

interface RevenueChartProps {
  revenueData: any;
  duration: DURATION_TYPE | undefined;
  durationType: any;
  onChartLoaded: any;
  fetchCompareToData: any;
  compareToData: any;
  getForecast: any;
  forecastData: any;
  anomalyData: any;
  revenue: any;
  compareTo: any;
  setCompareTo: any;
  setShowForecast: any;
  showForecast: any;
  isACB: boolean | null;
  analyzeFilter: string;
  customerOrProductData: any;
  getAccountsOrProducts: any;
  filterObjects: any;
  revenueTempData: any;
  setRevenueTempData: any;
}

const RevenueChart = (props: RevenueChartProps) => {
  const {
    anomalyData,
    compareTo,
    compareToData,
    duration,
    durationType,
    fetchCompareToData,
    forecastData,
    getForecast,
    onChartLoaded,
    revenue,
    revenueData,
    setCompareTo,
    setShowForecast,
    showForecast,
    isACB,
    analyzeFilter,
    customerOrProductData,
    getAccountsOrProducts,
    filterObjects,
    revenueTempData,
    setRevenueTempData,
  } = props;
  const [xAxisCategories, setXAxisCategories] = useState<string[]>([]);
  const [formattedCompareToData, setFormattedCompareToData] = useState<any>([]);
  const comparisonDataSeriesName = "Comparison Data";
  const forecastedSeriesName = "Forecasted Billable Usage";
  //const [compareTo, setCompareTo] = useState(COMPARE_TO_TYPE.none);
  //const [showForecast, setShowForecast] = useState(showForecastOnOverview);
  const [forecastedSeries, setForecastedSeries] = useState<any[]>([]);
  const [anomalySeries, setAnomalySeries] = useState<any[]>([]);
  const [selectedObjects, setSelectedObjects] = useState<any>([
    ...filterObjects,
  ]);

  useEffect(() => {
    if (
      selectedObjects?.length > -1 &&
      selectedObjects?.length != filterObjects?.length
    ) {
      onChartLoaded(isACB, durationType, duration, false, selectedObjects);
    }
  }, [selectedObjects]);

  const mapCompareToData = (
    compareToData: { [x: string]: any },
    compareTo: string | number | Date,
  ) => {
    const result: any = {};

    for (const key in compareToData) {
      const newKey: string = addCompareToTime(
        key,
        compareTo,
        getPeriodicity(duration),
      )
        .getTime()
        .toString(10);
      result[newKey] = compareToData[key];
    }

    return result;
  };

  useEffect(() => {
    setFormattedCompareToData(
      formatRevenueData(mapCompareToData(compareToData, compareTo)),
    );
  }, [revenueData, compareToData]);

  const createAnomalySeries = (res: any) => {
    if (res) {
      const periodicity: DURATION_SELECT = getPeriodicity(duration);
      if (periodicity === DURATION_SELECT.daily) {
        setAnomalySeries(res);
      } else {
        const isWeekly: boolean = periodicity === DURATION_SELECT.weekly;
        const anomalies = res.map((anomaly: any) => {
          const millis = anomaly[0];
          const category = isWeekly
            ? getWeekText(millis)
            : getMonthText(millis);
          return { name: category, y: anomaly[1], millis: millis };
        });
        setAnomalySeries(anomalies);
      }
    }
  };

  useEffect(() => {
    if (anomalyData && anomalyData.length) {
      createAnomalySeries(anomalyData);
    }
  }, [anomalyData]);

  const createForecastSeries = (res: any) => {
    const periodicity: DURATION_SELECT = getPeriodicity(duration);
    const seriesData: any[] = [];
    if (periodicity === DURATION_SELECT.daily) {
      for (let i = 0; i < res.timestamp.length; i++) {
        const timestamp = res.timestamp[i];
        const value = res.value[i];
        seriesData.push([new Date(timestamp.split(" ")[0]).getTime(), value]);
      }
    } else {
      const isWeekly: boolean = periodicity === DURATION_SELECT.weekly;
      for (let i = 0; i < res.timestamp.length; i++) {
        const millis: number = new Date(
          res.timestamp[i].split(" ")[0],
        ).getTime();
        const category = isWeekly ? getWeekText(millis) : getMonthText(millis);
        seriesData.push({
          name: category,
          y: res.value[i],
          millis: millis,
        });
      }
    }
    setForecastedSeries(seriesData);
  };

  useEffect(() => {
    if (forecastData && showForecast) {
      createForecastSeries(forecastData);
    }
  }, [forecastData?.value, showForecast]);
  function getXAxisCategories(
    revenueData: NonNullable<unknown>,
    forecastedSeries: any[],
    periodicity: DURATION_SELECT,
  ): void {
    const categories: string[] = Object.keys(revenueData)
      .map((milli: string) => parseInt(milli, 10))
      .sort((n1: number, n2: number) => n1 - n2)
      .map((milli: number) =>
        periodicity === DURATION_SELECT.weekly
          ? getWeekText(milli)
          : getMonthText(milli),
      );
    const forecastCategories: string[] = forecastedSeries.map(
      (point) => point.name,
    );
    setXAxisCategories([...new Set([...categories, ...forecastCategories])]);
  }

  useEffect(() => {
    //const forecastedSeries: never[] = [];
    const periodicity: DURATION_SELECT = getPeriodicity(duration);
    if (periodicity !== DURATION_SELECT.daily) {
      getXAxisCategories(revenueData, forecastedSeries || [], periodicity);
    }
    // forecastedSeries ADD THIS AS DEPENDENCY
  }, [revenueData, forecastedSeries]);

  useEffect(() => {
    if (revenueData && Object.keys(revenueData).length > 0) {
      if (!showForecast && anomalySeries.length) {
        setForecastedSeries([]); // Clear forecasted data if showForecast is false
        setAnomalySeries([]);
      }
      if (showForecast) {
        const { forecastPayload, periods, seasonality } =
          formatForecastedRevenueData(revenueData, duration, !!isACB);
        if (
          forecastPayload &&
          periods &&
          seasonality &&
          JSON.stringify(revenueData) !== revenueTempData
        ) {
          setRevenueTempData(revenueData);
          getForecast(forecastPayload, periods, seasonality);
        }
      }
    } else {
      setForecastedSeries([]);
      setAnomalySeries([]);
    }
  }, [revenueData, showForecast]);

  function formatRevenueDataDatetime(data: { [x: string]: any }) {
    const formattedData: any[] = [];
    for (const timestamp in data) {
      if (Object.prototype.hasOwnProperty.call(data, timestamp)) {
        const x = parseInt(timestamp); // Assuming timestamp is in milliseconds
        const y = data[timestamp];
        formattedData.push({ x, y });
      }
    }
    // Sort the data array by x-values (timestamps)
    formattedData.sort((a, b) => a.x - b.x);
    // Convert sorted data to the Highcharts format
    const seriesData = formattedData.map((item) => [item.x, item.y]);
    return seriesData;
  }

  function formatRevenueDataCategory(data: { [x: string]: any }) {
    const isWeekly: boolean =
      getPeriodicity(duration) === DURATION_SELECT.weekly;
    const timestamps: number[] = Object.keys(data)
      .map((timestamp: string) => parseInt(timestamp, 10))
      .sort((n1: number, n2: number) => n1 - n2);

    return timestamps.map((timestamp: number) => {
      const category: string = isWeekly
        ? getWeekText(timestamp)
        : getMonthText(timestamp);
      return {
        name: category,
        y: data[timestamp.toString(10)],
        millis: timestamp,
      };
    });
  }

  const formatRevenueData = (data: { [x: string]: any }) => {
    if (getPeriodicity(duration) === DURATION_SELECT.daily) {
      return formatRevenueDataDatetime(data);
    } else {
      return formatRevenueDataCategory(data);
    }
  };

  const data = formatRevenueData(revenueData);
  // PASS isACB as params after getting isACB from
  const isNotDaily: boolean =
    getPeriodicity(duration) !== DURATION_SELECT.daily;
  if (showForecast && forecastedSeries.length) {
    const temp = isACB && isNotDaily ? data.slice(0, -1) : data;
    //isACB &&
    const lastVal = temp[temp.length - 1];
    forecastedSeries.unshift(lastVal);
  }

  const filteredAnomalySeries =
    showForecast &&
    forecastedSeries.length &&
    isACB &&
    isNotDaily &&
    anomalySeries &&
    anomalySeries.length
      ? anomalySeries.filter(
          (anomaly) => anomaly.name !== forecastedSeries[1].name,
        )
      : anomalySeries;

  const chartOptions = {
    chart: {
      marginRight: 50, // Add margin to the right side of the chart
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },

    xAxis: {
      type:
        getPeriodicity(duration) === DURATION_SELECT.daily
          ? "datetime"
          : "category",
      dateTimeLabelFormats:
        getPeriodicity(duration) === DURATION_SELECT.daily
          ? {
              month: "%b %Y",
            }
          : undefined,
      categories:
        getPeriodicity(duration) === DURATION_SELECT.daily
          ? undefined
          : xAxisCategories,
      title: {
        text: "",
      },
      labels: {
        enabled: true, // Enable x-axis labels
      },
    },
    yAxis: {
      title: {
        text: "Net MCR (USD)",
      },
      //min: findNextLowestNonZero(formatRevenueData(revenueData)),
    },
    lang: {
      noData: "No data to show",
    },
    tooltip: {
      shared: true, // Enable shared tooltips
      formatter: function (this: any) {
        let tooltipText = "";
        tooltipText += `<b>${
          getPeriodicity(duration) === DURATION_SELECT.daily
            ? formatDate(new Date(this.points[0].x))
            : this.points[0].x
        }</b><br/>`;
        this.points.forEach(
          (point: {
            series: { name: string };
            x: any;
            point: { millis: any };
            color: any;
            y: number;
          }) => {
            const periodicity: DURATION_SELECT = getPeriodicity(duration);
            if (point.series.name === comparisonDataSeriesName) {
              tooltipText += `<b>${getCompareToDateText(
                periodicity === DURATION_SELECT.daily
                  ? point.x
                  : point.point.millis,
                compareTo,
                periodicity,
              )}</b><br/>`;
            }
            if (
              point.series.name === forecastedSeriesName &&
              this.points.length > 1
            ) {
              return;
            }
            tooltipText += `<span style="color:${point.color}">\u25CF</span> ${
              point.series.name
            }: <b>${Highcharts.numberFormat(point.y, 2)}</b><br/>`;
          },
        );
        return tooltipText;
      },
    },
    series: [
      {
        name: "Billable Usage",
        data:
          showForecast &&
          isACB &&
          getPeriodicity(duration) !== DURATION_SELECT.daily
            ? data.slice(0, -1)
            : data,
        color: "#009684",
        lineWidth: 2,
        type:
          formattedCompareToData && formattedCompareToData.length > 0
            ? "line"
            : "area",
        fillColor: {
          // NEED TO CHANGE FOR FORECAST
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.color("#009684").setOpacity(0.5).get("rgba")], // Semi-transparent at the top
            [1, Highcharts.color("#009684").setOpacity(0).get("rgba")], // Fully transparent at the bottom
          ],
        },
      },
      ...(formattedCompareToData && formattedCompareToData.length > 0
        ? [
            {
              name: comparisonDataSeriesName,
              data: formattedCompareToData,
              color: "#5435AB", // Different color for comparison
              lineWidth: 2,
            },
          ]
        : []),
      ...(showForecast && forecastedSeries.length > 0
        ? [
            {
              name: forecastedSeriesName,
              data: forecastedSeries,
              color: "#009684", // Line color
              lineWidth: 2, // Stroke width
              dashStyle: "ShortDot", // Line style
              marker: {
                enabled: true,
                radius: 5,
              },
            },
          ]
        : []),
      //!doNotShowAnomaly&&
      ...(showForecast && filteredAnomalySeries.length > 0
        ? [
            {
              name: "Anomaly",
              type: "line",
              color: "#FF0000",
              marker: {
                enabled: true,
                radius: 5,
                symbol: "circle",
              },
              showInLegend: true,
              lineWidth: 0,
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
              data: filteredAnomalySeries,
            },
          ]
        : []),
    ],
  };
  const handleDurationSelection = (
    filterType: any,
    durationTypeArg: any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedDuration: any,
  ) => {
    if (filterType !== durationType.toLowerCase()) {
      setCompareTo(COMPARE_TO_TYPE.none);
    }
    onChartLoaded(isACB, filterType, durationTypeArg);
  };
  const handleAnalyzeFilterChange = (e: any) => {
    const { endDate, startDate } = calculateDatesForPeriod(duration, !!isACB);
    getAccountsOrProducts(
      startDate,
      endDate,
      e.target.value,
      null,
      null,
      true,
      "",
    );
  };
  return (
    <Card
      fullWidth
      id="net-mcr-chart"
      header={
        <Stack direction={"column"} spacing={1}>
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <DurationSelector
              currentDuration={duration}
              durationType={durationType}
              handleDurationSelection={handleDurationSelection}
            />
            <Stack direction={"row"} spacing={1}>
              <Typography variant="title">Analysed by:</Typography>
              <Select
                dsOnChange={handleAnalyzeFilterChange}
                fullWidth={false}
                value={analyzeFilter}
              >
                <SelectItem value="analyzeByProduct">
                  <Typography>Product</Typography>
                </SelectItem>
                <SelectItem value="analyzeByCustomer">
                  <Typography>Customer</Typography>
                </SelectItem>
              </Select>
              <AnalyseTypeAhead
                analyzeBy={analyzeFilter}
                customerOrProductData={customerOrProductData}
                getAccountsOrProducts={getAccountsOrProducts}
                selectedDuration={duration}
                selectedObjects={selectedObjects}
                setSelectedObjects={setSelectedObjects}
              />
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <Typography variant="h6">Billable Usage Trends</Typography>
              <Tooltip
                title={
                  "This metric displays the patterns and changes in billable usage over a selected time period."
                }
              >
                <IconButton size="small">
                  <InfoOutlinedIcon
                    sx={{ color: "text.secondary" }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            </Stack>
            <CompareToSelector
              duration={duration}
              showForecastOnOverview={false}
              setCompareTo={setCompareTo}
              compareTo={compareTo}
              setShowForecast={setShowForecast}
              showForecast={showForecast}
              fetchCompareToData={fetchCompareToData}
              revenueData={revenueData}
            />
          </Stack>
        </Stack>
      }
      body={
        <>
          {revenue && (
            <Stack
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{ marginBottom: "20px", marginTop: "-40px" }}
                variant="caption"
              >
                Billable Usage based on {getLastDurationStr(duration)}
              </Typography>
              <Typography>Total Billable Usage</Typography>
              <Stack alignItems="center" direction="row" spacing={2}>
                <Stack alignItems="flex-end" direction="row" spacing={1}>
                  <Typography variant="h5">{revenue.value}</Typography>
                  <Typography
                    sx={{
                      paddingLeft: "0px",
                      color: designTokens.colors.lightEmphasisLow,
                    }}
                    variant="h6"
                  >
                    {revenue.currency}
                  </Typography>
                </Stack>

                <Stack alignItems="flex-start" direction="column">
                  <ChangeIndicator value={revenue.change} />
                  <Typography variant="caption">{revenue.dateText}</Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </>
      }
    />
  );
};

export default RevenueChart;
