import React from "react";
import { Chip } from "zds";
import type {
  BaseFieldComponentProps,
  FieldComponentBaseProps,
} from "../constants";
import FieldLabel, { BASE_LABEL_TEXT_SIZE } from "../component/FieldLabel";

type ChipFieldComponentProps = FieldComponentBaseProps & {
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  borderRadius?: string;
  boxShadow?: string;
  cellBackgroundColor?: string;
  cellBorderColor?: string;
  cellBorderWidth?: number;
  cellBorderRadius?: string;
  cellBoxShadow?: string;
  accentColor?: string;
  label?: string;
  disabled: boolean;
  variant?: "filled" | "outlined";
  state?: "default" | "error" | "success" | "warning";
  chipStateError?: string;
  chipStatePrimary?: string;
  chipStateSuccess?: string;
  chipStateWarning?: string;
  chipStateSecondary?: string;
  chipStateInfo?: string;
  chipStateIndeterminate?: string;
};

export type ChipFieldProps = BaseFieldComponentProps<ChipFieldComponentProps>;

const COMPONENT_DEFAULT_VALUES = {
  isDisabled: true,
  isRequired: false,
  isSpellCheck: false,
  isVisible: true,
  labelTextSize: BASE_LABEL_TEXT_SIZE,
  label: "",
  isChecked: true,
};

function ChipField({
  inlineLabelParent,
  labelAlignmentParent,
  labelStyleParent,
  labelTextColorParent,
  labelTextSizeParent,
  labelWidthParent,
  schemaItem,
}: ChipFieldProps) {
  const {
    chipStateError,
    chipStateIndeterminate,
    chipStateInfo,
    chipStatePrimary,
    chipStateSecondary,
    chipStateSuccess,
    chipStateWarning,
    inlineLabel,
    label,
    labelAlignment,
    labelStyle,
    labelTextColor,
    labelTextSize,
    labelWidth,
    tooltip,
    useDefaultStyles,
  } = schemaItem;

  const getChipState = () => {
    if (schemaItem.defaultValue === chipStateError) return "error";
    if (schemaItem.defaultValue === chipStatePrimary) return "primary";
    if (schemaItem.defaultValue === chipStateSuccess) return "success";
    if (schemaItem.defaultValue === chipStateWarning) return "warning";
    if (schemaItem.defaultValue === chipStateSecondary) return "secondary";
    if (schemaItem.defaultValue === chipStateInfo) return "info";
    if (schemaItem.defaultValue === chipStateIndeterminate)
      return "indeterminate";
    return "default";
  };

  const ellipsisContainerStyle = {
    flex: "0 1 auto",
    minWidth: 0,
    overflow: "hidden",
    wordWrap: "break-word" as const,
    width: "100%",
    display: "flex",
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: (useDefaultStyles ? inlineLabelParent : inlineLabel)
            ? "row"
            : "column",
        }}
      >
        <FieldLabel
          alignment={useDefaultStyles ? labelAlignmentParent : labelAlignment}
          direction={
            (useDefaultStyles ? inlineLabelParent : inlineLabel)
              ? "row"
              : "column"
          }
          label={label}
          labelStyle={useDefaultStyles ? labelStyleParent : labelStyle}
          labelTextColor={
            useDefaultStyles ? labelTextColorParent : labelTextColor
          }
          labelTextSize={useDefaultStyles ? labelTextSizeParent : labelTextSize}
          tooltip={tooltip}
          width={useDefaultStyles ? labelWidthParent : labelWidth}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flex: 1,
            overflowX: "hidden",
          }}
        >
          <div style={ellipsisContainerStyle}>
            <Chip
              completable={false}
              disabled={schemaItem.disabled}
              dismissible={false}
              label={schemaItem.defaultValue || ""}
              size={"small"}
              state={getChipState()}
              uppercase
              variant={schemaItem.variant}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ChipField.componentDefaultValues = COMPONENT_DEFAULT_VALUES;

export default ChipField;
