import { ValidationTypes } from "constants/WidgetValidation";
import { EvaluationSubstitutionType } from "ee/entities/DataTree/types";
import {
  ARRAY_ITEM_KEY,
  FIELD_EXPECTING_OPTIONS,
  FIELD_SUPPORTING_FOCUS_EVENTS,
  FieldType,
} from "widgets/ZJsonSummaryWidget/constants";
import {
  fieldTypeUpdateHook,
  getAutocompleteProperties,
  getSchemaItem,
  type HiddenFnParams,
  hiddenIfArrayItemIsObject,
  hiddenIfNotZOBject,
  hiddenIfNotZOBjectAndHyperLink,
  updateChildrenDisabledStateHook,
} from "../helper";
import { Alignment } from "@blueprintjs/core";
import { getStylesheetValue } from "../../../../JSONFormWidget/widget/propertyConfig/helper";

const FIELDS_WITH_ACCENT_COLOR = [FieldType.CHECKBOX];
const FIELDS_WITHOUT_BORDER_RADIUS = [
  FieldType.OBJECT,
  FieldType.CHECKBOX,
  FieldType.TEXT_FIELD,
];
const FIELDS_WITHOUT_BOX_SHADOW = [
  FieldType.OBJECT,
  FieldType.CHECKBOX,
  FieldType.TEXT_FIELD,
];
const COMMON_PROPERTIES = {
  content: {
    data: [
      {
        propertyName: "fieldType",
        label: "Field Type",
        helpText: "Type of the widget to be used corresponding to the field",
        controlType: "DROP_DOWN",
        isBindProperty: false,
        isTriggerProperty: false,
        defaultValue: FieldType.TEXT_FIELD,
        options: Object.values(FieldType).map((option) => ({
          label: option,
          value: option,
        })),
        dependencies: ["schema", "childStylesheet", "dynamicBindingPathList"],
        updateHook: fieldTypeUpdateHook,
      },
      {
        propertyName: "displayValue",
        helpText: "Sets the label value of the field",
        label: "Display Value",
        controlType: "JSON_FORM_COMPUTE_VALUE",
        placeholderText: "Display Value",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: hiddenIfNotZOBjectAndHyperLink,
        dependencies: ["schema", "sourceData"],
      },
      {
        propertyName: "defaultValue",
        helpText: "Sets the label value of the field",
        label: "Property Value",
        controlType: "JSON_FORM_COMPUTE_VALUE",
        placeholderText: "Actual Value",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.FUNCTION,
          params: {
            fn: (defaultValue: unknown) => {
              if (
                typeof defaultValue === "string" ||
                typeof defaultValue === "object" ||
                typeof defaultValue === "boolean" ||
                typeof defaultValue === "number"
              ) {
                return {
                  isValid: true,
                  parsed: defaultValue,
                  messages: [""],
                };
              } else {
                return {
                  isValid: false,
                  parsed: defaultValue,
                  messages: [
                    "This value does not evaluate to type Text or Object",
                  ],
                };
              }
            },
            expected: {
              type: "Text or Object ",
              example: `"text" | { "label": "label1", "value": "value1" }`,
            },
          },
        },
        hidden: hiddenIfArrayItemIsObject,
        dependencies: ["schema", "sourceData"],
      },
      {
        propertyName: "startIcon",
        label: "Start Icon",
        controlType: "ZICON_SELECT",
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.TEXT_FIELD),
        dependencies: ["schema", "sourceData"],
      },
      {
        propertyName: "iconColor",
        label: "Icon Color",
        controlType: "COLOR_PICKER",
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.TEXT_FIELD),
        dependencies: ["schema"],
      },
      {
        propertyName: "options",
        helpText:
          "Allows users to select from the given option(s). Values must be unique",
        label: "Options",
        controlType: "INPUT_TEXT",
        placeholderText: '[{ "label": "Option1", "value": "Option2" }]',
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY,
          params: {
            unique: ["value"],
            children: {
              type: ValidationTypes.OBJECT,
              params: {
                required: true,
                allowedKeys: [
                  {
                    name: "label",
                    type: ValidationTypes.TEXT,
                    params: {
                      default: "",
                      required: true,
                    },
                  },
                  {
                    name: "value",
                    type: ValidationTypes.TEXT,
                    params: {
                      default: "",
                      required: true,
                    },
                  },
                ],
              },
            },
          },
        },
        evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotIncludes(FIELD_EXPECTING_OPTIONS),
        dependencies: ["schema", "sourceData"],
      },
    ],
    dataToggles: [
      {
        propertyName: "enableNullValues",
        helpText: "Displays null or empty values vs --",
        label: "Show null values",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        validation: { type: ValidationTypes.BOOLEAN },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.TEXT_FIELD),
        dependencies: ["schema", "sourceData"],
      },
      {
        propertyName: "toggleBooleans",
        helpText: "Displays Yes/No vs true/false",
        label: "Toggle boolean display values",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        validation: { type: ValidationTypes.BOOLEAN },
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotMatches(FieldType.TEXT_FIELD),
        dependencies: ["schema", "sourceData"],
      },
    ],
    general: [
      {
        propertyName: "tooltip",
        helpText: "Show help text or details about current field",
        label: "Tooltip",
        controlType: "ZJSON_SUMMARY_COMPUTE_VALUE",
        placeholderText: "Passwords must be at-least 6 chars",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: hiddenIfArrayItemIsObject,
        dependencies: ["schema", "sourceData"],
      },
      {
        propertyName: "footerRef",
        helpText: "footerRef",
        label: "footerRef",
        controlType: "INPUT_TEXT",
        customJSControl: "ZJSON_SUMMARY_COMPUTE_VALUE",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: hiddenIfNotZOBject,
        dependencies: ["schema", "sourceData"],
      },
      {
        propertyName: "footerDisplay",
        helpText: "footerDisplay",
        label: "footerDisplay",
        controlType: "INPUT_TEXT",
        customJSControl: "ZJSON_SUMMARY_COMPUTE_VALUE",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: hiddenIfNotZOBject,
        dependencies: ["schema", "sourceData"],
      },
      {
        propertyName: "columnSpan",
        helpText: "Sets the column span of the field",
        label: "Column span",
        controlType: "NUMERIC_INPUT",
        isBindProperty: true,
        isTriggerProperty: false,
        min: 1,
        validation: {
          type: "NUMBER",
          params: {
            natural: true,
          },
        },
        dependencies: ["schema", "sourceData"],
      },
    ],
    label: [
      {
        propertyName: "label",
        helpText: "Sets the label text of the field",
        label: "Property Name",
        controlType: "INPUT_TEXT",
        placeholderText: "Name:",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: hiddenIfArrayItemIsObject,
        dependencies: ["schema", "sourceData"],
      },
    ],
    generalSwitch: [
      {
        propertyName: "isVisible",
        helpText: "Controls the visibility of the field",
        label: "Visible",
        controlType: "SWITCH",
        defaultValue: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "ZJSON_SUMMARY_COMPUTE_VALUE",
        validation: { type: ValidationTypes.BOOLEAN },
        hidden: (...args: HiddenFnParams) => {
          return getSchemaItem(...args).compute(
            (schemaItem) => schemaItem.identifier === ARRAY_ITEM_KEY,
          );
        },
        dependencies: ["schema", "sourceData"],
      },
      {
        propertyName: "isDisabled",
        helpText: "Disables the field",
        label: "Disabled",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "ZJSON_SUMMARY_COMPUTE_VALUE",
        validation: { type: ValidationTypes.BOOLEAN },
        dependencies: ["schema", "sourceData"],
        updateHook: updateChildrenDisabledStateHook,
      },
      {
        propertyName: "shouldAllowAutofill",
        label: "Allow autofill",
        helpText: "Allow users to autofill values from browser",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
        hidden: (...args: HiddenFnParams) => {
          //should be shown for only inputWidgetV2 and for email or password input types
          return getSchemaItem(...args);
        },
        dependencies: ["schema", "sourceData"],
      },
    ],
    events: [
      {
        propertyName: "onFocus",
        helpText: "when focused.",
        label: "onFocus",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
        additionalAutoComplete: getAutocompleteProperties,
        dependencies: ["schema", "sourceData"],
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotIncludes(
            FIELD_SUPPORTING_FOCUS_EVENTS,
          ),
      },
      {
        propertyName: "onBlur",
        helpText: "when the field loses focus.",
        label: "onBlur",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
        additionalAutoComplete: getAutocompleteProperties,
        dependencies: ["schema", "sourceData"],
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotIncludes(
            FIELD_SUPPORTING_FOCUS_EVENTS,
          ),
      },
    ],
  },
  style: {
    label: [
      {
        propertyName: "useDefaultStyles",
        label: "useDefaultStyles",
        controlType: "SWITCH",
        defaultValue: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "ZJSON_SUMMARY_COMPUTE_VALUE",
        validation: { type: ValidationTypes.BOOLEAN },
        dependencies: ["schema", "sourceData"],
      },
      {
        propertyName: "labelTextColor",
        label: "Font color",
        helpText: "Control the color of the label associated",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "ZJSON_SUMMARY_COMPUTE_VALUE",
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            regex: /^(?![<|{{]).+/,
          },
        },
        dependencies: ["schema", "sourceData"],
        hidden: (...args: HiddenFnParams) => {
          return getSchemaItem(...args).compute(
            (schemaItem) => schemaItem.useDefaultStyles,
          );
        },
      },
      {
        propertyName: "labelTextSize",
        label: "Font size",
        helpText: "Control the font size of the label associated",
        defaultValue: "0.875rem",
        controlType: "DROP_DOWN",
        options: [
          {
            label: "XS",
            value: "0.688rem",
            subText: "0.688rem",
          },
          {
            label: "S",
            value: "0.875rem",
            subText: "0.875rem",
          },
          {
            label: "M",
            value: "1rem",
            subText: "1rem",
          },
          {
            label: "L",
            value: "1.25rem",
            subText: "1.25rem",
          },
          {
            label: "XL",
            value: "1.875rem",
            subText: "1.875rem",
          },
          {
            label: "XXL",
            value: "3rem",
            subText: "3rem",
          },
          {
            label: "3XL",
            value: "3.75rem",
            subText: "3.75rem",
          },
        ],
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (...args: HiddenFnParams) => {
          return getSchemaItem(...args).compute(
            (schemaItem) => schemaItem.useDefaultStyles,
          );
        },
      },
      {
        propertyName: "labelStyle",
        label: "Emphasis",
        helpText: "Control if the label should be bold or italics",
        controlType: "BUTTON_GROUP",
        options: [
          {
            icon: "text-bold",
            value: "BOLD",
          },
          {
            icon: "text-italic",
            value: "ITALIC",
          },
        ],
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "ZJSON_SUMMARY_COMPUTE_VALUE",
        validation: { type: ValidationTypes.TEXT },
        dependencies: ["schema", "sourceData"],
        hidden: (...args: HiddenFnParams) => {
          return getSchemaItem(...args).compute(
            (schemaItem) => schemaItem.useDefaultStyles,
          );
        },
      },
      {
        propertyName: "inlineLabel",
        label: "inlineLabel",
        controlType: "SWITCH",
        defaultValue: false,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "ZJSON_SUMMARY_COMPUTE_VALUE",
        validation: { type: ValidationTypes.BOOLEAN },
        dependencies: ["schema", "sourceData"],
        hidden: (...args: HiddenFnParams) => {
          return getSchemaItem(...args).compute(
            (schemaItem) => schemaItem.useDefaultStyles,
          );
        },
      },
      {
        helpText: "Sets the label alignment of the widget",
        propertyName: "labelAlignment",
        label: "Alignment",
        controlType: "LABEL_ALIGNMENT_OPTIONS",
        fullWidth: false,
        options: [
          {
            startIcon: "align-left",
            value: Alignment.LEFT,
          },
          {
            startIcon: "align-right",
            value: Alignment.RIGHT,
          },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (...args: HiddenFnParams) => {
          return getSchemaItem(...args).compute(
            (schemaItem) =>
              schemaItem.inlineLabel === false || schemaItem.useDefaultStyles,
          );
        },
      },
      {
        helpText: "Sets the label width in px",
        propertyName: "labelWidth",
        label: "Width",
        controlType: "INPUT_TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        min: 0,
        validation: {
          type: ValidationTypes.NUMBER,
          params: {
            natural: true,
          },
        },
        hidden: (...args: HiddenFnParams) => {
          return getSchemaItem(...args).compute(
            (schemaItem) =>
              schemaItem.inlineLabel === false || schemaItem.useDefaultStyles,
          );
        },
      },
    ],
    value: [
      {
        propertyName: "valueTextColor",
        label: "Font color",
        helpText: "Control the color of the value associated",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "ZJSON_SUMMARY_COMPUTE_VALUE",
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            regex: /^(?![<|{{]).+/,
          },
        },
        dependencies: ["schema", "sourceData"],
      },
      {
        propertyName: "valueTextSize",
        label: "Font size",
        helpText: "Control the font size of the value associated",
        defaultValue: "0.875rem",
        controlType: "DROP_DOWN",
        options: [
          {
            label: "XS",
            value: "0.688rem",
            subText: "0.688rem",
          },
          {
            label: "S",
            value: "0.875rem",
            subText: "0.875rem",
          },
          {
            label: "M",
            value: "1rem",
            subText: "1rem",
          },
          {
            label: "L",
            value: "1.25rem",
            subText: "1.25rem",
          },
          {
            label: "XL",
            value: "1.875rem",
            subText: "1.875rem",
          },
          {
            label: "XXL",
            value: "3rem",
            subText: "3rem",
          },
          {
            label: "3XL",
            value: "3.75rem",
            subText: "3.75rem",
          },
        ],
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "valueStyle",
        label: "Emphasis",
        helpText: "Control if the value should be bold or italics",
        controlType: "BUTTON_GROUP",
        options: [
          {
            icon: "text-bold",
            value: "BOLD",
          },
          {
            icon: "text-italic",
            value: "ITALIC",
          },
        ],
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        customJSControl: "ZJSON_SUMMARY_COMPUTE_VALUE",
        validation: { type: ValidationTypes.TEXT },
        dependencies: ["schema", "sourceData"],
      },
      {
        helpText: "Value lines display",
        propertyName: "linesDisplayed",
        label: "linesDisplay",
        controlType: "INPUT_TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        min: 1,
        validation: {
          type: ValidationTypes.NUMBER,
          params: {
            natural: true,
          },
        },
      },
    ],
    borderShadow: [
      {
        propertyName: "borderRadius",
        label: "Border radius",
        helpText: "Rounds the corners of the icon button's outer border edge",
        controlType: "BORDER_RADIUS_OPTIONS",
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        getStylesheetValue,
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeIncludes(
            FIELDS_WITHOUT_BORDER_RADIUS,
          ),
        dependencies: ["schema"],
      },
      {
        propertyName: "boxShadow",
        label: "Box shadow",
        helpText:
          "Enables you to cast a drop shadow from the frame of the widget",
        controlType: "BOX_SHADOW_OPTIONS",
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        getStylesheetValue,
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeIncludes(FIELDS_WITHOUT_BOX_SHADOW),
        validation: { type: ValidationTypes.TEXT },
        dependencies: ["schema"],
      },
    ],
    color: [
      {
        propertyName: "accentColor",
        helpText: "Sets the accent color",
        label: "Accent color",
        controlType: "COLOR_PICKER",
        customJSControl: "JSON_FORM_COMPUTE_VALUE",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        getStylesheetValue,
        hidden: (...args: HiddenFnParams) =>
          getSchemaItem(...args).fieldTypeNotIncludes(FIELDS_WITH_ACCENT_COLOR),
        dependencies: ["schema"],
      },
    ],
  },
};

export default COMMON_PROPERTIES;
